import { handleActions } from 'redux-actions';
import AttributesActions from '../redux-actions/AttributesActions';

const initialAttributesState = {
    loading: null,
    error: null,
    data: [],
};

const attributes = handleActions(
    {
        [AttributesActions.getDistinctAttributes]: (state) => {
            return {
                ...state,
                loading: true,
                error: false,
            };
        },
        [AttributesActions.getDistinctAttributesSuccess]: (state, { payload: { attributes } }) => {
            return {
                ...state,
                data: attributes,
                loading: false,
                error: false,
            };
        },
        [AttributesActions.getDistinctAttributesError]: (state, { payload: { message } }) => {
            return {
                ...state,
                loading: false,
                error: message,
            };
        },
    },
    initialAttributesState,
);

export const getAttributes = (state) => state;
export default attributes;
