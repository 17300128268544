import { CopyAll } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PublishIcon from '@mui/icons-material/Public';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QueueIcon from '@mui/icons-material/Queue';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import arrayMove from 'array-move';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import WorkoutActions from '../../redux-actions/WorkoutActions';
import { useMedia } from '../../util/useMedia';
import WorkoutDay from './WorkoutDay';
import debounce from 'lodash.debounce';

const styles = createUseStyles({
    container: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    accordionOuter: {
        width: '100%',
        backgroundColor: 'transparent!important',
        borderRadius: '0px !important',
        borderBottom: '1px solid rgba(5, 116, 19, .3)',
        boxShadow: '0px 0px 0px!important',
    },
    accordionHeader: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold!important',
        marginRight: '10px!important',
    },
    accordionDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '0px 0px 20px !important',
    },
    iconButton: {
        width: '200px',
        height: '200px',
        padding: '0!important',
    },
    chip: {
        margin: 2,
        fontSize: '18px!important',
        height: '40px!important',
        backgroundColor: 'rgb(0,0,0)!important',
        color: 'white!important',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    assignementChip: {
        margin: 2,
        fontSize: '12px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    assignmentChipTitle: {
        fontSize: '12px!important',
    },
    assignementChipSmall: {
        margin: 2,
        fontSize: '8px!important',
        textAlign: 'center!important',
        height: '18px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    assignmentChipTitleSmall: {
        fontSize: '10px!important',
    },
    toggleGroup: {
        height: 30,
    },
    selected: {
        backgroundColor: '#fafafa!important',
        border: '.5px solid #D3D3D3!important',
    },
    dayNameInput: {
        fontWeight: 'bold!important',
    },
});

interface WorkoutProps {
    workout: any;
    onWorkoutDayOpen: (resource: any) => void;
}

const Workout = (props: WorkoutProps): JSX.Element => {
    const { workout, onWorkoutDayOpen } = props;
    const classes = styles();
    const theme = useTheme();

    const large = useMedia('(min-width: 900px)');
    const exercises = useSelector(StateSelectors.getDefinedExercises);
    const attributes = useSelector(StateSelectors.getAttributes);
    const userCredentials = useSelector(StateSelectors.getUserCredentials);

    const [workoutState, setWorkoutState] = useState(workout);
    const [editModeOn, setEditModeOn] = useState(false);
    const [newWorkoutName, setNewWorkoutName] = useState(workout.name);
    const [isPublic, setIsPublic] = useState(workout.isPublished);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);

    const [updateWorkout, deleteWorkout, duplicateWorkout] = useActions([
        (workout) => WorkoutActions.updateWorkout(workout),
        (workoutId) => WorkoutActions.deleteWorkout(workoutId),
        (workout) => WorkoutActions.createWorkout(workout),
    ]);

    useEffect(() => {
        setWorkoutState(workout);
        setIsPublic(workout.isPublished);
    }, [workout, setWorkoutState, setIsPublic]);

    useEffect(() => {
        if (!initialLoad) {
            if (['ADMIN', 'SUPERVISOR'].includes(userCredentials.role)) {
                setTimeout(() => updateWorkout(workoutState), 500);
            }
        }
        setInitialLoad(false);
    }, [updateWorkout, userCredentials.role, setInitialLoad]);

    const exercisesMapped = useCallback(
        (exercisesOfDay) => {
            const exercisesMapped = {};
            exercisesOfDay.forEach((exercise: any) => {
                // @ts-ignore
                exercisesMapped[exercise.exerciseId] = exercises.data?.filter(
                    (ex: any) => ex.id === exercise.exerciseId,
                )[0];
            });
            return exercisesMapped;
        },
        [workout, exercises],
    );

    const addWorkoutDay = useCallback(() => {
        const currentDays = [...workoutState.days];
        currentDays.push({
            id: `${workoutState.id}__${workoutState.days.length + 1}`,
            day: 'Title',
            exercises: [],
            info: '',
        });

        updateWorkout({ ...workoutState, days: currentDays });
    }, [setWorkoutState, workoutState]);

    const updateAttributes = useCallback(
        (value, attribute) => {
            updateWorkout({
                ...workoutState,
                [attribute]: value.indexOf('None') !== -1 || value.length === 0 ? null : value,
            });
        },
        [updateWorkout, workoutState],
    );

    const onPublishStateChange = useCallback(
        (event, value) => {
            // event.persist();
            updateWorkout({ ...workoutState, isPublished: value === 'published' });
        },
        [[updateWorkout, workoutState, setIsPublic]],
    );

    const onDeleteWorkoutDay = useCallback(
        (workoutDay) => {
            const newWorkoutConfig = { ...workout, days: workout.days.filter((day) => day.day !== workoutDay.day) };
            updateWorkout(newWorkoutConfig);
        },
        [workout],
    );

    const onDeleteWorkoutPress = useCallback(() => {
        setDeleteDialogOpen(true);
    }, [setDeleteDialogOpen]);

    const onDeleteWorkout = useCallback(() => {
        deleteWorkout(workoutState.id);
        setDeleteDialogOpen(false);
    }, [workoutState, deleteWorkout, setDeleteDialogOpen]);

    const onDeleteWorkoutCancelPress = useCallback(() => {
        setDeleteDialogOpen(false);
    }, [setDeleteDialogOpen]);

    const onNewWorkoutNameSave = useCallback(() => {
        updateWorkout({ ...workoutState, name: newWorkoutName });
        setEditModeOn(false);
    }, [workoutState, newWorkoutName]);

    const onWorkoutNameChange = useCallback((event) => {
        event.persist();
        setNewWorkoutName(event.target.value);
    }, []);

    const onDuplicateWorkoutPress = useCallback(() => {
        duplicateWorkout({ name: `${workoutState.name}_Copy`, days: workoutState.days });
    }, [duplicateWorkout, workoutState]);

    const deleteDialog = useMemo(() => {
        return (
            <DeleteDialog
                title="Delete Workout"
                item={{ description: { name: workoutState.name } }}
                open={deleteDialogOpen}
                onSavePress={onDeleteWorkout}
                onCancelPress={onDeleteWorkoutCancelPress}
            />
        );
    }, [workout, deleteDialogOpen, onDeleteWorkout, setDeleteDialogOpen, onDeleteWorkoutCancelPress]);

    const editHeader = useMemo(() => {
        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                        <Autocomplete
                            id="group-select"
                            options={attributes.data?.departments || []}
                            value={workoutState?.department || []}
                            multiple
                            onChange={(e, v) => updateAttributes(v, 'department')}
                            style={{ minWidth: '250px' }}
                            limitTags={2}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChip}
                                        color="primary"
                                    />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} variant="standard" label="Groups" />}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                        <Autocomplete
                            id="side-select"
                            options={attributes.data?.sides || []}
                            value={workoutState?.side || []}
                            multiple
                            onChange={(e, v) => updateAttributes(v, 'side')}
                            style={{ minWidth: '250px' }}
                            limitTags={2}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChip}
                                        color="primary"
                                    />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} variant="standard" label="Sides" />}
                        />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                        <Autocomplete
                            id="position-select"
                            options={attributes.data?.positions || []}
                            value={workoutState?.position || []}
                            multiple
                            onChange={(e, v) => updateAttributes(v, 'position')}
                            style={{ minWidth: '250px' }}
                            limitTags={2}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChip}
                                        color="primary"
                                    />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} variant="standard" label="Positions" />}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 10, height: 'calc(100% - 20px)' }}>
                        <ToggleButtonGroup
                            value={isPublic ? 'published' : 'hidden'}
                            onChange={onPublishStateChange}
                            exclusive
                            size="small"
                        >
                            <ToggleButton
                                selected={isPublic}
                                value={'published'}
                                size="small"
                                classes={{ selected: classes.selected }}
                                style={{ width: 75 }}
                            >
                                <PublishIcon sx={{ color: isPublic ? 'icon.primary' : 'icon.disabled' }} />
                            </ToggleButton>
                            <ToggleButton
                                selected={!isPublic}
                                value={'hidden'}
                                size="small"
                                classes={{ selected: classes.selected }}
                                style={{ width: 75 }}
                            >
                                <VisibilityOffIcon sx={{ color: !isPublic ? 'icon.secondary' : 'icon.disabled' }} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <div
                    style={{
                        marginLeft: 20,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        startIcon={<DeleteIcon />}
                        onClick={onDeleteWorkoutPress}
                    >
                        Delete
                    </Button>
                </div>
            </>
        );
    }, [userCredentials, updateAttributes, onDeleteWorkoutPress, workoutState, attributes]);

    const userHeader = () => {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
                    <Typography className={classes.assignmentChipTitle}>Groups</Typography>
                    <div style={{ display: 'flex' }}>
                        {workoutState?.department.map((department) => {
                            return (
                                <Chip
                                    key={'department'}
                                    label={department}
                                    className={classes.assignementChip}
                                    color="primary"
                                />
                            );
                        })}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20 }}>
                    <Typography className={classes.assignmentChipTitle}>Sides</Typography>
                    <div style={{ display: 'flex' }}>
                        {workoutState?.side.map((s) => {
                            return (
                                <Chip
                                    key={'department'}
                                    label={s}
                                    className={classes.assignementChip}
                                    color="primary"
                                />
                            );
                        })}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography className={classes.assignmentChipTitle}>Positions</Typography>
                    <div style={{ display: 'flex' }}>
                        {workoutState?.position.map((pos) => {
                            return (
                                <Chip
                                    key={'department'}
                                    label={pos}
                                    className={classes.assignementChip}
                                    color="primary"
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const SortableItem = SortableElement(({ day }) => {
        return (
            <li style={{ display: 'inline-block', verticalAlign: 'top' }}>
                <WorkoutDay
                    key={`${workoutState.id}__${day.id}`}
                    workoutDay={day}
                    workoutId={workoutState.id}
                    exercisesMap={exercisesMapped(day.exercises)}
                    onDeleteWorkoutDay={onDeleteWorkoutDay}
                />
            </li>
        );
    });

    const SortableList = SortableContainer(({ days }) => {
        return (
            <ul>
                {days.map((day, index) => (
                    <SortableItem key={`item-${index}`} index={index} day={day} />
                ))}
            </ul>
        );
    });

    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            setWorkoutState((priorState): any => {
                setTimeout(
                    () => updateWorkout({ ...priorState, days: arrayMove([...priorState.days], oldIndex, newIndex) }),
                    500,
                );

                return { ...priorState, days: arrayMove([...priorState.days], oldIndex, newIndex) };
            });
        },
        [setWorkoutState, updateWorkout],
    );

    const onWorkoutOpenChange = useCallback(
        (event, expanded) => {
            if (expanded) {
                onWorkoutDayOpen(workoutState.id);
            }
        },
        [onWorkoutDayOpen],
    );

    return (
        <div className={classes.container}>
            <Accordion
                className={classes.accordionOuter}
                TransitionProps={{ unmountOnExit: true }}
                onChange={onWorkoutOpenChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="invitations-content"
                    id="invitations-header"
                    style={{ width: '100%', padding: 10, height: 100 }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {!editModeOn && (
                                <Typography className={classes.accordionHeader}>{newWorkoutName} </Typography>
                            )}
                            {editModeOn && (
                                <Input
                                    id="workoutName-multiline-static"
                                    value={newWorkoutName}
                                    onChange={onWorkoutNameChange}
                                    classes={{
                                        input: classes.dayNameInput,
                                    }}
                                    style={{ width: '100%' }}
                                />
                            )}
                            {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && !editModeOn && (
                                <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    style={{ height: 'fit-content', zIndex: 10 }}
                                    onClick={() => setEditModeOn((currentState) => !currentState)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                            {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && !editModeOn && (
                                <IconButton
                                    color="primary"
                                    aria-label="edit"
                                    style={{ height: 'fit-content', zIndex: 10 }}
                                    onClick={onDuplicateWorkoutPress}
                                >
                                    <ContentCopyIcon fontSize="small" />
                                </IconButton>
                            )}
                            {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && editModeOn && (
                                <IconButton
                                    color="primary"
                                    aria-label="save"
                                    style={{ height: 'fit-content', zIndex: 10 }}
                                    onClick={onNewWorkoutNameSave}
                                >
                                    <SaveIcon fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={classes.assignmentChipTitleSmall}>Groups</Typography>
                                <div style={{ display: 'flex' }}>
                                    {workoutState?.department?.map((g) => {
                                        return (
                                            <Chip
                                                key={`department_${g}_${workoutState.id}`}
                                                label={g}
                                                className={classes.assignementChipSmall}
                                                color="primary"
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{ width: 10, borderRight: '1px solid #D3D3D3', marginRight: 10 }} />

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={classes.assignmentChipTitleSmall}>Sides</Typography>
                                <div style={{ display: 'flex' }}>
                                    {workoutState?.side?.map((s) => {
                                        return (
                                            <Chip
                                                key={`side${s}_${workoutState.id}`}
                                                label={s}
                                                className={classes.assignementChipSmall}
                                                color="primary"
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div style={{ width: 10, borderRight: '1px solid #D3D3D3', marginRight: 10 }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={classes.assignmentChipTitleSmall}>Positions</Typography>
                                <div style={{ display: 'flex' }}>
                                    {workoutState?.position?.map((pos) => {
                                        return (
                                            <Chip
                                                key={`position${pos}_${workoutState.id}`}
                                                label={pos}
                                                className={classes.assignementChipSmall}
                                                color="primary"
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {large && (
                        <>
                            <div style={{ display: 'flex', width: '100%', padding: 10 }}>
                                {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && editHeader}
                            </div>
                            <SortableList
                                days={workoutState.days ?? []}
                                onSortEnd={onSortEnd}
                                axis={'xy'}
                                useDragHandle
                            />
                        </>
                    )}
                    {!large && (
                        <>
                            <Carousel animation="slide" autoPlay={false} navButtonsAlwaysInvisible timeout={300}>
                                {workoutState.days?.map((day: any) => {
                                    return (
                                        <WorkoutDay
                                            workoutDay={day}
                                            workoutId={workoutState.id}
                                            exercisesMap={exercisesMapped(day.exercises)}
                                            onDeleteWorkoutDay={onDeleteWorkoutDay}
                                        />
                                    );
                                })}
                            </Carousel>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Typography style={{ fontSize: '10px', fontStyle: 'italic' }}>
                                    *swipe or press bubbles to see other days
                                </Typography>
                            </div>
                        </>
                    )}

                    {large && ['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && (
                        <div
                            style={{ display: 'flex', alignItems: 'center', width: '250px', justifyContent: 'center' }}
                        >
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={addWorkoutDay}
                            >
                                <QueueIcon style={{ fontSize: 100 }} />
                            </IconButton>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
            {deleteDialogOpen && deleteDialog}
        </div>
    );
};

export default Workout;
