import CancelIcon from '@mui/icons-material/Cancel';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { createUseStyles } from 'react-jss';
import useSound from 'use-sound';

const styles = createUseStyles({
    dialogRoot: {
        minWidth: 300,
        borderRadius: '10px!important',
    },
    titleRoot: {
        padding: '0px!important',
        paddingLeft: '25px!important',
        backgroundColor: 'rgba(0,86,44)!important',
        color: 'white!important',
    },
    timeWrapper: {
        position: 'relative',
        height: 60,
        width: 60,
    },
    time: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.2s',
        opacity: 1,
        transform: 'translateY(0)',
    },
    timeUp: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.2s',
        opacity: 0,
        transform: 'translateY(-100%)',
    },

    timeDown: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.2s',
        opacity: 0,
        transform: 'translateY(100%)',
    },
    headerButtonRoot: {
        padding: '5px!important',
    },
});

const marks = [
    {
        value: 30,
        label: '30s',
    },
    {
        value: 45,
        label: '45s',
    },
    {
        value: 60,
        label: '60s',
    },
    {
        value: 90,
        label: '90s',
    },
];

const PrettoSlider = styled(Slider)({
    color: 'rgba(0,86,44)',
    height: 8,
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid rgba(0,86,44)',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    markLabel: {
        fontWeight: 'bold',
    },
    mark: {
        height: 8,
    },
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
});

interface CountDownDialogProps {
    open: boolean;
    onCountdownClose: () => void;
}

const CountDownDialog = (props: CountDownDialogProps): JSX.Element => {
    const { open, onCountdownClose } = props;
    const classes = styles();
    const beepSound = require('../../assets/sounds/beep.mp3');
    const [play, { stop }] = useSound(beepSound);

    const [duration, setDuration] = useState(30);
    const [timerKey, setTimerKey] = useState(0);
    const [notifyOn, setNotifyOn] = useState(true);
    const [timerRunning, setTimerRunning] = useState(false);

    const onTimerComplete = useCallback(() => {
        if (notifyOn) {
            play();
        }
        setTimeout(() => {
            stop();
            onCountdownClose();
        }, 3000);
    }, [onCountdownClose, play, notifyOn]);

    const onClose = useCallback(() => {
        onCountdownClose();
    }, [onCountdownClose]);

    const Label = ({ remainingTime }) => {
        const currentTime = useRef(remainingTime);
        const prevTime = useRef(null);
        const isNewTimeFirstTick = useRef(false);
        const [, setOneLastRerender] = useState(0);

        if (currentTime.current !== remainingTime) {
            isNewTimeFirstTick.current = true;
            prevTime.current = currentTime.current;
            currentTime.current = remainingTime;
        } else {
            isNewTimeFirstTick.current = false;
        }

        // force one last re-render when the time is over to tirgger the last animation
        if (remainingTime === 0) {
            setTimeout(() => {
                setOneLastRerender((val) => val + 1);
            }, 20);
        }

        const isTimeUp = isNewTimeFirstTick.current;

        return (
            <div className={classes.timeWrapper}>
                <div key={remainingTime} className={isTimeUp ? classes.timeUp : classes.time}>
                    <Typography variant="h3" color={remainingTime > 5 ? 'primary' : 'secondary'}>
                        {remainingTime}
                    </Typography>
                </div>
                {prevTime.current !== null && (
                    <div key={prevTime.current} className={!isTimeUp ? classes.timeDown : classes.time}>
                        <Typography variant="h3">{prevTime.current}</Typography>
                    </div>
                )}
            </div>
        );
    };

    const onStartStopClick = useCallback(() => {
        setTimerKey((prevKey) => prevKey + 1);
        setTimerRunning((isRunning: boolean) => !isRunning);
    }, [setTimerRunning, setTimerKey]);

    const countDown = useMemo(() => {
        return (
            <CountdownCircleTimer
                key={timerKey}
                isPlaying={timerRunning}
                duration={duration}
                colors="#00562c"
                onComplete={onTimerComplete}
                initialRemainingTime={duration}
                strokeWidth={18}
            >
                {Label}
            </CountdownCircleTimer>
        );
    }, [duration, timerRunning, timerKey]);

    const onSliderChange = useCallback(
        (e, v) => {
            setDuration(v);
            setTimerKey((prevKey) => prevKey + 1);
            setTimerRunning(false);
        },
        [setTimerKey, setDuration, setTimerRunning],
    );

    return (
        <Dialog open={open} onClose={() => {}} classes={{ paper: classes.dialogRoot }}>
            <DialogTitle id="simple-dialog-title" classes={{ root: classes.titleRoot }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: 50,
                        paddingRight: 10,
                    }}
                >
                    <Typography color="primary" variant="h5" style={{ fontWeight: 'bold', color: 'white' }}>
                        Countdown
                    </Typography>
                    <div>
                        <IconButton
                            aria-label="edit"
                            onClick={() => setNotifyOn((isOn) => !isOn)}
                            style={notifyOn ? { color: '#f6b800' } : {}}
                            classes={{ root: classes.headerButtonRoot }}
                        >
                            {notifyOn ? <NotificationsActiveIcon /> : <NotificationsOffIcon />}
                        </IconButton>
                        <IconButton aria-label="edit" onClick={onClose} classes={{ root: classes.headerButtonRoot }}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ paddingTop: 25 }}>
                    <PrettoSlider
                        defaultValue={duration}
                        aria-labelledby="discrete-slider-always"
                        step={5}
                        marks={marks}
                        valueLabelDisplay="auto"
                        onChange={(e, v) => onSliderChange(e, v)}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>{countDown}</div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <Button onClick={onStartStopClick} variant="contained" color="primary">
                        {timerRunning ? 'Reset' : 'Start'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default CountDownDialog;
