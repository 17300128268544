import { httpGetSpring, httpPostSpring } from '../util/HttpSpring';

import { call, put, select, takeEvery } from 'redux-saga/effects';

import Endpoints from '../util/endpoints';
import UsageActions from '../redux-actions/UsageActions';
import { StateSelectors } from '../reducers/StateSelectors';
import UserActions from '../redux-actions/UserActions';

export function* createUsageEntry({ payload: { resource } }) {
    const user = yield select(StateSelectors.getUser);
    try {
        yield call(httpPostSpring, Endpoints.createUsageEntry, generateUsageEntry(user, resource));
        // yield put(UsageActions.getUsageEntriesForResource('login'));
    } catch (e) {
        console.log(e);
    }
}

export function* getUsageEntries() {
    try {
        const usageSummary = yield call(httpGetSpring, Endpoints.getUsageSummary);
    } catch (e) {
        console.log(e);
    }
}

export function* getUsageEntriesForResource({ payload: { resource } }) {
    try {
        const usage = yield call(httpGetSpring, Endpoints.getUsageSummaryForResource, { params: { resource } });
        yield put(UsageActions.getUsageEntriesForResourceSuccess(resource, usage.data));
    } catch (e) {
        yield put(UsageActions.getUsageEntriesForResourceError(e));
    }
}

const generateUsageEntry = (user, resource) => {
    return {
        userId: user.data.credentials.userId,
        email: user.data.credentials.email,
        role: user.data.credentials.role,
        resource,
        position: user.data.profile.position,
        side: user.data.profile.side,
        department: user.data.profile.department,
        experience: user.data.profile.experience,
    };
};

export default function* usageSagas() {
    yield takeEvery(UsageActions.createUsageEntry.toString(), createUsageEntry);
    yield takeEvery(UsageActions.getUsageEntries.toString(), getUsageEntries);
    yield takeEvery(UsageActions.getUsageEntriesForResource.toString(), getUsageEntriesForResource);
}
