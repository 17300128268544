import { createAction } from 'redux-actions';

export default class InvitationActions {
    static getPending = createAction('PENDING_INVITES_REQUESTED', () => ({}));
    static getPendingSuccess = createAction('PENDING_INVITES_SUCCESS', (invitations) => ({
        invitations,
    }));
    static getPendingError = createAction('PENDING_INVITES_ERROR', (message) => ({ message }));

    static generateInvitationCode = createAction('CREATE_INVITE_REQUESTED', (invitationConfig) => ({
        invitationConfig,
    }));

    static generateInvitationCodeSuccess = createAction('CREATE_INVITE_SUCCESS', (invite) => ({
        invite,
    }));
}
