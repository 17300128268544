import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { StateSelectors } from '../../reducers/StateSelectors';

const styles = createUseStyles({
    dialog: {
        minWidth: 350,
    },
    dialogContent: {
        padding: 5,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    dialogItem: {
        marginBottom: 10,
    },
    chip: {
        margin: 2,
        fontSize: '10px!important',
        height: '20px!important',
        backgroundColor: 'rgb(0,0,0)!important',
        color: 'white!important',
    },
    selectGroupLabel: {
        color: 'rgba(5, 116, 19)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
    assignementChip: {
        margin: 2,
        fontSize: '12px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
});

interface ExerciseDialogProps {
    item?: any;
    mode: string;
    onSavePress: (exercise: any) => void;
    open: boolean;
    onCancelPress: () => void;
    title: string;
}

const ExerciseDialog = (props: ExerciseDialogProps): JSX.Element => {
    const { item, mode, onSavePress, onCancelPress, open, title } = props;

    const classes = styles();

    const attributes = useSelector(StateSelectors.getAttributes);

    const [itemToBeSaved, setItemToBeSaved] = useState(mode === 'edit' ? item : {});

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            setItemToBeSaved((priorItemToBeSaved) => ({
                ...priorItemToBeSaved,
                description: { ...priorItemToBeSaved.description, [field]: event.target?.value },
            }));
        },
        [setItemToBeSaved],
    );

    const onMuscleGroupsChange = useCallback(
        (value) => {
            setItemToBeSaved((priorItemToBeSaved) => ({
                ...priorItemToBeSaved,
                description: { ...priorItemToBeSaved.description, muscleGroups: value },
            }));
        },
        [setItemToBeSaved],
    );

    const onMuscleGroupsDetailedChange = useCallback(
        (value) => {
            setItemToBeSaved((priorItemToBeSaved) => ({
                ...priorItemToBeSaved,
                description: { ...priorItemToBeSaved.description, muscleGroupsDetailed: value },
            }));
        },
        [setItemToBeSaved],
    );

    const onExerciseTypeChange = useCallback(
        (value) => {
            setItemToBeSaved((priorItemToBeSaved) => ({
                ...priorItemToBeSaved,
                description: { ...priorItemToBeSaved.description, type: value },
            }));
        },
        [setItemToBeSaved],
    );

    const onMeasureInputFieldChange = useCallback(
        (field, event) => {
            // event.persist();
            //@ts-ignore
            setItemToBeSaved((priorItemToBeSaved) => {
                let currentMeasures = priorItemToBeSaved.description?.measures
                    ? [...priorItemToBeSaved.description.measures]
                    : [];
                if (event.target?.checked) {
                    currentMeasures.push(field);
                } else {
                    currentMeasures = currentMeasures.filter((measure: string) => measure !== field);
                }

                return {
                    ...priorItemToBeSaved,
                    description: {
                        ...priorItemToBeSaved.description,
                        measures: [...currentMeasures],
                    },
                };
            });
        },
        [setItemToBeSaved],
    );

    const onCancelExercisePress = useCallback(() => {
        onCancelPress();
    }, [onCancelPress]);

    const onSaveExercisePress = useCallback(() => {
        onSavePress(itemToBeSaved);
    }, [itemToBeSaved]);

    return (
        <Dialog fullWidth={true} open={open} onClose={() => {}}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <div className={classes.dialogContent}>
                    <div className={classes.dialogItem}>
                        <TextField
                            id="outlined-exerciseNameText"
                            label="Name"
                            variant="outlined"
                            defaultValue={itemToBeSaved?.description?.name}
                            onChange={(e) => onInputFieldChange('name', e)}
                            error={itemToBeSaved?.description?.name?.length === 0}
                            style={{ width: 'calc(100% - 10px)' }}
                        />
                    </div>
                    <div className={classes.dialogItem}>
                        <TextField
                            id="outlined-exerciseCommentText"
                            label="Comment"
                            variant="outlined"
                            defaultValue={itemToBeSaved?.description?.comment}
                            onChange={(e) => onInputFieldChange('comment', e)}
                            style={{ width: 'calc(100% - 10px)' }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '100%',
                            marginBottom: 10,
                            flexDirection: 'column',
                        }}
                    >
                        <Autocomplete
                            id="muscles-detailed-select"
                            options={attributes.data?.muscleGroupsDetailed || []}
                            value={itemToBeSaved?.description?.muscleGroupsDetailed || []}
                            onChange={(e, v) => onMuscleGroupsDetailedChange(v)}
                            classes={{ groupLabel: classes.selectGroupLabel }}
                            style={{ width: 'calc(100% - 10px)', marginBottom: 10 }}
                            multiple
                            groupBy={(option: any) => option.group}
                            getOptionLabel={(option: any) => {
                                return option.title;
                            }}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option.title}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChip}
                                        color="primary"
                                    />
                                ))
                            }
                            renderInput={(params) => {
                                return <TextField {...params} variant="outlined" placeholder="Select Muscles..." />;
                            }}
                        />
                        <Autocomplete
                            id="type-select"
                            options={attributes.data?.exerciseTypes || []}
                            value={itemToBeSaved?.description?.type || []}
                            onChange={(e, v) => onExerciseTypeChange(v)}
                            style={{ width: 'calc(100% - 10px)' }}
                            renderInput={(params) => {
                                return <TextField {...params} variant="outlined" label="Exercise-Type" />;
                            }}
                        />
                    </div>
                    <div className={classes.dialogItem}>
                        <FormLabel component="legend">Measurements</FormLabel>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {attributes.data.measures?.map((measure: string) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={itemToBeSaved.description?.measures?.includes(measure)}
                                                onChange={(e) => onMeasureInputFieldChange(measure, e)}
                                                name="isMeasuredByWeight"
                                            />
                                        }
                                        label={measure.toUpperCase()}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={classes.dialogItem}>
                        <TextField
                            id="outlined-exerciseNameText"
                            label="Youtube Video ID"
                            variant="outlined"
                            defaultValue={itemToBeSaved?.description?.videoId}
                            onChange={(e) => onInputFieldChange('videoId', e)}
                            style={{
                                width: 'calc(100% - 10px)',
                            }}
                        />
                    </div>
                    <Accordion style={{ width: 'calc(100% - 10px)' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="strength-content"
                            id="strength-header"
                        >
                            <Typography>Info</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                id="outlined-exerciseInfoText"
                                variant="outlined"
                                multiline
                                defaultValue={itemToBeSaved?.description?.info}
                                onChange={(e) => onInputFieldChange('info', e)}
                                style={{ width: 'calc(100% - 10px)' }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelExercisePress} variant="outlined">
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={onSaveExercisePress}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExerciseDialog;

// rxD321l2svE
