import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { StateSelectors } from '../../reducers/StateSelectors';

const styles = createUseStyles({
    frame: {
        padding: 20,
    },
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    section: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginTop: 50,
    },
    headerContainer: {
        display: 'flex',
        height: '50px',
        marginBottom: 20,
    },
    headerTitle: {
        fontSize: '28px !important',
        fontWeight: 'bold!important',
    },
});

interface SupportProps {}

const Support = (props: SupportProps): JSX.Element => {
    const classes = styles();

    const actionResponse = useSelector(StateSelectors.getActionresponses);

    return (
        <div className={classes.frame}>
            <div className={classes.headerContainer}>
                <Typography className={classes.headerTitle} color="primary">
                    Support
                </Typography>
            </div>
            <div className={classes.container}>
                <div className={classes.section}>
                    <Typography variant="h5" style={{ marginBottom: 10 }}>
                        Technical Support
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ marginTop: 20 }}>Dear User</Typography>

                        <Typography style={{ marginTop: 20 }}>
                            In case of any technical issues or questions please write us an Email.
                        </Typography>

                        <Typography style={{ marginTop: 20 }}>
                            Please provide at least the following details:
                        </Typography>
                        <Typography style={{ marginTop: 5, fontStyle: 'italic' }}>
                            Your User-ID, Which Menu-Item is affected? How to reproduce the error? <br />
                            Do you have a screenshot of the error?
                        </Typography>
                        <Typography style={{ marginTop: 20 }}>
                            We will respond to you as fast as we can and will take every request serious.
                        </Typography>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                                width: 310,
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                            }}
                        >
                            <MailIcon color="primary" />
                            <Typography>
                                <a
                                    href="mailto:thg-support@heidelberg-hunters.de"
                                    style={{
                                        borderBottom: 'none',
                                        color: 'rgba(5, 116, 19)',
                                        textDecoration: 'none',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    thg-support@heidelberg-hunters.de
                                </a>
                            </Typography>
                            <Typography style={{ fontSize: '8px', marginLeft: 35 }}>
                                *you can click here to open your mailclient
                            </Typography>
                        </div>

                        <Typography style={{ marginTop: 20 }}>
                            Please communicate feature-requests through your coaches and refrain from sending them to us
                            directly.
                        </Typography>

                        <Typography style={{ marginTop: 20 }}>Your Support Team</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
