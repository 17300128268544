import Button from '@mui/material/Button';
import React from 'react';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    menuItemButtonLabel: {
        color: '#fafafa',
    },
});

interface MenuButtonProps {
    icon: any;
    isSelected: boolean;
    onPress: () => void;
    buttonText: string;
}
const MenuButton = (props: MenuButtonProps): JSX.Element => {
    const { isSelected, icon, onPress, buttonText } = props;
    const classes = styles();

    return (
        <Button
            startIcon={icon}
            style={{
                width: '100%',
                justifyContent: 'flex-start',
                backgroundColor: isSelected ? 'rgba(5, 116, 19, .3)' : 'transparent',
                marginBottom: 5,
                color: '#fafafa',
                padding: 10,
                borderRadius: 5,
            }}
            onClick={onPress}
        >
            {buttonText}
        </Button>
    );
};

export default MenuButton;
