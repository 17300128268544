import { handleActions } from 'redux-actions';
import UsageActions from '../redux-actions/UsageActions';

const initialUserState = {
    loading: null,
    error: null,
    data: {},
};

const usage = handleActions(
    {
        [UsageActions.getUsageEntries]: (state) => {
            return {
                ...state,
                data: null,
                loading: true,
                error: false,
            };
        },
        [UsageActions.getUsageEntriesForResource]: (state, { payload: { resource } }) => {
            return {
                ...state,
                loading: true,
            };
        },
        [UsageActions.getUsageEntriesForResourceSuccess]: (state, { payload: { resource, usage } }) => {
            return {
                ...state,
                loading: false,
                data: { ...state.data, [resource]: usage },
            };
        },
        [UsageActions.getUsageEntriesForResourceError]: (state, { payload: { message } }) => {
            return {
                ...state,
                error: message,
            };
        },
    },
    initialUserState,
);

export const getUsage = (state) => state;

export default usage;
