import { call, put, takeLatest } from 'redux-saga/effects';
import ActionResponseActions from '../redux-actions/ActionResponseActions';
import ExercisesActions from '../redux-actions/ExercisesActions';
import Endpoints from '../util/endpoints';
import { httpDelete, httpGet, httpPut } from '../util/Http';

export function* fetchDefinedExercises() {
    try {
        const definedExercises = yield call(httpGet, Endpoints.readExerciseDefinitions);
        yield put(ExercisesActions.getDefinedExercisesSuccess(definedExercises.data));
    } catch (e) {
        yield put(ExercisesActions.getDefinedExercisesError(e));
    }
}

export function* saveExercise({ payload: { exercise } }) {
    try {
        const exerciseNew = yield call(httpPut, Endpoints.saveExerciseDefinition, exercise);
        yield put(ExercisesActions.saveExerciseDefinitionSuccess(exerciseNew.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Add Exercise',
                severity: 'success',
                message: 'Exercise added',
            }),
        );
    } catch (e) {
        yield put(ExercisesActions.saveExerciseDefinitionError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Add Exercise',
                severity: 'error',
                message: 'Could not add Exercise',
            }),
        );
    }
}

export function* updateExercise({ payload: { exercise } }) {
    try {
        const exerciseNew = yield call(httpPut, Endpoints.saveExerciseDefinition, exercise);
        yield put(ExercisesActions.updateExerciseDefinitionSuccess(exercise));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Exercise',
                severity: 'success',
                message: 'Exercise updated',
            }),
        );
    } catch (e) {
        yield put(ExercisesActions.updateExerciseDefinitionError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Exercise',
                severity: 'error',
                message: 'Could not updated Exercise',
            }),
        );
    }
}

export function* deleteExercise({ payload: { id } }) {
    try {
        const exerciseNew = yield call(httpDelete, `${Endpoints.deleteExerciseDefinition}/${id}`);
        yield put(ExercisesActions.deleteExerciseDefinitionSuccess(id));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Exercise',
                severity: 'success',
                message: 'Exercise deleted',
            }),
        );
    } catch (e) {
        yield put(ExercisesActions.deleteExerciseDefinitionError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Exercise',
                severity: 'error',
                message: 'Could not delete Exercise',
            }),
        );
    }
}

export default function* definedExercisesSagas() {
    yield takeLatest(ExercisesActions.getDefinedExercises.toString(), fetchDefinedExercises);
    yield takeLatest(ExercisesActions.saveExerciseDefinition.toString(), saveExercise);
    yield takeLatest(ExercisesActions.updateExerciseDefinition.toString(), updateExercise);
    yield takeLatest(ExercisesActions.deleteExercise.toString(), deleteExercise);
}
