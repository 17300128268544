import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface LoadingIndicatorProps {
    size?: number;
    text?: string;
}
const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
    const classes = styles();
    const { size, text } = props;

    return (
        <div className={classes.contentContainer}>
            <CircularProgress size={size} />
            {text && <Typography>{text}</Typography>}
        </div>
    );
};

export default LoadingIndicator;
