import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import hunterLogo from '../../assets/hunters-head.png';
import DisabledProfile from '../../components/DisabledProfile';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UserActions from '../../redux-actions/UserActions';
import { navigate } from '../../routing/Router';
import { useMedia } from '../../util/useMedia';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = createUseStyles({
    container: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#eeeeee',
        padding: 5,
    },
    headerText: {
        color: 'rgba(5, 116, 19)',
        fontWeight: 'bold!important',
        textShadow: '2px 2px 3px black',
    },
    formContainer: {
        display: 'flex',
        wodth: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    input: {
        margin: 5,
    },
    loginButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    errorText: {
        color: 'red',
    },
});

interface LoginProps {}
const Login = (props: LoginProps): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const user = useSelector(StateSelectors.getUser);

    const [logIn, resetPassword] = useActions([
        (credentials: any) => UserActions.logIn(credentials),
        (credentials: any) => UserActions.resetPassword(credentials),
    ]);

    const [credentialsEntered, setCredentialsEntered] = useState({});
    const [forgotPassword, setForgotPassword] = useState(false);

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            setCredentialsEntered((priorCredentialsEntered) => ({
                ...priorCredentialsEntered,
                [field]: event.target?.value,
            }));
        },
        [setCredentialsEntered],
    );

    const onForgotPasswordClick = useCallback(() => {
        setForgotPassword((current) => !current);
    }, [setForgotPassword]);

    const onActionFire = useCallback(() => {
        if (forgotPassword) {
            resetPassword(credentialsEntered);
            setForgotPassword((current) => !current);
        } else logIn(credentialsEntered);
    }, [forgotPassword, credentialsEntered, logIn, resetPassword, setForgotPassword]);

    const login = () => {
        return (
            <FormControl>
                <div className={classes.formContainer}>
                    <div className={classes.input}>
                        <TextField
                            id="outlined-helperText"
                            label={`Email${forgotPassword ? '' : ' or User-ID'} `}
                            variant="outlined"
                            required
                            onChange={(e) => onInputFieldChange('userName', e)}
                            style={{ width: '300px' }}
                            error={user.loginAttempts > 0}
                        />
                    </div>
                    {!forgotPassword && (
                        <div className={classes.input}>
                            <TextField
                                id="filled-password-input"
                                label="Password"
                                type="password"
                                variant="outlined"
                                required
                                style={{ width: '300px' }}
                                onChange={(e) => onInputFieldChange('password', e)}
                                error={user.loginAttempts > 0}
                            />
                        </div>
                    )}
                </div>
                {user.loginAttempts > 0 && !forgotPassword && (
                    <Typography className={classes.errorText} align="center">
                        Something went wrong, please try again and make sure you entered the correct credentials
                    </Typography>
                )}
                {user.loginAttempts > 0 && forgotPassword && (
                    <Typography className={classes.errorText} align="center">
                        We will send you a new password to the email entered
                    </Typography>
                )}
                <div className={classes.loginButtonContainer}>
                    {user.loginAttempts > 0 && (
                        <Button color="primary" onClick={onForgotPasswordClick}>
                            {forgotPassword ? 'Remeber Password?' : 'Forgot Password?'}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '200px', height: '50px', marginTop: 20, marginBottom: 10 }}
                        onClick={onActionFire}
                    >
                        {forgotPassword ? 'Send new Password' : 'Login'}
                    </Button>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate('/register')}
                        style={{ fontSize: 16 }}
                    >
                        Register
                    </Link>
                </div>
            </FormControl>
        );
    };

    return (
        <div className={classes.container}>
            <img src={hunterLogo} alt="Logo" style={{ width: '50%', maxWidth: '500px' }} />
            <Typography variant={large ? 'h1' : 'h3'} component="h2" gutterBottom className={classes.headerText}>
                The Hunt Game
            </Typography>
            {user.data?.toBeDeleted && <DisabledProfile />}
            {!user.data?.toBeDeleted && login()}
        </div>
    );
};

export default Login;
