import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import BaseDialog from '../base/BaseDialog';

const styles = createUseStyles({
    assignementChipSmall: {
        margin: 2,
        fontSize: '8px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    selectGroupLabel: {
        color: 'rgba(5, 116, 19)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
});

interface ChangePasswordDialogProps {
    open: boolean;
    onApplyPress: (passwords) => void;
}

const ChangePasswordDialog = (props: ChangePasswordDialogProps): JSX.Element => {
    const { open, onApplyPress } = props;
    const classes = styles();

    const [credentials, setCredentials] = useState({ oldPassword: null, newPassword: null, newPasswordRepeat: null });
    const [passwordValid, setPasswordValid] = useState<boolean>();

    useEffect(() => {
        if (credentials.oldPassword && credentials.newPassword && credentials.newPasswordRepeat) {
            if (credentials.newPassword === credentials.newPasswordRepeat) {
                setPasswordValid(true);
            }
        } else {
            setPasswordValid(false);
        }
    }, [setPasswordValid, credentials]);

    const onSaveApplyPress = useCallback(() => {
        onApplyPress(credentials);
    }, [credentials]);

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            setCredentials((current: any) => ({ ...current, [field]: event.target?.value }));
        },
        [setCredentials],
    );

    const renderPasswords = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    margin: 5,
                    flexWrap: 'wrap',
                    width: 'calc(100% - 10px)',
                    flexDirection: 'column',
                }}
            >
                <TextField
                    id="outlined-old"
                    label="Interims Password"
                    variant="outlined"
                    type="password"
                    required
                    onChange={(e) => onInputFieldChange('oldPassword', e)}
                    style={{
                        marginBottom: 15,
                    }}
                />
                <TextField
                    id="outlined-new"
                    label="New Password"
                    variant="outlined"
                    type="password"
                    required
                    onChange={(e) => onInputFieldChange('newPassword', e)}
                    style={{
                        marginBottom: 10,
                    }}
                />
                <TextField
                    id="outlined-newRepeat"
                    label="Repeate New Password"
                    variant="outlined"
                    type="password"
                    required
                    onChange={(e) => onInputFieldChange('newPasswordRepeat', e)}
                    style={{}}
                />
            </div>
        );
    };

    const renderDialogActions = () => {
        return [
            <Button color="primary" variant="contained" onClick={onSaveApplyPress} disabled={!passwordValid}>
                Apply
            </Button>,
        ];
    };

    return (
        <BaseDialog
            title={' You have to change your password'}
            open={open}
            renderContent={renderPasswords()}
            actions={renderDialogActions()}
        />
    );
};

export default ChangePasswordDialog;

// rxD321l2svE
