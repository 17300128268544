import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useActions } from '../hooks/useActions';
import UserActions from '../redux-actions/UserActions';

const styles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    logoutLabel: {
        fontSize: '10px!important',
    },
});

const DisabledProfile = (): JSX.Element => {
    const classes = styles();

    const [logout, reactivateProfile] = useActions([
        () => UserActions.logout(),
        () => UserActions.reactivateUserProfile(),
    ]);

    const onLogoutPress = useCallback(() => {
        localStorage.removeItem('THG_AUTH_HEADER');
        logout();
    }, [localStorage]);

    const onReactivateProfilePress = useCallback(() => {
        reactivateProfile();
    }, [reactivateProfile]);

    return (
        <div className={classes.container}>
            <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>
                {' '}
                Your profile is about to be deleted
            </Typography>
            <Button onClick={onReactivateProfilePress} variant="contained" color="primary">
                Reactivate my Profile
            </Button>
            <Button onClick={onLogoutPress} color="primary" classes={{ text: classes.logoutLabel }}>
                Logout
            </Button>
        </div>
    );
};

export default DisabledProfile;
