import { createAction } from 'redux-actions';

export default class UsageActions {
    static getUsageEntries = createAction('USAGE_ENTRY_GET_REQUESTED', () => ({}));
    static getUsageEntriesForResource = createAction('USAGE_ENTRY_RESOURCE_GET_REQUESTED', (resource) => ({
        resource,
    }));
    static getUsageEntriesForResourceSuccess = createAction('USAGE_ENTRY_RESOURCE_GET_SUCCESS', (resource, usage) => ({
        resource,
        usage,
    }));
    static getUsageEntriesForResourceError = createAction('USAGE_ENTRY_RESOURCE_GET_ERROR', (message) => ({
        message,
    }));
    static createUsageEntry = createAction('USAGE_ENTRY_CREATE_REQUESTED', (resource) => ({
        resource,
    }));
}
