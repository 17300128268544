import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import anatomy from '../../assets/education/anatomy.jpg';
import effort from '../../assets/education/effort.jpeg';
import THGCard from '../../components/THGCard';
import { useMedia } from '../../util/useMedia';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const styles = createUseStyles({
    contentContainer: {
        height: '100%',
        width: '100%',
        paddingTop: 10,

        overflow: 'hidden',
        position: 'relative',
    },
    accordionOuter: {
        width: '100%',
        backgroundColor: 'transparent!important',
        borderRadius: '0px !important',
        borderBottom: '1px solid rgba(5, 116, 19, .3)',
        boxShadow: 'none!important',
    },
    accordionHeader: {
        fontSize: '1.5rem !important',
        fontWeight: 'bold!important',
        marginRight: '10px!important',
    },
    accordionDetails: {
        display: 'flex',
        alignItems: 'center',
        width: '100%!impportant',
        flexWrap: 'wrap',
        padding: '20px !important',
    },
    arrowIcon: {},
    actionButton: {
        marginTop: '5px!important',
    },
});
const Education = (): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const [anatomyVisible, setAnatomyVisible] = useState(large);
    const [effortsVisible, setEffortsVisible] = useState(large);

    const desktopContent = () => {
        return (
            <>
                <Accordion className={classes.accordionOuter}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="anatomy-content"
                        id="anatomy-header"
                    >
                        <Typography className={classes.accordionHeader}>Anatomy </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <img
                            src={anatomy}
                            alt="ANATOMY"
                            style={{ width: '100%', maxWidth: '800px', borderRadius: 10 }}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion className={classes.accordionOuter}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="efforts-content"
                        id="efforts-header"
                    >
                        <Typography className={classes.accordionHeader}>Efforts </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <img src={effort} alt="EFFORT" style={{ width: '100%', maxWidth: '800px', borderRadius: 10 }} />
                    </AccordionDetails>
                </Accordion>
            </>
        );
    };

    const onShowHideAnatomyPress = useCallback(() => {
        setAnatomyVisible((visible) => !visible);
    }, [setAnatomyVisible]);

    const onShowHideEffortsPress = useCallback(() => {
        setEffortsVisible((visible) => !visible);
    }, [setEffortsVisible]);

    const anatomyGroupAction = () => (
        <IconButton aria-label="settings" onClick={onShowHideAnatomyPress} classes={{ root: classes.actionButton }}>
            {!anatomyVisible && (
                <ChevronRightIcon
                    color="inherit"
                    classes={{ root: classes.arrowIcon }}
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
            )}
            {anatomyVisible && (
                <ExpandMoreIcon color="inherit" classes={{ root: classes.arrowIcon }} style={{ color: '#fafafa' }} />
            )}
        </IconButton>
    );

    const effortsGroupAction = () => (
        <IconButton aria-label="settings" onClick={onShowHideEffortsPress} classes={{ root: classes.actionButton }}>
            {!effortsVisible && (
                <ChevronRightIcon
                    color="inherit"
                    classes={{ root: classes.arrowIcon }}
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
            )}
            {effortsVisible && (
                <ExpandMoreIcon color="inherit" classes={{ root: classes.arrowIcon }} style={{ color: '#fafafa' }} />
            )}
        </IconButton>
    );

    const mobileContent = () => {
        return (
            <>
                <THGCard
                    content={
                        anatomyVisible && (
                            <div style={{ marginTop: 10 }}>
                                <img src={anatomy} alt="ANATOMY" style={{ width: '100%', borderRadius: 10 }} />
                            </div>
                        )
                    }
                    loading={false}
                    title={`Anatomy`}
                    subheader=""
                    action={anatomyGroupAction()}
                    headerTransparent={!anatomyVisible}
                />
                <THGCard
                    content={
                        effortsVisible && (
                            <div style={{ marginTop: 10 }}>
                                <img src={effort} alt="EFFORT" style={{ width: '100%', borderRadius: 10 }} />
                            </div>
                        )
                    }
                    loading={false}
                    title={`Efforts`}
                    subheader=""
                    action={effortsGroupAction()}
                    headerTransparent={!effortsVisible}
                />
            </>
        );
    };

    return (
        <div className={classes.contentContainer}>
            {large && desktopContent()}
            {!large && mobileContent()}
        </div>
    );
};

export default Education;
