import AppsIcon from '@mui/icons-material/Apps';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import LockIcon from '@mui/icons-material/Lock';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import DataUsageIcon from '@mui/icons-material/DataUsage';

import React from 'react';

const menu = [
    {
        id: 'dashboard',
        icon: <DashboardIcon />,
        buttonText: 'Dashboard',
        path: '/',
        roles: ['ALL'],
        placement: 'TOP',
    },
    {
        id: 'workouts',
        icon: <FitnessCenterIcon />,
        buttonText: 'Workouts',
        path: '/thg/workouts',
        roles: ['ALL'],
        placement: 'TOP',
    },
    {
        id: 'catalog',
        icon: <AppsIcon />,
        buttonText: 'Catalog',
        path: '/thg/catalog',
        roles: ['ALL'],
        placement: 'TOP',
    },
    {
        id: '1rm',
        icon: <SwapVerticalCircleIcon />,
        buttonText: '1-RM',
        path: '/thg/1-rm',
        roles: ['ALL'],
        placement: 'TOP',
    },
    {
        id: 'education',
        icon: <MenuBookIcon />,
        buttonText: 'Education',
        path: '/thg/education',
        roles: ['ALL'],
        placement: 'TOP',
    },
    {
        id: 'user-management',
        icon: <LockIcon />,
        buttonText: 'User Management',
        path: '/thg/user-management',
        roles: ['ADMIN', 'SUPERVISOR'],
        rights: ['manage-access'],
        placement: 'BOTTOM',
    },
    {
        id: 'catalog-management',
        icon: <MenuBookIcon />,
        buttonText: 'Catalog Management',
        path: '/thg/catalog-management',
        roles: ['ADMIN', 'SUPERVISOR'],
        placement: 'BOTTOM',
    },
    {
        id: 'usage',
        icon: <DataUsageIcon />,
        buttonText: 'Usage',
        path: '/thg/usage',
        roles: ['ADMIN', 'SUPERVISOR'],
        placement: 'BOTTOM',
    },
    // {
    //   id: 'performance',
    //   icon: <EqualizerIcon />,
    //   buttonText: 'Performance',
    //   path: '/thg/performance',
    //   roles: ['ADMIN', 'SUPERVISOR'],
    //   placement: 'BOTTOM'
    // },
];

// eslint-disable-next-line import/prefer-default-export
export { menu };
