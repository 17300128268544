import { all } from 'redux-saga/effects';
import attributesSagas from './attributesSagas';
import definedExercisesSagas from './exerciseDefinitionsSagas';
import invitationsSagas from './invitationsSagas';
import userSagas from './userSagas';
import workoutsSagas from './workoutsSagas';
import usageSagas from './usageSagas';

export default function* rootSaga() {
    yield all([
        definedExercisesSagas(),
        userSagas(),
        invitationsSagas(),
        attributesSagas(),
        workoutsSagas(),
        usageSagas(),
    ]);
}
