import { handleActions } from 'redux-actions';
import InvitationActions from '../redux-actions/InvitationActions';

const initialinvitationsState = {
    loading: null,
    error: null,
    data: [],
};

const invitations = handleActions(
    {
        [InvitationActions.getPending]: (state) => {
            return {
                data: [],
                loading: true,
                error: false,
            };
        },
        [InvitationActions.getPendingSuccess]: (state, { payload: { invitations } }) => {
            return {
                data: invitations,
                loading: false,
                error: false,
            };
        },
        [InvitationActions.getPendingError]: (state, { payload: { message } }) => {
            return {
                data: [],
                loading: false,
                error: message,
            };
        },
        [InvitationActions.generateInvitationCodeSuccess]: (state, { payload: { invite } }) => {
            return {
                ...state,
                data: [...state.data, invite],
            };
        },
    },
    initialinvitationsState,
);

export const getInvitations = (state) => state;
export default invitations;
