import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UsageActions from '../../redux-actions/UsageActions';
import WorkoutActions from '../../redux-actions/WorkoutActions';
import { useMedia } from '../../util/useMedia';
import Workout from './Workout';
import WorkoutMobile from './WorkoutMobile';

const styles = createUseStyles({
    container: {
        paddingTop: 10,

        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    createInput: {},
});
const Workouts = (): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const [loadAllWorkouts] = useActions([() => WorkoutActions.getAllWorkouts()]);
    const [createUsageEntryForWorkout] = useActions([(resource) => UsageActions.createUsageEntry(resource)]);
    const exercises = useSelector(StateSelectors.getDefinedExercises);
    const workouts = useSelector(StateSelectors.getWorkouts);

    const userProfile: any = useSelector(StateSelectors.getUserProfile);

    const [workoutsToBeShown, setWorkoutsToBeShown] = useState(workouts.data);
    const [userFilters] = useState({ sides: [] as any, positions: [] as any });

    useEffect(() => {
        loadAllWorkouts();
    }, [loadAllWorkouts, userProfile?.group, userProfile?.position, userProfile?.side]);

    useEffect(() => {
        setWorkoutsToBeShown(workouts.data);
    }, [setWorkoutsToBeShown, workouts]);

    //todo:fix
    useEffect(() => {
        const { sides, positions } = userFilters;

        setWorkoutsToBeShown((currentWorkoutsToBeShwon: any) => {
            return [...workouts.data].filter((workout: any) => {
                //@ts-ignore
                return sides.length > 0
                    ? workout.side.some((s: any) => sides.includes(s))
                    : true && positions.length > 0
                    ? workout.position.some((pos: any) => positions.includes(pos))
                    : true;
            });
        });
    }, [userFilters]);

    const onWorkoutOpenPress = useCallback(
        (resource) => {
            createUsageEntryForWorkout(`workout_${resource}`);
        },
        [createUsageEntryForWorkout],
    );

    return (
        <div className={classes.container}>
            {(exercises.loading || workoutsToBeShown.loading) && <LoadingIndicator size={120} text={'Loading...'} />}
            {!exercises.loading && !workoutsToBeShown.loading && (
                <div>
                    {workoutsToBeShown.map((workout: any) => {
                        return large ? (
                            <Workout workout={workout} key={workout.id} onWorkoutDayOpen={onWorkoutOpenPress} />
                        ) : (
                            <WorkoutMobile workout={workout} key={workout.id} onWorkoutDayOpen={onWorkoutOpenPress} />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default Workouts;
