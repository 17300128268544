import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import THGCard from '../../components/THGCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { useMedia } from '../../util/useMedia';
import ExerciseDescriptionDialog from '../../components/dialogs/ExerciseDescriptionDialog';

const styles = createUseStyles({
    contentContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    b4text: {
        fontWeight: 'bold!important',
        fontSize: '12px!important',
        width: '100%!important',
    },
    arrowIcon: {},
    actionButton: {
        marginTop: '5px!important',
    },
});

interface ExerciseGroupProps {
    data: any;
    title: string;
    itemsModifyable?: boolean;
}
const ExerciseGroup = (props: ExerciseGroupProps): JSX.Element => {
    const classes = styles();
    const { data, title, itemsModifyable = false } = props;
    const large = useMedia('(min-width: 900px)');

    const [filteredExercises, setFilteredExercises] = useState(data);
    const [exercisesVisible, setExercisesVisible] = useState(large);
    const [showExerciseDescription, setShowExerciseDescription] = useState(false);
    const [exerciseToBeDescribed, setExerciseToBeDescribed] = useState();

    useEffect(() => {
        setFilteredExercises(data);
    }, [setFilteredExercises, data]);

    const onShowExerciseDescription = useCallback(
        (exercise) => {
            setExerciseToBeDescribed(exercise);
            setShowExerciseDescription(true);
        },
        [setExerciseToBeDescribed, setShowExerciseDescription],
    );

    const onCloseExerciseDescription = useCallback(() => {
        setShowExerciseDescription(false);
    }, [setShowExerciseDescription]);

    const exerciseDescriptionDialog = useMemo(() => {
        return (
            <ExerciseDescriptionDialog
                open={showExerciseDescription}
                exercise={exerciseToBeDescribed}
                onClosePress={onCloseExerciseDescription}
            />
        );
    }, [setShowExerciseDescription, exerciseToBeDescribed, showExerciseDescription]);

    const renderExercises = () => {
        return (
            <>
                {exercisesVisible && (
                    <>
                        {/* <div style={{ height: 10, borderBottom: '.5px solid #D3D3D3' }} /> */}
                        <div style={{ maxHeight: 350, overflowY: 'auto', marginTop: 10 }}>
                            {filteredExercises.map((item: any) => {
                                return (
                                    <div
                                        onClick={() => onShowExerciseDescription(item)}
                                        style={{ height: '30px!important', display: 'flex', alignItems: 'center' }}
                                    >
                                        <Button classes={{ root: classes.b4text }}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <Typography
                                                    style={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}
                                                >
                                                    {item.description?.name}
                                                </Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ChevronRightIcon
                                                        style={{ fontSize: '18px', paddingLeft: 10, color: 'black' }}
                                                    />
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </>
        );
    };

    const onShowHideExercisesPress = useCallback(() => {
        setExercisesVisible((visible) => !visible);
    }, [setExercisesVisible]);

    const exerciseGroupAction = () => (
        <IconButton aria-label="settings" onClick={onShowHideExercisesPress} classes={{ root: classes.actionButton }}>
            {!exercisesVisible && (
                <ChevronRightIcon
                    color="inherit"
                    classes={{ root: classes.arrowIcon }}
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
            )}
            {exercisesVisible && (
                <ExpandMoreIcon color="inherit" classes={{ root: classes.arrowIcon }} style={{ color: '#fafafa' }} />
            )}
        </IconButton>
    );

    return (
        <div className={classes.contentContainer}>
            <THGCard
                content={renderExercises()}
                loading={false}
                title={`${title} (${filteredExercises.length})`}
                subheader=""
                action={exerciseGroupAction()}
                headerTransparent={!exercisesVisible}
            />
            {showExerciseDescription && exerciseDescriptionDialog}
        </div>
    );
};

export default ExerciseGroup;
