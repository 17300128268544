import { createAction } from 'redux-actions';

export default class WorkoutActions {
    static getAllWorkouts = createAction('ALL_WORKOUTS_FETCH_REQUESTED', () => ({}));
    static getAllWorkoutsSuccess = createAction('ALL_WORKOUTS_FETCH_SUCCESS', (workouts) => ({
        workouts,
    }));
    static getAllWorkoutsError = createAction('ALL_WORKOUTS_FETCH_ERROR', (message) => ({ message }));

    static updateWorkout = createAction('UPDATE_WORKOUT_PUT_REQUESTED', (workout) => ({ workout }));
    static deleteWorkout = createAction('DELETE_WORKOUT_REQUESTED', (workoutId) => ({ workoutId }));

    static updateWorkoutSuccess = createAction('UPDATE_WORKOUT_PUT_SUCCESS', (workout) => ({
        workout,
    }));

    static deleteWorkoutSuccess = createAction('DELETE_WORKOUT_SUCCESS', (workoutId) => ({
        workoutId,
    }));
    static updateWorkoutError = createAction('UPDATE_WORKOUT_PUT_ERROR', (message) => ({ message }));

    static updateWorkoutDay = createAction('UPDATE_WORKOUT_DAY_PUT_REQUESTED', (workoutId, workoutDay) => ({
        workoutId,
        workoutDay,
    }));

    static createWorkout = createAction('CREATE_WORKOUT_PUT_REQUESTED', (workout) => ({ workout }));

    static createWorkoutSuccess = createAction('CREATE_WORKOUT_PUT_SUCCESS', (workout) => ({
        workout,
    }));
    static createWorkoutError = createAction('CREATE_WORKOUT_PUT_ERROR', (message) => ({ message }));
}
