import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import Exercise from '../../modules/catalog/Exercise';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

const styles = createUseStyles({
    dialogContentRoot: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden!important',
        paddingLeft: '5px!important',
        paddingRight: '5px!important',
        backgroundColor: 'transparent!important',
    },
    dialogPaper: {
        margin: '0px!important',
        backgroundColor: 'transparent!important',
    },
    dialogContent: {
        padding: 5,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface ExerciseDescriptionDialogProps {
    exercise?: any;
    onClosePress: () => void;
    open: boolean;
}

const ExerciseDescriptionDialog = (props: ExerciseDescriptionDialogProps): JSX.Element => {
    const { exercise, onClosePress, open } = props;
    const classes = styles();
    const fullScreen = useMediaQuery('(max-width: 800px)');

    const onCloseDialogPressPress = useCallback(() => {
        onClosePress();
    }, [onClosePress]);

    return (
        <Dialog fullScreen={fullScreen} classes={{ paper: classes.dialogPaper }} open={open} onClose={() => {}}>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div style={{ position: 'relative' }}>
                    <Exercise key={exercise.id} item={exercise} modifyable={false} infoExpanded={true} />
                    <div style={{ display: 'flex', position: 'absolute', top: 50, right: 15 }}>
                        <IconButton edge="start" onClick={onCloseDialogPressPress}>
                            <CancelIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
};

export default ExerciseDescriptionDialog;

// rxD321l2svE
