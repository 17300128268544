import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';

const styles = createUseStyles({
    dialogContent: {
        padding: 5,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});

interface DeleteDialogProps {
    item?: any;
    onSavePress: () => void;
    open: boolean;
    onCancelPress: () => void;
    title: string;
}

const DeleteDialog = (props: DeleteDialogProps): JSX.Element => {
    const { item, onSavePress, onCancelPress, open, title } = props;
    const classes = styles();

    const onCancelExercisePress = useCallback(() => {
        onCancelPress();
    }, [onCancelPress]);

    const onSaveDeletePress = useCallback(() => {
        onSavePress();
    }, []);

    return (
        <Dialog fullWidth={true} open={open} onClose={() => {}}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <div className={classes.dialogContent}>
                    <Typography>{`Are you sure you want to delete : ${item.description.name}`}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelExercisePress} variant="outlined">
                    Cancel
                </Button>
                <Button color="secondary" variant="contained" onClick={onSaveDeletePress}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;

// rxD321l2svE
