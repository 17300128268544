import { call, put, select, takeLatest } from 'redux-saga/effects';
import { StateSelectors } from '../reducers/StateSelectors';
import ActionResponseActions from '../redux-actions/ActionResponseActions';
import UsageActions from '../redux-actions/UsageActions';
import UserActions from '../redux-actions/UserActions';
import { navigate } from '../routing/Router';
import Endpoints from '../util/endpoints';
import { httpGet, httpPost, httpPut } from '../util/Http';
import { httpGetSpring, httpPostSpring } from '../util/HttpSpring';

export function* attemptLogIn({ payload: { credentials } }) {
    try {
        const user = yield call(httpPost, Endpoints.login, credentials);
        const { data } = yield call(httpPostSpring, Endpoints.loginSpring, {
            userId: credentials.userName,
            ...credentials,
        });

        if (data.token) {
            localStorage.setItem('THG_JWT_TOKEN', data.token);
        }

        if (user.data.accessToken) {
            yield put(UserActions.logInSuccess(user.data));

            localStorage.setItem('THG_AUTH_HEADER', JSON.stringify(user.data.accessToken));
            yield put(UsageActions.createUsageEntry('login'));
        }
        if (credentials.rememberUser) {
            localStorage.setItem('THG_USER_REMEMBER', true);
        }
    } catch (e) {
        yield put(UserActions.logInError(e));
    }
}

export function* getAllUsers() {
    const {
        data: { credentials, userRights, profile },
    } = yield select(StateSelectors.getUser);
    let endpoint = `${Endpoints.allUsers}`;

    if (credentials.role === 'COACH') {
        endpoint = `${Endpoints.allUsers}/${profile.department}`;
    }
    try {
        const users = yield call(httpGet, endpoint);
        yield put(UserActions.getAllUserSuccess(users.data));
    } catch (e) {
        yield put(UserActions.getAllUserError(e));
    }
}

export function* getUserById({ payload: { userId } }) {
    try {
        const user = yield call(httpGet, `${Endpoints.userById}/${userId}`);
        yield put(UserActions.logInSuccess(user.data));
        yield put(UsageActions.createUsageEntry('login'));
    } catch (e) {
        yield put(UserActions.logInError(e));
    }
}

export function* resetPassword({ payload: { credentials } }) {
    try {
        yield call(httpGet, `${Endpoints.resetPassword}/${credentials.userName}`);
    } catch (e) {}
}
export function* changePassword({ payload: { passwords } }) {
    const credentials = yield select(StateSelectors.getUserCredentials);

    const dto = { userId: credentials.userId, newPassword: passwords.newPassword, oldPassword: passwords.oldPassword };
    try {
        const user = yield call(httpPut, `${Endpoints.changePassword}`, dto);
        yield put(UserActions.updateUserSuccess(user.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Change Password',
                severity: 'success',
                message: 'Successfully changed your Password',
            }),
        );
    } catch (e) {
        // yield put(UserActions.updateUserError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Change Password',
                severity: 'error',
                message: 'Could not change your Password',
            }),
        );
    }
}

export function* updateUser({ payload: { userToBe } }) {
    try {
        const user = yield call(httpPut, `${Endpoints.updateUser}`, userToBe);
        yield put(UserActions.updateUserSuccess(user.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Profile',
                severity: 'success',
                message: 'Successfully updated your Profile',
            }),
        );
    } catch (e) {
        yield put(UserActions.updateUserError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Profile',
                severity: 'error',
                message: 'Could not updated your Profile',
            }),
        );
    }
}
export function* updateUserORM({ payload: { orm } }) {
    try {
        const user = yield call(httpPut, `${Endpoints.updateORMForExercise}`, orm);
        yield put(UserActions.updateUserSuccess(user.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update ORM',
                severity: 'success',
                message: 'Successfully updated your ORM',
            }),
        );
    } catch (e) {
        yield put(UserActions.updateUserError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update ORM',
                severity: 'error',
                message: 'Could not updated your ORM',
            }),
        );
    }
}

export function* deleteUserProfile() {
    const user = yield select(StateSelectors.getUser);
    const { data } = user;

    try {
        const user = yield call(httpPut, `${Endpoints.deleteUser}/${data.id}`);
        yield put(UserActions.updateUserSuccess(user.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Profile',
                severity: 'success',
                message: 'Successfully reserved your Profile for deletion',
            }),
        );
    } catch (e) {
        yield put(UserActions.updateUserError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Profile',
                severity: 'error',
                message: 'Could not reserve your Profile for deletion',
            }),
        );
    }
}

export function* reactivateUserProfile() {
    const user = yield select(StateSelectors.getUser);
    const { data } = user;

    try {
        const user = yield call(httpPut, `${Endpoints.reactivateUser}/${data.id}`);
        yield put(UserActions.updateUserSuccess(user.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Reactivate Profile',
                severity: 'success',
                message: 'Successfully reactivated your Profile ',
            }),
        );
    } catch (e) {
        yield put(UserActions.updateUserError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Reactivate Profile',
                severity: 'error',
                message: 'Could not reactivate your Profile',
            }),
        );
    }
}

export function* attemptRegister({ payload: { credentials } }) {
    const { email, userId, password } = credentials;

    try {
        const newUser = yield call(httpPost, Endpoints.register, credentials);

        if (newUser.status === 200) {
            yield call(attemptLogIn, {
                payload: { credentials: { userName: userId, password: password } },
            });
            navigate('/');
        }
    } catch (e) {
        yield put(UserActions.registerError());
    }
}

export function* getPrincipal() {
    try {
        const principal = yield call(httpGetSpring, Endpoints.userPrincipal);
    } catch (e) {
        console.log(e);
    }
}

export default function* definedExercisesSagas() {
    yield takeLatest(UserActions.logIn.toString(), attemptLogIn);
    yield takeLatest(UserActions.register.toString(), attemptRegister);
    yield takeLatest(UserActions.getUser.toString(), getUserById);
    yield takeLatest(UserActions.getAllUser.toString(), getAllUsers);
    yield takeLatest(UserActions.updateUser.toString(), updateUser);
    yield takeLatest(UserActions.updateUserORM.toString(), updateUserORM);
    yield takeLatest(UserActions.deleteUserProfile.toString(), deleteUserProfile);
    yield takeLatest(UserActions.reactivateUserProfile.toString(), reactivateUserProfile);
    yield takeLatest(UserActions.resetPassword.toString(), resetPassword);
    yield takeLatest(UserActions.changePassword.toString(), changePassword);
    yield takeLatest(UserActions.getPrincipal.toString(), getPrincipal);
}
