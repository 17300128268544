import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { StateSelectors } from '../reducers/StateSelectors';

const styles = createUseStyles({
    snackBarRoot: {
        width: '90%',
    },
    alertRoot: {
        minWidth: 300,
    },
});

const ActionResponse = (): JSX.Element => {
    const classes = styles();

    const [open, setOpen] = useState(false);

    const actionResponse = useSelector(StateSelectors.getActionresponses);

    useEffect(() => {
        const { response } = actionResponse;
        if (response?.title && response?.message && response?.severity) {
            setOpen(true);
        }
    }, [actionResponse, setOpen]);

    const onAlertClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const snackBar = () => {
        const { response } = actionResponse;

        return (
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={onAlertClose}
                style={{ width: '100%', display: 'flex', justifyContent: 'center', left: 0, bottom: 20 }}
            >
                <Alert severity={response?.severity} classes={{ root: classes.alertRoot }}>
                    <AlertTitle>{response?.title?.toUpperCase()}</AlertTitle>
                    {response?.message}
                </Alert>
            </Snackbar>
        );
    };
    return snackBar();
};

export default ActionResponse;
