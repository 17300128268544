import { createAction } from 'redux-actions';

export default class ExercisesActions {
    static getDefinedExercises = createAction('DEFINED_EXERCISES_FETCH_REQUESTED', () => ({}));
    static getDefinedExercisesSuccess = createAction('DEFINED_EXERCISES_FETCH_SUCCESS', (exercises) => ({
        exercises,
    }));
    static getDefinedExercisesError = createAction('DEFINED_EXERCISES_FETCH_ERROR', (message) => ({ message }));

    static saveExerciseDefinition = createAction('SAVE_EXERCISE_REQUESTED', (exercise) => ({
        exercise,
    }));

    static saveExerciseDefinitionSuccess = createAction('SAVE_EXERCISE_SUCCESS', (exercise) => ({
        exercise,
    }));

    static saveExerciseDefinitionError = createAction('SAVE_EXERCISE_ERROR', (message) => ({
        message,
    }));

    static updateExerciseDefinition = createAction('UPDATE_EXERCISE_REQUESTED', (exercise) => ({
        exercise,
    }));

    static updateExerciseDefinitionSuccess = createAction('UPDATE_EXERCISE_SUCCESS', (exercise) => ({
        exercise,
    }));

    static updateExerciseDefinitionError = createAction('UPDATE_EXERCISE_ERROR', (message) => ({
        message,
    }));

    static deleteExercise = createAction('DELETE_EXERCISE_REQUESTED', (id) => ({
        id,
    }));

    static deleteExerciseDefinitionSuccess = createAction('DELETE_EXERCISE_SUCCESS', (id) => ({
        id,
    }));

    static deleteExerciseDefinitionError = createAction('DELETE_EXERCISE_ERROR', (message) => ({
        message,
    }));
}
