import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Chart } from 'chart.js';
import moment from 'moment';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import THGCard from '../../components/THGCard';
import { StateSelectors } from '../../reducers/StateSelectors';
import { navigate } from '../../routing/Router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// @ts-ignore
Chart.register(ChartDataLabels);

const styles = createUseStyles({
    introContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: 5,
    },
    b4Container: {
        display: 'flex',
        height: '2rem',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    b4text: {
        fontWeight: 'bold!important',
        fontSize: '12px!important',
        width: '100%!important',
    },
    b4TotalContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 5,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 20,
        borderTop: '1px solid #DDD',
    },
    b4TotalText: {
        fontWeight: 'bold!important',
        fontSize: '16px!important',
        width: '100%!important',
    },
    noStatsText: {
        fontWeight: 'bold!important',
        fontSize: '16px!important',
        width: '100%!important',
        textAlign: 'center',
    },
    actionButton: {
        color: 'white!important',
        paddingTop: '2px!important',
        paddingBottom: '0px!important',
    },
    actionDescription: {
        fontSize: '8px!important',
    },
});
const IntroPage = (): JSX.Element => {
    const classes = styles();

    const big4 = useSelector(StateSelectors.big4Exercises);
    const userORM = useSelector(StateSelectors.userORM);
    const [selectedExercise, setSelectedExercise] = useState('a33bcd66-6d2f-45a7-9381-5321332c88f5');

    const big4Total = () => {
        const { data } = userORM;
        let total = 0;
        big4.data.forEach((b4) => {
            const oRmForExercise = data?.filter((orm) => orm.exercise === b4.id)[0]?.orm;
            if (oRmForExercise) {
                total += oRmForExercise;
            }
        });

        let clubColor = '#9c5221';
        if (total >= 550) {
            clubColor = '#b5b5bd';
        }
        if (total >= 650) {
            clubColor = '#ffd700';
        }
        return (
            <div className={classes.b4TotalContainer}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <Typography className={classes.b4TotalText}>TOTAL</Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: clubColor,
                            borderRadius: '50%',
                            height: 50,
                            width: 50,
                            border: '2px solid black',
                        }}
                    >
                        <Typography
                            style={{ color: 'white', fontSize: 22, fontWeight: 'bold' }}
                        >{`${total}`}</Typography>
                    </div>
                </div>
            </div>
        );
    };

    const renderBig4 = () => {
        const { data } = userORM;

        return (
            <div>
                {big4.data.map((b4) => {
                    return (
                        <div className={classes.b4Container} onClick={() => navigate('/thg/1-rm', { exercise: b4.id })}>
                            <Button classes={{ root: classes.b4text }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div>{b4.description?.name}</div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {`${data?.filter((orm) => orm.exercise === b4.id)[0]?.orm ?? '-'}KG`}
                                        <ArrowForwardIosIcon style={{ fontSize: '12px', paddingLeft: 10 }} />
                                    </div>
                                </div>
                            </Button>
                        </div>
                    );
                })}
                <div>{big4Total()}</div>
            </div>
        );
    };

    const big4Action = () => (
        <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', marginTop: 5, marginRight: 5 }}>
            <IconButton
                aria-label="settings"
                onClick={() => navigate('/thg/1-rm')}
                classes={{ root: classes.actionButton }}
            >
                <SwapVerticalCircleIcon color="inherit" />
            </IconButton>
            <Typography className={classes.actionDescription}>1-RM Calculator</Typography>
        </div>
    );

    const renderBig4TrendChart = (id) => {
        const { data: ormData = [] } = userORM;

        const big4History = ormData.filter((orm) => {
            return id == orm.exercise;
        });

        const labels = big4History?.[0]?.ormHistory?.map((oH) =>
            oH.savedDate ? moment(oH.savedDate).format('MMM,YYYY') : '',
        );
        labels?.push(moment().format('MMM,YYYY'));

        const chartData = big4History?.[0]?.ormHistory?.map((oH) => oH.orm ?? 0);
        chartData?.push(big4History?.[0]?.orm);

        const data = {
            labels,
            datasets: [
                {
                    label: '1RM(kg)',
                    data: chartData,
                    fill: false,
                    backgroundColor: 'rgba(0,86,44)',
                    borderColor: 'rgba(0,86,44,0.2)',
                },
            ],
        };

        const options = {
            maintainAspectRatio: true,
            responsive: true,
            layout: {
                padding: 20,
            },

            plugins: {
                datalabels: {
                    align: 'end',
                    display: 'auto',
                    formatter: function (value, context) {
                        return `${value}kg`;
                    },
                    labels: {
                        title: {
                            font: {
                                weight: 'bold',
                                size: 10,
                            },
                            color: 'rgba(0,86,44)',
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        font: {
                            size: 10,
                            weight: 'bold',
                        },
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        // Include a dollar sign in the ticks
                        callback: function (value, index, values) {
                            return value + 'kg';
                        },
                    },
                },
            },
        };

        if (!chartData || labels.length == 0) {
            return (
                <div
                    style={{
                        height: 168,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Typography className={classes.noStatsText}>Time to get Your first 1RM documented!</Typography>
                </div>
            );
        }
        return (
            <div style={{ height: 140, marginTop: 28, marginBottom: 10 }}>
                <Line data={data} options={options} type={'line'} />
            </div>
        );
    };

    return (
        <div className={classes.introContainer}>
            <THGCard
                content={renderBig4()}
                loading={big4.loading || userORM.loading}
                title="BIG 4"
                subheader="1-RM"
                avatar={<WhatshotIcon fontSize="large" />}
                action={big4Action()}
            />
            <THGCard
                content={renderBig4TrendChart('a33bcd66-6d2f-45a7-9381-5321332c88f5')}
                loading={big4.loading || userORM.loading}
                title="Flat Bench Press"
                subheader="Development"
                avatar={<TimelineIcon fontSize="large" />}
            />
            <THGCard
                content={renderBig4TrendChart('ab630e64-fa92-4961-b2ab-e170555c0c42')}
                loading={big4.loading || userORM.loading}
                title="Deadlift/Kreuzheben"
                subheader="Development"
                avatar={<TimelineIcon fontSize="large" />}
            />
            <THGCard
                content={renderBig4TrendChart('28765a6e-608d-4bc5-8f6f-49f207811349')}
                loading={big4.loading || userORM.loading}
                title="Standing Press"
                subheader="Development"
                avatar={<TimelineIcon fontSize="large" />}
            />
            <THGCard
                content={renderBig4TrendChart('794e92bd-a16e-4037-aa6e-97bd6685e17b')}
                loading={big4.loading || userORM.loading}
                title="Barbell Squat"
                subheader="Development"
                avatar={<TimelineIcon fontSize="large" />}
            />
        </div>
    );
};

export default IntroPage;
