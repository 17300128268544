import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UsageActions from '../../redux-actions/UsageActions';
import WorkoutActions from '../../redux-actions/WorkoutActions';
import { useMedia } from '../../util/useMedia';
import Workout from '../workouts/Workout';
import WorkoutMobile from '../workouts/WorkoutMobile';

const styles = createUseStyles({
    container: {
        paddingTop: 10,

        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    createInput: {},
});
const WorkoutManagement = (): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const [loadAllWorkouts] = useActions([() => WorkoutActions.getAllWorkouts()]);
    const [createUsageEntryForWorkout] = useActions([(resource) => UsageActions.createUsageEntry(resource)]);

    const exercises = useSelector(StateSelectors.getDefinedExercises);
    const workouts = useSelector(StateSelectors.getWorkouts);

    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [newWorkoutLabel, setNewWorkoutLabel] = useState('');

    const [createWorkout] = useActions([(workout) => WorkoutActions.createWorkout(workout)]);

    useEffect(() => {
        loadAllWorkouts();
    }, [loadAllWorkouts]);

    const onCreateWorkoutPress = useCallback(
        (event) => {
            setAnchorEl(event.currentTarget);
            setShowCreateDialog(true);
        },
        [setShowCreateDialog],
    );

    const onChangeNewWorkoutLabelPress = useCallback(
        (event) => {
            // event.persist();
            setNewWorkoutLabel(event.target.value);
        },
        [setNewWorkoutLabel],
    );

    const onCreateConfirmPress = useCallback(() => {
        createWorkout({ name: newWorkoutLabel, days: [] });
        setNewWorkoutLabel('');
    }, [createWorkout, setNewWorkoutLabel, newWorkoutLabel]);

    const handleClose = () => {
        setAnchorEl(null);
        setShowCreateDialog(false);
    };

    const onWorkoutOpenPress = useCallback(
        (resource) => {
            createUsageEntryForWorkout(`workout_${resource}`);
        },
        [createUsageEntryForWorkout],
    );

    return (
        <div className={classes.container}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                {large && (
                    <div style={{ display: 'flex', marginRight: 10 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onCreateWorkoutPress}
                            startIcon={<AddIcon />}
                            size="small"
                            style={{
                                padding: 5,
                                borderRadius: 5,
                                justifyContent: 'flex-start',
                                backgroundColor: 'rgba(0,86,44)',
                                marginBottom: 5,
                                color: '#fafafa',
                            }}
                        >
                            Create Workout
                        </Button>
                        <Popover
                            id={'create_popover'}
                            open={showCreateDialog}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div
                                style={{ display: 'flex', flexDirection: 'column', padding: 10, alignItems: 'center' }}
                            >
                                <InputLabel color="primary">Workout-Name</InputLabel>
                                <Input
                                    id="weight-multiline-static"
                                    onChange={onChangeNewWorkoutLabelPress}
                                    value={newWorkoutLabel}
                                    classes={{
                                        input: classes.createInput,
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 10 }}
                                    onClick={onCreateConfirmPress}
                                    size="small"
                                >
                                    Create
                                </Button>
                            </div>
                        </Popover>
                    </div>
                )}
            </div>
            {(exercises.loading || workouts.data.loading) && <LoadingIndicator size={120} text={'Loading...'} />}
            {!exercises.loading && !workouts.data.loading && (
                <div>
                    {workouts.data.map((workout: any) => {
                        return large ? (
                            <Workout workout={workout} key={workout.id} onWorkoutDayOpen={onWorkoutOpenPress} />
                        ) : (
                            <WorkoutMobile workout={workout} key={workout.id} onWorkoutDayOpen={onWorkoutOpenPress} />
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default WorkoutManagement;
