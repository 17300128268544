import { call, put, takeLatest } from 'redux-saga/effects';
import ActionResponseActions from '../redux-actions/ActionResponseActions';
import InvitationActions from '../redux-actions/InvitationActions';
import Endpoints from '../util/endpoints';
import { httpGet, httpPost } from '../util/Http';

export function* getPendingInvitations() {
    try {
        const invitations = yield call(httpGet, `${Endpoints.pendingInvitations}`);
        yield put(InvitationActions.getPendingSuccess(invitations.data));
    } catch (e) {
        yield put(InvitationActions.getPendingError(e));
    }
}

export function* generateInviation({ payload: { invitationConfig } }) {
    try {
        const invitation = yield call(httpPost, `${Endpoints.createInvitation}`, invitationConfig);
        yield put(InvitationActions.generateInvitationCodeSuccess(invitation.data));

        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Create Invitation',
                severity: 'success',
                message: 'Invitationcode created',
            }),
        );
    } catch (e) {
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Create Invitation',
                severity: 'error',
                message: 'Could not create Invitationcode',
            }),
        );
    }
}

export default function* invitationsSagas() {
    yield takeLatest(InvitationActions.getPending.toString(), getPendingInvitations);
    yield takeLatest(InvitationActions.generateInvitationCode.toString(), generateInviation);
}
