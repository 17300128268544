import moment from 'moment';
import { handleActions } from 'redux-actions';
import ActionResponseActions from '../redux-actions/ActionResponseActions';

const initialActionResponseState = {
    response: {
        title: null,
        severity: null,
        message: null,
        createdAt: null,
    },
};

const actionResponse = handleActions(
    {
        [ActionResponseActions.setActionResponse]: (state, { payload: { response } }) => {
            return {
                response,
                createdAt: moment(),
            };
        },
        [ActionResponseActions.clearActionresponse]: (state) => {
            return {
                response: {
                    title: null,
                    severity: null,
                    message: null,
                },
            };
        },
    },
    initialActionResponseState,
);

export const getActionresponse = (state) => state;
export default actionResponse;
