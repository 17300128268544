import { handleActions } from 'redux-actions';
import UserActions from '../redux-actions/UserActions';

const initialUserManagementState = {
    loading: null,
    error: null,
    data: []

};

const userManagement = handleActions(
    {
        [UserActions.getAllUser]: (state) => {
            return {
                ...state,
                data: [],
                loading: true,
                error: false,
            };
        },
        [UserActions.getAllUserSuccess]: (state, { payload: { users } }) => {
            return {
                ...state,
                data: users,
                loading: false,
                error: false,
            };
        },
        [UserActions.getAllUserError]: (state, { payload: { message } }) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: message,
            };
        },
    },
    initialUserManagementState,
);

export const getUsers = (state) => state;

export default userManagement;
