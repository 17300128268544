import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface BaseDialogProps {
    open: boolean;
    title: string;
    onClose?: () => void;
    renderContent: any;
    actions: any;
    fullWidth?: boolean;
    maxWidth?: any;
}

const BaseDialog = (props: BaseDialogProps): JSX.Element => {
    const { open, title, actions, renderContent, fullWidth = false, maxWidth = undefined, onClose = undefined } = props;
    return (
        <Dialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth}>
            <DialogTitle style={{ fontSize: 16 }}>{title}</DialogTitle>
            <DialogContent>{renderContent}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Dialog>
    );
};

export default BaseDialog;

// rxD321l2svE
