import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/main.js';
import mainReducer from './main.js';


// saga
const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [sagaMiddleware];

const store = createStore(mainReducer, composeWithDevTools(applyMiddleware(...middleware)));

// run saga middleware
sagaMiddleware.run(rootSaga);

export default store;
