import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import DownloadLink from '../../components/DownloadLink';
import { StateSelectors } from '../../reducers/StateSelectors';
import { navigate } from '../../routing/Router';
import MenuButton from './MenuButton';
import * as config from './menuConfig';
import hunterLogo from '../../assets/hunters-head.png';

const styles = createUseStyles({
    sideMenuContainer: {
        backgroundColor: '#293133',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: '100%',
        height: 'calc(100% - 20px)',
        paddingLeft: '10px',
        paddingTop: '20px',
        paddingRight: '10px',
    },
    shellBar: {
        display: 'flex',
        paddingLeft: 10,
        alignItems: 'center',
    },
    headerText: {
        // color: 'rgba(0,86,44)',
        color: 'white',
        fontWeight: 'bold!important',
        // textShadow: '1px 1px 2px black',
        fontSize: '20px!important',
    },
    menuItemsTop: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    menuItemsBottom: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        marginBottom: 10,
    },
    menuGroupHeader: {
        height: 40,
        borderBottom: '1px solid #fafafa',
        display: 'flex',
        alignItems: 'flex-end',
        paddingLeft: 10,
        marginBottom: 15,
    },
    linkLabel: {
        fontSize: '10px!important',
    },
});

interface SideMenuProps {
    onNavigate: (item) => void;
    userRole: string;
    large?: boolean;
}
const SideMenu = (props: SideMenuProps): JSX.Element => {
    const classes = styles();
    const { onNavigate, userRole, large } = props;

    const user = useSelector(StateSelectors.getUser);

    const onMenuItemClick = useCallback(
        (item) => {
            navigate(item.path);
            onNavigate(item);
        },
        [props],
    );

    return (
        <div className={classes.sideMenuContainer}>
            <div className={classes.menuItemsTop}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={hunterLogo} width="50%" />
                </div>
                <div className={classes.shellBar}>
                    <Typography className={classes.headerText}>Menu</Typography>
                </div>
                <div style={{ height: 5, marginBottom: 10, width: '100%', borderBottom: '1px solid #fafafa' }} />
                {config.menu
                    .filter(
                        (m) =>
                            (m.roles?.[0] === 'ALL' ||
                                m.roles?.includes(userRole) ||
                                m.rights?.some((right) => user.data?.userRights?.[right])) &&
                            m.placement === 'TOP',
                    )
                    .map((menuItem) => {
                        return (
                            <MenuButton
                                key={menuItem.id}
                                icon={menuItem.icon}
                                isSelected={window.location.hash.replace('#', '') === menuItem.path}
                                onPress={() => onMenuItemClick(menuItem)}
                                buttonText={menuItem.buttonText}
                            />
                        );
                    })}
            </div>
            <div className={classes.menuItemsBottom}>
                {userRole !== 'USER' && (
                    <>
                        <div className={classes.menuGroupHeader}>
                            <Typography className={classes.headerText}>Coaches Corner</Typography>
                        </div>
                        {config.menu
                            .filter(
                                (m) =>
                                    (m.roles?.[0] === 'ALL' ||
                                        m.roles?.includes(userRole) ||
                                        m.rights?.some((right) => user.data?.userRights?.[right])) &&
                                    m.placement === 'BOTTOM',
                            )
                            .map((menuItem) => {
                                return (
                                    <MenuButton
                                        key={menuItem.id}
                                        icon={menuItem.icon}
                                        isSelected={window.location.hash.replace('#', '') === menuItem.path}
                                        onPress={() => onMenuItemClick(menuItem)}
                                        buttonText={menuItem.buttonText}
                                    />
                                );
                            })}
                    </>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: 'rgba(5, 116, 19)',
                        flexWrap: 'wrap',
                        borderTop: '1px solid rgba(0,86,44)',
                    }}
                >
                    <Button
                        color="primary"
                        size="small"
                        classes={{ text: classes.linkLabel }}
                        onClick={() => navigate('/thg/impressum')}
                    >
                        Impressum
                    </Button>
                    <Button onClick={() => {}} color="primary" size="small" classes={{ text: classes.linkLabel }}>
                        <DownloadLink to="/files/THG_Datenschutzerklärung.pdf" target="_blank">
                            Datenschutz
                        </DownloadLink>
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        onClick={() => navigate('/thg/support')}
                        classes={{ text: classes.linkLabel }}
                    >
                        Support
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SideMenu;
