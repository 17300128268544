const thgServicesUrl = 'https://thg-java.azurewebsites.net/thg';

const thgServicesUrlSpring = 'https://thg-java-spring.azurewebsites.net/thg';

export default class Endpoints {
    static login = `${thgServicesUrl}/users/login`;
    static userById = `${thgServicesUrl}/users/user`;
    static allUsers = `${thgServicesUrl}/users/allUsers`;
    static updateUser = `${thgServicesUrl}/users/updateProfile`;
    static updateORMForExercise = `${thgServicesUrl}/users/updateORMForExercise`;
    static verifyToken = `${thgServicesUrl}/users/verifyToken`;
    static deleteUser = `${thgServicesUrl}/users/reserveForDeletion`;
    static reactivateUser = `${thgServicesUrl}/users/reactivateProfile`;
    static resetPassword = `${thgServicesUrl}/users/resetPassword`;
    static changePassword = `${thgServicesUrl}/users/changePassword`;

    static readExerciseDefinitions = `${thgServicesUrl}/exercises/all`;
    static saveExerciseDefinition = `${thgServicesUrl}/exercises/addOrUpdate`;
    static deleteExerciseDefinition = `${thgServicesUrl}/exercises/delete`;

    static pendingInvitations = `${thgServicesUrl}/users/pendingInvitations`;
    static createInvitation = `${thgServicesUrl}/users/generateInvitationCode`;

    static getDistinctAttributes = `${thgServicesUrl}/attributes/distinct`;

    static getAllWorkouts = `${thgServicesUrl}/workouts/all`;
    static getWorkoutsOfUser = `${thgServicesUrl}/workouts/myWorkouts`;
    static updateWorkout = `${thgServicesUrl}/workouts/addOrUpdate`;
    static deleteWorkout = `${thgServicesUrl}/workouts/delete`;

    //    Spring
    static loginSpring = `${thgServicesUrlSpring}/auth/login`;
    static register = `${thgServicesUrlSpring}/auth/register`;
    static userPrincipal = `${thgServicesUrlSpring}/manage/context`;

    static createUsageEntry = `${thgServicesUrlSpring}/usage/insertEntry`;
    static getUsageSummary = `${thgServicesUrlSpring}/usage/summary`;
    static getUsageSummaryForResource = `${thgServicesUrlSpring}/usage/resource-summary`;
}
