import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import QueueIcon from '@mui/icons-material/Queue';
import Autocomplete from '@mui/material/Autocomplete';
import arrayMove from 'array-move';
import React, { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

const styles = createUseStyles({
    dialogContent: {
        padding: 5,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
    },
    dialogContentRoot: {},
    dayNameInput: {
        fontWeight: 'bold!important',
    },
    iconButton: {
        width: '40px',
        height: '40px',
        padding: '0!important',
    },
    exerciseSelectGroupLabel: {
        color: 'rgba(0,86,44)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
});

interface EditWorkoutDayDialogProps {
    workoutDay?: any;
    definedExercises?: any;
    onClosePress: () => void;
    onSavePress: (workoutDay: any) => void;
    open: boolean;
}

const SortableItem = SortableElement(({ children }) => {
    return <li style={{ display: 'inline-block', verticalAlign: 'top', width: '100%', zIndex: 9999 }}>{children}</li>;
});

const SortableList = SortableContainer(({ children }) => {
    return <ul style={{ paddingLeft: 0 }}>{children}</ul>;
});

const DraggableHandle = SortableHandle(() => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <DragIndicatorIcon style={{ fontSize: '24px' }} />
    </div>
));

const EditWorkoutDayDialog = (props: EditWorkoutDayDialogProps): JSX.Element => {
    const { workoutDay, definedExercises, onClosePress, onSavePress, open } = props;
    const classes = styles();

    const [workoutDayState, setWorkoutDayState] = useState(workoutDay);

    const onCloseDialogPressPress = useCallback(() => {
        onClosePress();
    }, [onClosePress]);

    const onSaveWorkoutDayPress = useCallback(() => {
        onSavePress(workoutDayState);
    }, [onSavePress, workoutDayState]);

    const onWorkoutExerciseChange = useCallback(
        (value: any, index: number, field: string) => {
            setWorkoutDayState((priorWorkoutDayState) => {
                return {
                    ...priorWorkoutDayState,
                    exercises: priorWorkoutDayState.exercises.map((ex: any, arraySpot) => {
                        if (index === arraySpot) {
                            return { ...ex, [field]: value?.id };
                        }
                        return ex;
                    }),
                };
            });
        },
        [setWorkoutDayState],
    );

    const onWorkoutInputChange = useCallback(
        (index: number, field: string) => (event) => {
            event.persist();
            setWorkoutDayState((priorWorkoutDayState) => {
                const newExercises = [...priorWorkoutDayState.exercises];
                newExercises[index] = { ...priorWorkoutDayState.exercises[index], [field]: event.target.value };
                return {
                    ...priorWorkoutDayState,
                    exercises: newExercises,
                };
            });
        },
        [setWorkoutDayState],
    );

    const onWorkoutConfigChange = useCallback(
        (index: number, field: string) => (event) => {
            setWorkoutDayState((priorWorkoutDayState) => {
                const newExercises = [...priorWorkoutDayState.exercises];
                newExercises[index] = { ...priorWorkoutDayState.exercises[index], [field]: event.target.value };
                return {
                    ...priorWorkoutDayState,
                    exercises: newExercises,
                };
            });
        },
        [setWorkoutDayState],
    );

    const onAddExerciseRow = useCallback(() => {
        setWorkoutDayState((priorWorkoutDayState) => {
            const exercises = [...priorWorkoutDayState.exercises];
            exercises.push({
                exerciseId: '',
                info: '',
                repetitions: 0,
                sets: 0,
                intensity: '',
            });
            return {
                ...priorWorkoutDayState,
                exercises,
            };
        });
    }, [setWorkoutDayState, definedExercises]);

    const onDeleteExerciseRow = useCallback(
        (index: number) => () => {
            setWorkoutDayState((priorWorkoutDayState) => {
                return {
                    ...priorWorkoutDayState,
                    exercises: priorWorkoutDayState.exercises.filter(
                        (exercise, exerciseIndex) => index !== exerciseIndex,
                    ),
                };
            });
        },
        [setWorkoutDayState, definedExercises],
    );

    const onWorkoutDayNameChange = useCallback(
        (event) => {
            event.persist();
            setWorkoutDayState((priorWorkoutDayState) => {
                return {
                    ...priorWorkoutDayState,
                    day: event.target.value,
                };
            });
        },
        [setWorkoutDayState],
    );

    const onSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            setWorkoutDayState((priorState): any => ({
                ...priorState,
                exercises: arrayMove([...priorState.exercises], oldIndex, newIndex),
            }));
        },
        [setWorkoutDayState],
    );

    const workoutDayItems = useMemo(() => {
        return workoutDayState.exercises?.map((exercise: any, index: number) => {
            const rowValue = definedExercises.data?.filter((exerciseEntry: any) => {
                return exerciseEntry.id === exercise.exerciseId;
            })[0];
            return (
                <SortableItem key={`item-${index}`} index={index}>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                        <Autocomplete
                            id={`exercise-select_${index}`}
                            options={definedExercises.data || []}
                            value={rowValue ?? null}
                            onChange={(e, v) => onWorkoutExerciseChange(v, index, 'exerciseId')}
                            classes={{ groupLabel: classes.exerciseSelectGroupLabel }}
                            groupBy={(option: any) => option.description?.type}
                            getOptionLabel={(option: any) => {
                                return option.description?.name;
                            }}
                            style={{ margin: 5 }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        style={{ width: 500 }}
                                        variant="standard"
                                        placeholder="Select Exercise..."
                                    />
                                );
                            }}
                        />
                        <div style={{ display: 'flex', width: 100, height: 42 }}>
                            <Select
                                id={`sets-select_${index}`}
                                value={exercise.sets ?? ''}
                                onChange={onWorkoutConfigChange(index, 'sets')}
                                style={{ margin: 5, width: 100 }}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number: number) => {
                                    return <MenuItem value={number}>{number}</MenuItem>;
                                })}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', width: 150, height: 42 }}>
                            <Input
                                id={`reps-select_${index}`}
                                key={`reps-select_${index}`}
                                value={exercise.repetitions ?? ''}
                                style={{ margin: 5, width: 50 }}
                                onChange={onWorkoutInputChange(index, 'repetitions')}
                            />

                            <Select
                                id={`measure-select_${index}`}
                                style={{ margin: 5, width: 100 }}
                                value={exercise.unit ?? []}
                                multiple
                                onChange={onWorkoutConfigChange(index, 'unit')}
                            >
                                {/* @ts-ignore */}
                                {['reps', 'meters', 'yards', 'seconds'].map((unit: string) => {
                                    return <MenuItem value={unit}>{unit}</MenuItem>;
                                })}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', minWidth: 300 }}>
                            <FormControl variant="standard">
                                <Input
                                    id={`intensity-select_${index}`}
                                    key={`intensity-select_${index}`}
                                    multiline
                                    value={exercise.intensity}
                                    onChange={onWorkoutInputChange(index, 'intensity')}
                                    fullWidth
                                    style={{ margin: 5 }}
                                />
                            </FormControl>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 42,
                            }}
                        >
                            <IconButton
                                color="secondary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={onDeleteExerciseRow(index)}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            <DraggableHandle />
                        </div>
                    </div>
                </SortableItem>
            );
        });
    }, [workoutDayState.exercises]);

    return (
        <Dialog open={open} onClose={() => {}} fullWidth={true} maxWidth="lg">
            <DialogTitle id="dialog-title-exercise-secription">
                <Input
                    id="dayName-multiline-static"
                    value={workoutDayState.day}
                    onChange={onWorkoutDayNameChange}
                    classes={{
                        input: classes.dayNameInput,
                    }}
                    style={{ width: '100%' }}
                />
                {/* {workoutDayState.day} */}
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentRoot }}>
                <div className={classes.dialogContent}>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: 'rgba(0,86,44)',
                            color: 'white',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            height: 30,
                            borderRadius: 10,
                        }}
                    >
                        <div style={{ display: 'flex', width: 500, paddingLeft: 10 }}>Exercise</div>
                        <div style={{ display: 'flex', width: 100 }}>Sets</div>

                        <div style={{ display: 'flex', width: 150 }}>Amount</div>
                        <div style={{ display: 'flex', minWidth: 300 }}>Intensity</div>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>Actions</div>
                    </div>
                    <SortableList onSortEnd={onSortEnd} axis={'y'} useDragHandle key={'exercises-list'}>
                        {workoutDayItems}
                    </SortableList>
                    {/* Actions here */}
                    <div style={{ display: 'flex', width: '100%', paddingRight: 10, justifyContent: 'flex-start' }}>
                        <IconButton
                            color="primary"
                            aria-label="edit"
                            className={classes.iconButton}
                            onClick={onAddExerciseRow}
                        >
                            <QueueIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseDialogPressPress} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSaveWorkoutDayPress} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditWorkoutDayDialog;

// rxD321l2svE
