import { call, put, takeLatest } from 'redux-saga/effects';
import AttributesActions from '../redux-actions/AttributesActions';
import Endpoints from '../util/endpoints';
import { httpGet } from '../util/Http';

export function* getDistinctAttributes() {
    try {
        const attributes = yield call(httpGet, `${Endpoints.getDistinctAttributes}`);
        yield put(AttributesActions.getDistinctAttributesSuccess(attributes.data));
    } catch (e) {
        yield put(AttributesActions.getDistinctAttributesSuccess(e));
    }
}

export default function* invitationsSagas() {
    yield takeLatest(AttributesActions.getDistinctAttributes.toString(), getDistinctAttributes);
}
