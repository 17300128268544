export default class UserProfileUtils {
    static checkUserProfileComplete(userProfile) {
        if (!userProfile) return false;
        const { firstName, lastName, department, position } = userProfile;

        if (!firstName || !lastName || !department || !position) {
            return false;
        }
        return true;
    }
}
