import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import ExerciseDialog from '../../components/dialogs/ExerciseDialog';
import ExercisesFiltersDialog from '../../components/dialogs/ExercisesFiltersDialog';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import ExercisesActions from '../../redux-actions/ExercisesActions';
import { useMedia } from '../../util/useMedia';
import ExerciseGroupMobile from '../catalog/ExerciseGroupMobile';

const styles = createUseStyles({
    contentContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    innerContent: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'auto',
        padding: 5,
        justifyContent: 'center',
    },
    exerciseSelectGroupLabel: {
        color: 'rgba(0,86,44)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
    assignementChipSmall: {
        margin: 2,
        fontSize: '8px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
});
const CatalogManagement = (): JSX.Element => {
    const classes = styles();

    const definedExercises = useSelector(StateSelectors.getDefinedExercises);
    const attributes = useSelector(StateSelectors.getAttributes);

    const [userFilters, setUserFilters] = useState({ muscleGroups: [], exercises: [] });
    const [showFilterDialog, setShowFilterDialog] = useState(false);

    const large = useMedia('(min-width: 900px)');

    const [saveExercise] = useActions([(exercise: any) => ExercisesActions.saveExerciseDefinition(exercise)]);

    const [showCreateModel, setShowCreateModel] = useState(false);

    const onShowCreateModelPress = useCallback(() => {
        setShowCreateModel(true);
    }, [setShowCreateModel]);

    const onCancelEditPress = useCallback(() => {
        setShowCreateModel(false);
    }, [setShowCreateModel]);

    const onSaveEditPress = useCallback(
        (exercise) => {
            saveExercise(exercise);
            setShowCreateModel(false);
        },
        [saveExercise, setShowCreateModel],
    );

    const onCancelWorkoutFilteringPress = useCallback(() => {
        setShowFilterDialog(false);
    }, [setShowFilterDialog]);

    const onSaveFilterValuesPress = useCallback(
        (filters) => {
            setUserFilters(filters);
            setShowFilterDialog(false);
        },
        [setShowFilterDialog, setUserFilters],
    );

    const onSearchExerciseChange = useCallback(
        (value) => {
            setUserFilters((priorFilters: any) => ({ ...priorFilters, exercises: value }));
        },
        [setUserFilters],
    );

    const createDialog = () => {
        return (
            <ExerciseDialog
                title="Create Exercise"
                mode="create"
                open={showCreateModel}
                onSavePress={onSaveEditPress}
                onCancelPress={onCancelEditPress}
            />
        );
    };

    const filterDialog = () => {
        return (
            <ExercisesFiltersDialog
                onSavePress={onSaveFilterValuesPress}
                onCancelPress={onCancelWorkoutFilteringPress}
                currentFilters={userFilters}
                open={showFilterDialog}
                title="Filter Exercises"
            />
        );
    };

    const exerciseGroups = useMemo(() => {
        return attributes.data.exerciseTypes?.map((type: string) => {
            return (
                <ExerciseGroupMobile
                    itemsModifyable
                    title={type}
                    data={definedExercises.data
                        .filter((d: any) => d.description?.type === type)
                        .filter((ex) => {
                            if (userFilters.muscleGroups.length > 0) {
                                //@ts-ignore
                                return userFilters.muscleGroups?.some((mG: any) =>
                                    ex.description?.muscleGroupsDetailed?.map((mGd) => mGd.name).includes(mG.name),
                                );
                            }
                            return ex;
                        })
                        .filter((e) => {
                            if (userFilters.exercises?.length > 0) {
                                return userFilters.exercises?.some((mG: any) => {
                                    return e.description?.name === mG.description?.name;
                                });
                            }
                            return e;
                        })}
                />
            );
        });
    }, [definedExercises, attributes, userFilters]);

    return (
        <div className={classes.contentContainer}>
            <div
                style={{
                    display: 'flex',
                    width: 'calc(100% - 20px)',
                    justifyContent: 'flex-end',
                    padding: 10,
                    flexWrap: 'wrap',
                }}
            >
                <div style={{ marginRight: large ? 10 : 0, marginBottom: large ? 0 : 10 }}>
                    <Autocomplete
                        id={`exercise-select`}
                        options={definedExercises.data || []}
                        value={userFilters.exercises || []}
                        multiple
                        limitTags={2}
                        onChange={(e, v) => onSearchExerciseChange(v)}
                        style={{ width: 350 }}
                        size="small"
                        classes={{ groupLabel: classes.exerciseSelectGroupLabel }}
                        groupBy={(option: any) => option.description?.type}
                        renderTags={(value, getTagProps) =>
                            value.map((option: any, index) => {
                                //@ts-ignore
                                return (
                                    <Chip
                                        label={option.description?.name}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChipSmall}
                                        color="primary"
                                    />
                                );
                            })
                        }
                        getOptionLabel={(option: any) => {
                            return option.description?.name;
                        }}
                        renderInput={(params) => {
                            return <TextField {...params} variant="outlined" placeholder="Search Exercise..." />;
                        }}
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                    onClick={onShowCreateModelPress}
                    startIcon={<AddIcon />}
                    size="small"
                >
                    Create Exercise
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FilterListIcon />}
                    size="small"
                    onClick={() => setShowFilterDialog(true)}
                >
                    {`Filter ${userFilters.muscleGroups.length > 0 ? '*' : ''}`}
                </Button>
            </div>
            <div className={classes.innerContent}>
                {!definedExercises.loading && exerciseGroups}
                {definedExercises.loading && <LoadingIndicator size={120} text={'Loading...'} />}
            </div>
            {showCreateModel && createDialog()}
            {showFilterDialog && filterDialog()}
        </div>
    );
};

export default CatalogManagement;
