import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EmailIcon from '@mui/icons-material/Email';
import HistoryIcon from '@mui/icons-material/History';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';
import THGCard from '../../components/THGCard';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import InvitationActions from '../../redux-actions/InvitationActions';
import UserActions from '../../redux-actions/UserActions';
import { useMedia } from '../../util/useMedia';

const styles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: 5,
    },
    innerContent: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'auto',
        padding: 5,
        justifyContent: 'center',
    },
    accordionOuter: {
        width: '100%',
        backgroundColor: 'transparent!important',
        borderRadius: '0px !important',
        borderBottom: '1px solid rgba(5, 116, 19, .3)',
        boxShadow: '0px 0px 0px!important',
    },
    accordionHeader: {
        fontSize: '1.2rem !important',
    },
    accordionDetails: {
        flexDirection: 'column',
        padding: '0px 0px 20px !important',
    },
    form: {
        marginBottom: 20,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        flexWrap: 'wrap',
    },
    input: {
        margin: 5,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pendingInvitationContainer: {
        width: 'calc(100% - 10px)',
        maxWidth: 'calc(100% - 10px)',
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '.5px solid #D3D3D3',
        marginBottom: 5,
        padding: 5,
    },
    assignementChip: {
        margin: 2,
        fontSize: '10px!important',
        textAlign: 'center!important',
        height: '20px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    admin: {
        backgroundColor: 'black!important',
    },
    supervisor: {
        backgroundColor: 'black!important',
    },
    coach: {
        backgroundColor: 'rgba(0,86,44)!important',
    },
    user: {
        backgroundColor: 'grey!important',
    },
});
const AccessManagement = (): JSX.Element => {
    const classes = styles();
    const pendingInvitations = useSelector(StateSelectors.getInvitations);
    const userCredentials = useSelector(StateSelectors.getUserCredentials);
    const userProfile = useSelector(StateSelectors.getUserProfile);
    const attributes = useSelector(StateSelectors.getAttributes);
    const usersList = useSelector(StateSelectors.getUsers);
    const large = useMedia('(min-width: 900px)');

    const [loadPendingInvitations, generateInviation, loadUsers] = useActions([
        () => InvitationActions.getPending(),
        (invitationConfig: string) => InvitationActions.generateInvitationCode(invitationConfig),
        () => UserActions.getAllUser(),
    ]);

    const [invitationConfig, setInvitationConfig] = useState({ email: '' });
    const [isCoach, setIsCoach] = useState(false);
    const [isAccessManager, setIsAccessManager] = useState(false);
    const [groupsSelected, setgroupsSelected] = useState([userProfile.department]);
    const [invitationGroup, setInvitationGroup] = useState(userProfile.department);
    const [users, setUsers] = useState(usersList);

    useEffect(() => {
        if (userCredentials.role !== 'ADMIN') {
            setUsers({
                ...usersList,
                data: usersList.data?.filter((u) => !['ADMIN', 'SUPERVISOR'].includes(u?.credentials?.role)),
            });
        } else {
            setUsers(usersList);
        }
    }, [usersList, userCredentials]);

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            setInvitationConfig((priorInvitationConfig) => ({
                ...priorInvitationConfig,
                [field]: event.target?.value,
            }));
        },
        [setInvitationConfig],
    );

    const onCheckBoxStateChnage = useCallback(() => {
        setIsCoach((isCurrentlyCoach) => !isCurrentlyCoach);
    }, [setIsCoach]);
    const onCheckBoxStateAMChange = useCallback(() => {
        setIsAccessManager((isCurrentlyAM) => !isCurrentlyAM);
    }, [setIsAccessManager]);

    useEffect(() => {
        loadPendingInvitations();
        loadUsers();
    }, [loadPendingInvitations]);

    const resetConfig = useCallback(() => {
        setInvitationConfig({ email: '' });
        setInvitationGroup(userProfile.department);
        setgroupsSelected([userProfile.department]);
        setIsAccessManager(false);
        setIsCoach(false);
    }, [setInvitationGroup, setgroupsSelected, setInvitationConfig, setIsAccessManager, setIsCoach]);

    const onGenerateInvitationPress = useCallback(() => {
        generateInviation({
            ...invitationConfig,
            role: isCoach ? 'COACH' : 'USER',
            department: invitationGroup,
            additionRights: isAccessManager ? { 'manage-access': true } : {},
        });
        resetConfig();
    }, [generateInviation, invitationConfig, isCoach, isAccessManager, resetConfig, invitationGroup]);
    //@ts-ignore
    const { email } = invitationConfig;

    const userList = (group) => {
        const { data } = users;

        const dataForGroup = data
            .filter((user) => user?.profile?.department === group)
            .sort((a, b) =>
                a?.credentials?.role < b?.credentials?.role ? -1 : Number(a?.credentials?.role > b?.credentials?.role),
            );

        return (
            <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                {dataForGroup.map((user: any) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: 'calc(100% - 10px)',
                                height: 50,
                                borderBottom: '.5px solid #D3D3D3',
                                padding: 5,
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {user.profile?.lastName && user.profile?.firstName && (
                                    <Typography
                                        style={{ fontWeight: 'bold', fontSize: '14px' }}
                                    >{`${user.profile?.lastName},${user.profile?.firstName}`}</Typography>
                                )}
                                {(!user.profile?.lastName || !user.profile?.firstName) && (
                                    <Typography
                                        style={{
                                            fontStyle: 'italic',
                                            backgroundColor: '#e5e619',
                                            borderRadius: 5,
                                            padding: 5,
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                        }}
                                    >{`${user.credentials?.email}`}</Typography>
                                )}
                                {user.registrationDate && (
                                    <Typography style={{ fontSize: '12px' }}>{`Joined The Game: ${moment(
                                        user.registrationDate,
                                    ).format('MMM , YYYY')}`}</Typography>
                                )}
                            </div>
                            <div>
                                <Chip
                                    label={`${user.credentials?.role}`}
                                    className={classes.assignementChip}
                                    color="primary"
                                    classes={{ root: classes[`${user.credentials?.role.toLowerCase()}`] }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const invitationContent = () => {
        return (
            <div className={classes.form}>
                <FormControl>
                    <div className={classes.formContainer}>
                        <div className={classes.input}>
                            <TextField
                                id="outlined-helperText"
                                label="Email or User-ID"
                                variant="outlined"
                                required
                                value={invitationConfig?.email}
                                type="email"
                                onChange={(e) => onInputFieldChange('email', e)}
                                style={{ width: '300px' }}
                            />
                        </div>
                        {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && (
                            <div style={{ display: 'flex', paddingLeft: 20, justifyContent: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isCoach}
                                            onChange={onCheckBoxStateChnage}
                                            name="isCoachCheckBox"
                                        />
                                    }
                                    label="Is Coach"
                                />
                                {isCoach && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAccessManager}
                                                onChange={onCheckBoxStateAMChange}
                                                name="isAccessManagerCheckBox"
                                            />
                                        }
                                        label="Is Accessmanager"
                                    />
                                )}
                            </div>
                        )}
                        <Autocomplete
                            id="group-select"
                            options={attributes.data?.departments?.filter((d) => d !== 'All') || []}
                            value={invitationGroup}
                            // @ts-ignore
                            onChange={(e, v) => setInvitationGroup(v)}
                            style={{ minWidth: '250px', marginBottom: 20, marginTop: 10 }}
                            limitTags={2}
                            disabled={!['ADMIN', 'SUPERVISOR'].includes(userCredentials.role)}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        className={classes.assignementChip}
                                        color="primary"
                                    />
                                ))
                            }
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Group" />}
                        />
                    </div>
                </FormControl>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '200px', height: '50px' }}
                        onClick={onGenerateInvitationPress}
                        disabled={!email || email?.length === 0}
                    >
                        Generate Invitation
                    </Button>
                </div>
            </div>
        );
    };

    const pendingInvitationsContent = () => {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: 178, maxHeight: 350 }}>
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Autocomplete
                        id="group-select"
                        options={attributes.data?.departments?.filter((d) => d !== 'All') || []}
                        value={groupsSelected}
                        // @ts-ignore
                        multiple
                        onChange={(e, v) => setgroupsSelected(v)}
                        style={{ minWidth: '250px' }}
                        limitTags={2}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    label={`${option}(${
                                        pendingInvitations.data.filter((invite) => invite.department === option).length
                                    })`}
                                    {...getTagProps({ index })}
                                    className={classes.assignementChip}
                                    color="primary"
                                />
                            ))
                        }
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Groups" />}
                    />
                </div>
                {pendingInvitations.data.length === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                            Nothing to Show
                        </Typography>
                    </div>
                )}
                <div style={{ width: '100%', overflowY: 'auto' }}>
                    {pendingInvitations.data
                        .sort((a, b) => (a.role < b.role ? -1 : Number(a.role > b.role)))
                        .filter((invite) => groupsSelected.includes(invite.department) || invite.department === 'All')
                        .map((item: any) => {
                            return (
                                <div className={classes.pendingInvitationContainer}>
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                                        <div
                                            style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                                        >
                                            <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                                {item.email}
                                            </Typography>
                                            <Chip
                                                label={item.role}
                                                className={classes.assignementChip}
                                                color="primary"
                                                classes={{ root: classes[`${item.role.toLowerCase()}`] }}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <Typography
                                                style={{ fontSize: '12px', fontWeight: 'bold' }}
                                            >{`Group: ${item.department}`}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <Typography
                                                style={{ fontStyle: 'italic', fontSize: '12px' }}
                                            >{`Code: ${item.invitationCode}`}</Typography>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    };

    const userCards = useMemo(() => {
        let { departments } = attributes.data;

        if (!['ADMIN', 'SUPERVISOR'].includes(userCredentials?.role)) {
            departments = [userProfile?.department];
        }
        return (
            <>
                {!large && (
                    <Carousel animation="slide" autoPlay={false} navButtonsAlwaysInvisible timeout={300}>
                        {departments
                            ?.filter((d) => d !== 'All')
                            .map((department) => {
                                return (
                                    <THGCard
                                        content={userList(department)}
                                        loading={users.loading}
                                        title={`${department} (${
                                            users.data.filter((user) => user.profile?.department === department).length
                                        })`}
                                        subheader=""
                                        avatar={<AccessibilityNewIcon fontSize="large" />}
                                    />
                                );
                            })}
                    </Carousel>
                )}
                {large && (
                    <>
                        {departments
                            ?.filter((d) => d !== 'All')
                            .map((department) => {
                                return (
                                    <THGCard
                                        content={userList(department)}
                                        loading={users.loading}
                                        title={`${department} (${
                                            users.data.filter((user) => user.profile?.department === department).length
                                        })`}
                                        subheader=""
                                        avatar={<AccessibilityNewIcon fontSize="large" />}
                                    />
                                );
                            })}
                    </>
                )}
            </>
        );
    }, [users]);

    return (
        <div className={classes.container}>
            <div className={classes.innerContent}>
                {userCards}
                <THGCard
                    content={pendingInvitationsContent()}
                    loading={pendingInvitations.loading}
                    title="Pending Invitations"
                    subheader=""
                    avatar={<HistoryIcon fontSize="large" />}
                />
                <THGCard
                    content={invitationContent()}
                    loading={false}
                    title="Invite more Users"
                    subheader=""
                    avatar={<EmailIcon fontSize="large" />}
                />
            </div>
        </div>
    );
};

export default AccessManagement;
