import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import React from 'react';
import { createUseStyles } from 'react-jss';
import LoadingIndicator from './loading/LoadingIndicator';

const styles = createUseStyles({
    root: {
        minWidth: 340,
        maxWidth: '100%',
        height: 'fit-content',
        padding: 5,
        marginBottom: 10,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: '10px!important',
    },
    cardHeader: {
        backgroundColor: (props) => (props.headerTransparent ? 'transparent' : 'rgba(0,86,44)'),
        // background: 'linear-gradient(to right, rgba(0,86,44) 10%, #fafafa 90%)',
        color: (props) => (props.headerTransparent ? 'rgba(0,86,44)' : 'white'),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
        padding: '5px!important',
        height: 40,
        '& >div>span': {
            fontWeight: 'bold',
            fontSize: '18px',
        },
    },
    headerRoot: {},
    title: {
        color: (props) => (props.headerTransparent ? 'rgba(0,86,44)' : 'white'),
        fontSize: '18px!important',
        fontWeight: 'bold!important',
        paddingLeft: (props) => (props.avatar ? 0 : 10),
    },
    subheader: {
        fontSize: '12px!important',
        color: 'white!important',
    },
});
interface THGCardProps {
    title?: string;
    subheader?: string;
    avatar?: any;
    content?: any;
    loading?: boolean;
    action?: any;
    headerTransparent?: boolean;
}

const THGCard = (props: THGCardProps): JSX.Element => {
    const { title = '', subheader = '', avatar, content, loading, action, headerTransparent = false } = props;
    const classes = styles(props);

    return (
        <Card className={classes.root} classes={{ root: classes.root }}>
            <CardHeader
                title={title}
                subheader={subheader}
                avatar={avatar}
                action={action}
                className={classes.cardHeader}
                classes={{ root: classes.headerRoot, title: classes.title, subheader: classes.subheader }}
            />
            <CardContent style={{ padding: 0 }}>
                {!loading && content}
                {loading && <LoadingIndicator size={120} />}
            </CardContent>
        </Card>
    );
};

export default THGCard;
