import { createHashHistory } from 'history';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Router as HashRouter, Switch } from 'react-router-dom';
import LoadingIndicator from '../components/loading/LoadingIndicator';
import { useActions } from '../hooks/useActions';
import Login from '../modules/login/Login';
import Register from '../modules/register/Register';
import THG from '../modules/thg/THG';
import { StateSelectors } from '../reducers/StateSelectors';
import UserActions from '../redux-actions/UserActions';

export const history = createHashHistory();

export const navigate = (path: string, stateParams = {}) => {
    const location = {
        pathname: path,
        state: stateParams,
    };
    history.push(location);
};

const Router = (): JSX.Element => {
    const user = useSelector(StateSelectors.getUser);

    const [getUserData] = useActions([(userId: any) => UserActions.getUser(userId)]);

    const tokenObject = localStorage.getItem('THG_AUTH_HEADER');
    const rememberUser = localStorage.getItem('THG_USER_REMEMBER');

    if (tokenObject && !user?.loading && !user?.error && !user?.data) {
        const { expireDate, userId } = JSON.parse(tokenObject);
        // console.log(moment(expireDate).utc().diff(moment.utc(), 'minutes'))
        if (moment(expireDate).utc().diff(moment.utc(), 'minutes') > 5) {
            getUserData(userId);
        }
    }

    if (user.loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                }}
            >
                <LoadingIndicator size={120} text={'Loading...'} />
            </div>
        );
    }

    const userIsLoggedIn = () => {
        if (user.data?.hasOwnProperty('accessToken') && !user.error) {
            return true;
        }
        return false;
    };

    const rootRoute = () => {
        if (userIsLoggedIn() && !user.data?.toBeDeleted) {
            return <Route path={'/'} component={THG} />;
        }
        return <Route path={'/'} component={Login} />;
    };

    return (
        <HashRouter history={history}>
            <Switch>
                <Route path={'/register'} component={Register} />
                {rootRoute()}
            </Switch>
        </HashRouter>
    );
};

export default Router;
