import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import YouTube from 'react-youtube';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ExerciseDialog from '../../components/dialogs/ExerciseDialog';
import { useActions } from '../../hooks/useActions';
import ExercisesActions from '../../redux-actions/ExercisesActions';
import handle from '../../assets/exercises/handle.png';
import running from '../../assets/exercises/running.png';
import heartbeat from '../../assets/exercises/heartbeat.png';
import stretch from '../../assets/exercises/stretch.png';
import dots from '../../assets/exercises/dots.png';

const styles = createUseStyles({
    outerContainer: {
        position: 'relative',
        margin: 10,
        marginTop: 50,
    },
    container: {
        width: 340,
        minHeight: 150,
        height: 'fit-content',
        position: 'relative',
        borderRadius: 10,
        paddingBottom: 5,
        flexWrap: 'wrap',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.1)',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        padding: 10,
        paddingTop: 5,
        width: 'calc(100% - 20px)',
    },
    descriptionContainer: {
        boxShadow: 'unset!important',
    },
    heading: {
        fontSize: 15,
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100px',
        paddingLeft: '10px',
        zIndex: 1,
        position: 'absolute',
        top: -30,
    },
    image: {
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        backgroundColor: 'rgba(0,86,44)',
        borderRadius: 10,
    },
    iconButton: {
        width: '30px',
        height: '30px',
        padding: '0!important',
    },
    dialog: {
        minWidth: 350,
    },
    chip: {
        margin: 2,
        fontSize: '8px!important',
        height: '16px!important',
        backgroundColor: 'rgba(72, 85, 79)!important',
        color: 'white!important',
    },
    chipMuscles: {
        margin: 2,
        fontSize: '8px!important',
        height: '20px!important',
        backgroundColor: 'rgb(0,0,0)!important',
        color: 'white!important',
    },
    nameTextRoot: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        fontWeight: 'bold!important',
        // color: 'rgba(5, 116, 19)!important',
        // backgroundColor: 'rgba(5, 116, 19)!important',
        textShadow: '2px 2px rgb(211,211,211)!important',
    },
    accordionDetailsRoot: {
        maxHeight: '350px',
    },
});

interface ExerciseProps {
    item: any;
    modifyable?: boolean;
    infoExpanded?: boolean;
}

const Exercise = (props: ExerciseProps): JSX.Element => {
    const { item, modifyable = false, infoExpanded = false } = props;
    const { description, id } = item;

    const classes = styles();

    const image = () => {
        const { type } = description;

        switch (type) {
            case 'Strength':
                return handle;
            case 'Condition':
                return running;
            case 'Prevention & Rehab':
                return heartbeat;
            case 'Mobility':
                return stretch;
            default:
                return dots;
        }
    };

    const [showEditModel, setShowEditModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [isInfoExpanded, setIsInfoExpanded] = useState(infoExpanded);

    const [deleteExercise, updateExercise] = useActions([
        (id: any) => ExercisesActions.deleteExercise(id),
        (exercise: any) => ExercisesActions.updateExerciseDefinition(exercise),
    ]);

    const onEditExercisePress = useCallback(() => {
        setShowEditModel(true);
    }, [setShowEditModel]);

    const onDeleteExercisePress = useCallback(() => {
        setShowDeleteModel(true);
    }, [setShowDeleteModel]);

    const onCancelEditPress = useCallback(() => {
        setShowEditModel(false);
    }, [setShowEditModel]);

    const onSaveEditPress = useCallback(
        (exercise) => {
            updateExercise(exercise);
            setShowEditModel(false);
        },
        [updateExercise, setShowEditModel],
    );

    const onCancelDeletePress = useCallback(() => {
        setShowDeleteModel(false);
    }, [setShowDeleteModel]);

    const onSaveDeletePress = useCallback(() => {
        setShowDeleteModel(false);
        deleteExercise(id);
    }, [deleteExercise, setShowDeleteModel]);

    const editDialog = () => {
        return (
            <ExerciseDialog
                title="Edit Exercise"
                item={item}
                mode="edit"
                open={showEditModel}
                onSavePress={onSaveEditPress}
                onCancelPress={onCancelEditPress}
            />
        );
    };

    const deleteDialog = () => {
        return (
            <DeleteDialog
                title="Delete Exercise"
                item={item}
                open={showDeleteModel}
                onSavePress={onSaveDeletePress}
                onCancelPress={onCancelDeletePress}
            />
        );
    };

    return (
        <div className={classes.outerContainer}>
            <div className={classes.imageContainer}>
                <div className={classes.image}>
                    <img src={image()} alt={description?.name} height="30px" />
                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.headerContainer}>
                    {modifyable && (
                        <div style={{ width: '60px', display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={onEditExercisePress}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={onDeleteExercisePress}
                            >
                                <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                        </div>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                            marginTop: modifyable ? 0 : 30,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                                paddingBottom: 10,
                            }}
                        >
                            <Typography style={{ fontSize: '18px' }} classes={{ root: classes.nameTextRoot }}>
                                {description?.name}
                            </Typography>
                            <Typography style={{ fontSize: '12px' }}>{description?.comment}</Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                paddingTop: 5,
                                padding: 2,
                                borderBottom: '1px solid rgb(211,211,211)',
                            }}
                        >
                            <AssessmentIcon color="primary" style={{ color: 'rgba(0,86,44)' }} />
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {description?.measures?.map((measure: string, index: number) => {
                                    return (
                                        <Chip
                                            key={measure.toUpperCase()}
                                            label={measure.toUpperCase()}
                                            className={classes.chip}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div style={{ display: 'flex', padding: 2, alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: 2 }}>
                                <FitnessCenterIcon color="primary" style={{ color: 'rgba(0,86,44)' }} />
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', padding: 2 }}>
                                {description?.muscleGroupsDetailed?.map((muscle: any, index: number) => {
                                    return (
                                        <Chip
                                            key={muscle.name}
                                            label={muscle.title.toUpperCase()}
                                            className={classes.chipMuscles}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <Accordion
                    className={classes.descriptionContainer}
                    TransitionProps={{ unmountOnExit: true }}
                    expanded={isInfoExpanded}
                    onChange={(e, expanded) => setIsInfoExpanded(expanded)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', width: 308 }}>
                            {description?.videoId && (
                                <YouTube opts={{ width: '308', height: '200' }} videoId={description?.videoId} />
                            )}
                            {description?.info && <Typography>{description?.info}</Typography>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            {showEditModel && editDialog()}
            {showDeleteModel && deleteDialog()}
        </div>
    );
};

export default Exercise;

// rxD321l2svE
