import { combineReducers } from 'redux';
import actionResponse from './actionResponseReducer';
import attributes from './attributesReducers';
import exerciseDefinitions from './exercisesReducer';
import invitations from './invitationsReducer';
import user from './userReducer';
import userManagement from './userManagementReducer';
import workouts from './workoutsReducers';
import usage from './usageReducer';

const mainReducer = combineReducers({
    exerciseDefinitions,
    user,
    invitations,
    attributes,
    workouts,
    actionResponse,
    userManagement,
    usage,
});

export default mainReducer;
