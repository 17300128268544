import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import oneRepMaxChart from '../../assets/oneRepMaxChart.png';
import LoadingIndicator from '../../components/loading/LoadingIndicator';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UserActions from '../../redux-actions/UserActions';
import { useMedia } from '../../util/useMedia';
import { oneRmConfig } from './oneRmConfig';

const styles = createUseStyles({
    contentContainer: {
        maxHeight: '100%',
        width: '100%',
        position: 'relative',
        paddingTop: 5,
    },
    innerContent: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflowY: 'auto',
    },
    image: {
        borderRadius: 10,
    },
    inputOuter: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    inputContainer: {
        display: 'flex',
        margin: 5,
    },
    textField: {
        width: '25ch',
    },
    actionContainer: {
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    tableHead: {
        '& >th': {
            backgroundColor: '#8FBC8F',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '14px',
        },
    },
    tableContainerWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        borderRadius: 10,
    },
    table: {
        maxWidth: 700,
        width: 'fit-content',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.1)',
    },
    tableContainer: {
        maxHeight: 400,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 10,
    },
    infoContainer: {
        padding: 5,
        textAlign: 'center',
    },
    exerciseSelectGroupLabel: {
        color: 'rgba(0,86,44)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
});

const OneRM = (): JSX.Element => {
    const location = useLocation();

    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const user = useSelector(StateSelectors.getUser);
    const userCredentials = useSelector(StateSelectors.getUserCredentials);
    const definedExercises = useSelector(StateSelectors.getExercisesFor1RM);

    const [selectedExercise, setSelectedExercise] = useState();
    const [setsMade, setSetsMade] = useState('');
    const [weightUsed, setWeightUsed] = useState('');
    const [showTable, setShowTable] = useState(false);

    const [saveORM] = useActions([(userToBe: any) => UserActions.updateUserORM(userToBe)]);

    useEffect(() => {
        //@ts-ignore
        if (location.state?.exercise) {
            //@ts-ignore
            const selectedExercise = definedExercises.data?.filter((ex) => ex.id === location.state?.exercise)[0];
            setSelectedExercise(selectedExercise);
        }
    }, [location.state, setSelectedExercise, definedExercises]);

    useEffect(() => {
        const { userORM = [] } = user?.data;
        //@ts-ignore
        const ormForSelectedExercise = userORM.find((e: any) => e.exercise === selectedExercise?.id);
        if (ormForSelectedExercise !== undefined) {
            setWeightUsed(`${ormForSelectedExercise.orm}`);
            setSetsMade('1');
            setShowTable(true);
        } else {
            setWeightUsed('');
            setSetsMade('');
            setShowTable(false);
        }
    }, [user?.data, selectedExercise]);

    const validateInput = () => {
        return weightUsed === '' || !weightUsed || !setsMade || setsMade === '' || !selectedExercise;
    };

    const oneRMForPercent = useCallback(
        (percentage: number) => {
            const percentageForCalculation = oneRmConfig.filter((item) => item.reps === parseFloat(setsMade))[0]
                ?.percent;
            // @ts-ignore
            const factor = 100 / percentageForCalculation;

            return parseFloat(`${parseFloat(weightUsed) * factor * (percentage / 100)}`).toFixed(0);
        },
        [setsMade, weightUsed],
    );

    const onCalculateOneRm = useCallback(() => {
        const max = oneRMForPercent(100);

        //@ts-ignore
        saveORM({ exercise: selectedExercise?.id, orm: max, userId: userCredentials.userId });
    }, [saveORM, selectedExercise, oneRMForPercent, userCredentials]);

    const onExerciseSelectionChange = useCallback(
        (value) => {
            setSelectedExercise(value);
        },
        [selectedExercise, setsMade, weightUsed],
    );

    const buttonLabel = useMemo(() => {
        const { userORM = [] } = user?.data;

        //@ts-ignore
        const ormForSelectedExercise = userORM.find((e: any) => e.exercise === selectedExercise?.id);

        if (ormForSelectedExercise !== undefined) {
            return 'Update 1-RM';
        }
        return 'Calculate 1-RM';
    }, [selectedExercise, user?.data]);

    return (
        <div className={classes.contentContainer}>
            <div className={classes.innerContent}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <Autocomplete
                        id={`exercise-select`}
                        options={
                            definedExercises.data.filter((e) => {
                                return e.description.type === 'Strength' || e.description.type === 'BIG-4';
                            }) || []
                        }
                        value={selectedExercise || { description: { name: '' } }}
                        onChange={(e, v) => onExerciseSelectionChange(v)}
                        classes={{ groupLabel: classes.exerciseSelectGroupLabel }}
                        groupBy={(option: any) => option.description?.type}
                        getOptionLabel={(option: any) => {
                            return option.description?.name;
                        }}
                        style={{ margin: 5, marginBottom: 20, width: 300 }}
                        renderInput={(params) => {
                            return <TextField {...params} variant="outlined" placeholder="Select Exercise..." />;
                        }}
                    />
                </div>

                <div className={classes.inputOuter}>
                    <div className={classes.inputContainer}>
                        <TextField
                            label="Weight used"
                            id="weight"
                            className={classes.textField}
                            onChange={(e: any) => setWeightUsed(e.target.value)}
                            value={weightUsed}
                            style={{ width: 150 }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                            }}
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-reps-select">Reps made</InputLabel>
                            <Select
                                id={`reps-select`}
                                value={parseFloat(setsMade)}
                                variant="outlined"
                                label="Reps made"
                                onChange={(e: any) => setSetsMade(e.target.value)}
                                style={{ width: 150 }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 350,
                                        },
                                    },
                                }}
                                inputProps={{
                                    name: 'reps',
                                    id: 'outlined-reps-select',
                                }}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(
                                    (number: number) => {
                                        return <MenuItem value={number}>{number}</MenuItem>;
                                    },
                                )}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.actionContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        disabled={validateInput()}
                        onClick={onCalculateOneRm}
                    >
                        {buttonLabel}
                    </Button>
                </div>
                {showTable && user.ormLoading && <LoadingIndicator size={120} />}
                {showTable && !user.ormLoading && (
                    <div className={classes.tableContainerWrapper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} stickyHeader>
                                <TableHead>
                                    <TableRow className={classes.tableHead}>
                                        <TableCell>% ONE-RM</TableCell>
                                        <TableCell align="right">Weight</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[
                                        100,
                                        95,
                                        90,
                                        85,
                                        80,
                                        75,
                                        70,
                                        65,
                                        60,
                                        55,
                                        50,
                                        45,
                                        40,
                                        35,
                                        30,
                                        25,
                                        20,
                                        15,
                                        10,
                                        5,
                                    ].map((percentage, index) => {
                                        return (
                                            <TableRow key={`${index}pilquin`}>
                                                <TableCell align="right">{percentage}%</TableCell>
                                                <TableCell align="right">
                                                    {`${oneRMForPercent(percentage)}kg`}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '10px',
                        borderRadius: 10,
                    }}
                >
                    <img
                        src={oneRepMaxChart}
                        alt=""
                        width={large ? '500px' : '100%'}
                        height={large ? '300px' : '200px'}
                        className={classes.image}
                    />
                </div>
                <div className={classes.infoContainer}>
                    <p style={{ fontSize: '12px' }}>
                        Calculate your one rep max for any lift with this 1RM calculator. Your one rep max is the
                        maximum weight you should lift for a single rep of a given exercise.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default OneRM;
