import * as fromActionResponses from './actionResponseReducer';
import * as fromAttributes from './attributesReducers';
import * as fromDefinedExercises from './exercisesReducer';
import * as fromInvitations from './invitationsReducer';
import * as fromUser from './userReducer';
import * as fromUserManagement from './userManagementReducer';
import * as fromWorkouts from './workoutsReducers';
import * as fromUsage from './usageReducer';
import { createSelector } from 'reselect';

export class StateSelectors {
    static getDefinedExercises = (state) => fromDefinedExercises.getDefinedExercises(state.exerciseDefinitions);

    static getExercisesFor1RM = createSelector(this.getDefinedExercises, (exercises) => {
        let currentData = [...exercises.data];

        if (exercises?.data?.length > 0) {
            const big4 = exercises.data
                .filter((ex) => {
                    return [
                        'a33bcd66-6d2f-45a7-9381-5321332c88f5',
                        'ab630e64-fa92-4961-b2ab-e170555c0c42',
                        '28765a6e-608d-4bc5-8f6f-49f207811349',
                        '794e92bd-a16e-4037-aa6e-97bd6685e17b',
                    ].includes(ex.id);
                })
                .map((ex) => {
                    return { ...ex, description: { ...ex.description, type: 'BIG-4' } };
                });

            currentData = currentData.filter((ex) => {
                return ![
                    'a33bcd66-6d2f-45a7-9381-5321332c88f5',
                    'ab630e64-fa92-4961-b2ab-e170555c0c42',
                    '28765a6e-608d-4bc5-8f6f-49f207811349',
                    '794e92bd-a16e-4037-aa6e-97bd6685e17b',
                ].includes(ex.id);
            });
            return { ...exercises, data: big4.concat(currentData) };
        }

        return { ...exercises };
    });

    static big4Exercises = createSelector(StateSelectors.getDefinedExercises, (exercises) => {
        return {
            ...exercises,
            data: exercises.data.filter((ex) => {
                const big4Ids = [
                    'a33bcd66-6d2f-45a7-9381-5321332c88f5',
                    'ab630e64-fa92-4961-b2ab-e170555c0c42',
                    '28765a6e-608d-4bc5-8f6f-49f207811349',
                    '794e92bd-a16e-4037-aa6e-97bd6685e17b',
                ];
                return big4Ids.includes(ex.id);
            }),
        };
    });

    static getUser = (state) => fromUser.getUser(state.user);
    static getUserCredentials = (state) => fromUser.getUserCredentials(state.user);
    static getUserProfile = (state) => fromUser.getUserProfile(state.user);

    static userORM = createSelector(StateSelectors.getUser, (user) => {
        return { loading: user.loading, error: user.error, data: user.data.userORM };
    });

    static getWorkouts = (state) => fromWorkouts.getWorkouts(state.workouts);

    static workoutsForUsage = createSelector(StateSelectors.getWorkouts, (workouts) => {
        return {
            ...workouts,
            data: workouts?.data?.map((workout) => {
                return { ...workout, usageId: `workout_${workout.id}` };
            }),
        };
    });

    static getUsers = (state) => fromUserManagement.getUsers(state.userManagement);
    static getUsage = (state) => fromUsage.getUsage(state.usage);

    static getNumberOfLoginAttempts = (state) => fromUser.getLoginAttempts(state.user);

    static getInvitations = (state) => fromInvitations.getInvitations(state.invitations);

    static getAttributes = (state) => fromAttributes.getAttributes(state.attributes);

    static getActionresponses = (state) => fromActionResponses.getActionresponse(state.actionResponse);
}
