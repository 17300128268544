import DataUsageIcon from '@mui/icons-material/DataUsage';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import THGCard from '../../components/THGCard';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UsageActions from '../../redux-actions/UsageActions';
import WorkoutActions from '../../redux-actions/WorkoutActions';
import { TimeUtils } from '../../util/TimeUtils';

const styles = createUseStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        paddingTop: 5,
    },
    usageContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    usageContainerRow: {
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },
});

interface SupportProps {}

const UsageSummary = (props: SupportProps): JSX.Element => {
    const classes = styles();

    const workoutsForUsage = useSelector(StateSelectors.workoutsForUsage);
    const usageSummary = useSelector(StateSelectors.getUsage);
    const [loadAllWorkouts] = useActions([() => WorkoutActions.getAllWorkouts()]);
    const [loadUsageForResource] = useActions([(resource) => UsageActions.getUsageEntriesForResource(resource)]);

    useEffect(() => {
        if (workoutsForUsage?.data?.length === 0 && !workoutsForUsage.loading) {
            loadAllWorkouts();
        }
    }, [workoutsForUsage]);

    useEffect(() => {
        loadUsageForResource('login');
        workoutsForUsage?.data?.forEach((workout) => loadUsageForResource(`workout_${workout.id}`));
    }, [workoutsForUsage]);

    const renderUsageContentForLogins = () => {
        const { data } = usageSummary;
        const loginSummary = data?.login || [];

        return (
            <div className={classes.usageContainer}>
                <div className={classes.usageContainerRow}>
                    <div>Today</div>
                    <div style={{ fontWeight: 'bold' }}>
                        {loginSummary.filter((entry) => entry.loginDate === TimeUtils.today)?.[0]?.loginCount || 0}
                    </div>
                </div>
                <div className={classes.usageContainerRow}>
                    <div>Yesterday</div>
                    <div style={{ fontWeight: 'bold' }}>
                        {loginSummary.filter((entry) => entry.loginDate === TimeUtils.yesterday)?.[0]?.loginCount || 0}
                    </div>
                </div>
            </div>
        );
    };

    const renderUsageContentForWorkout = (resource) => {
        const { data } = usageSummary;
        const resourceSummary = data?.[resource] || [];

        return (
            <div className={classes.usageContainer}>
                <div className={classes.usageContainerRow}>
                    <div>Today</div>
                    <div style={{ fontWeight: 'bold' }}>
                        {resourceSummary.filter((entry) => entry.loginDate === TimeUtils.today)?.[0]?.loginCount || 0}
                    </div>
                </div>
                <div className={classes.usageContainerRow}>
                    <div>Yesterday</div>
                    <div style={{ fontWeight: 'bold' }}>
                        {resourceSummary.filter((entry) => entry.loginDate === TimeUtils.yesterday)?.[0]?.loginCount ||
                            0}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <THGCard
                content={renderUsageContentForLogins()}
                loading={usageSummary.loading}
                title="Login"
                subheader="Totals"
                avatar={<DataUsageIcon fontSize="large" />}
            />
            {workoutsForUsage?.data?.map((workout) => {
                return (
                    <THGCard
                        content={renderUsageContentForWorkout(`workout_${workout.id}`)}
                        loading={usageSummary.loading}
                        title={workout.name}
                        subheader="Totals"
                        avatar={<DataUsageIcon fontSize="large" />}
                    />
                );
            })}
        </div>
    );
};

export default UsageSummary;
