import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import TimerIcon from '@mui/icons-material/Timer';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMedia } from '../util/useMedia';
import CountDownDialog from './dialogs/CountdownDialog';

const styles = createUseStyles({
    root: {
        backgroundColor: '#293133!important',
        height: '50px!important',
    },
    toolBarRoot: {
        display: 'flex',
        minHeight: '0px!important',
        height: '100%',
    },

    menuButton: {
        marginRight: 10,
        width: 'auto',
    },
    avatar: {
        color: 'white!important',
        fontWeight: 'bold',
        backgroundColor: 'rgba(0,86,44)!important',
        border: '1px solid #fafafa',
        cursor: 'pointer',
        height: '30px!important',
        width: '30px!important',
        fontSize: '12px!important',
    },
});
interface THGAppBarProps {
    onMenuClick: () => void;
    onAvatarClick?: () => void;
    menuConfig?: any;
    initials?: any;
}

const THGAppBar = (props: THGAppBarProps): JSX.Element => {
    const { onMenuClick, menuConfig, onAvatarClick, initials } = props;
    const classes = styles();

    const [header, setHeader] = useState<string>('');
    const [showTimer, setShowTimer] = useState<boolean>(false);

    const large = useMedia('(min-width: 900px)');

    const onTimerIconClick = useCallback(() => {
        setShowTimer(true);
    }, [setShowTimer]);

    useEffect(() => {
        setHeader(
            menuConfig.filter((menuItem) => menuItem.path === window.location.hash.replace('#', ''))[0]?.buttonText,
        );
    }, [window.location.hash, menuConfig]);

    const countdownDialog = () => {
        return <CountDownDialog open={showTimer} onCountdownClose={() => setShowTimer(false)} />;
    };

    return (
        <AppBar position="static" classes={{ root: classes.root }}>
            <Toolbar classes={{ root: classes.toolBarRoot }} variant="dense">
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onMenuClick}
                        >
                            <MenuIcon />
                        </IconButton>
                        {large && (
                            <Typography variant="h6" noWrap>
                                {header}
                            </Typography>
                        )}
                    </div>
                    {!large && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" noWrap>
                                {header}
                            </Typography>
                        </div>
                    )}
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            onClick={onTimerIconClick}
                        >
                            <TimerIcon />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }} onClick={onAvatarClick}>
                            <Avatar className={classes.avatar}>{initials}</Avatar>
                        </div>
                    </div>
                </div>
            </Toolbar>
            {showTimer && countdownDialog()}
        </AppBar>
    );
};

export default THGAppBar;
