import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import ActionResponse from '../../components/ActionResponse';
import ChangePasswordDialog from '../../components/dialogs/implementations/ChangePasswordDialog';
import ProfileDialog from '../../components/dialogs/ProfileDialog';
import Impressum from '../../components/Impressum';
import THGAppBar from '../../components/THGAppBar';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import AttributesActions from '../../redux-actions/AttributesActions';
import ExercisesActions from '../../redux-actions/ExercisesActions';
import UserActions from '../../redux-actions/UserActions';
import { useMedia } from '../../util/useMedia';
import UserProfileUtils from '../../util/userProfileUtils';
import OneRM from '../1rm/OneRM';
import AccessManagement from '../access-management/AccessManagement';
import CatalogManagement from '../catalog-management/CatalogManagement';
import Exercises from '../catalog/Exercises';
import Dashboard from '../dashboard/Dashboard';
import Education from '../education/Education';
import * as config from '../sideMenu/menuConfig';
import SideMenu from '../sideMenu/SideMenu';
import Support from '../support/Support';
import WorkoutManagement from '../workout-management/WorkoutManagement';
import Workouts from '../workouts/Workouts';
import UsageSummary from '../usage/UsageSummary';

const styles = createUseStyles({
    dashboardContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
    },
    menuButton: {
        background: 'transparent',
        color: '#293133',
    },
});

const THG = (): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const [sidebarOpen, setSidebarOpen] = useState<boolean>(large);
    const [sidebarDocked, setSidebarDocked] = useState<boolean>(large);

    const [showProfileDialog, setShowProfileDialog] = useState(false);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false);
    const [userProfileComplete, setUserProfileComplete] = useState(true);

    const user = useSelector(StateSelectors.getUser);

    const { credentials, userRights } = user.data;

    const [loadDefinedExercises, loadAttributes, saveUser, changePassword, getPrincipal] = useActions([
        () => ExercisesActions.getDefinedExercises(),
        () => AttributesActions.getDistinctAttributes(),
        (userToBe: any) => UserActions.updateUser(userToBe),
        (passwords: any) => UserActions.changePassword(passwords),
        () => UserActions.getPrincipal(),
    ]);

    useEffect(() => {
        getPrincipal();
    }, []);

    useEffect(() => {
        if (user.data?.credentials?.hasToChangePassword) {
            setShowChangePasswordDialog(true);
        } else {
            setShowChangePasswordDialog(false);
            if (!UserProfileUtils.checkUserProfileComplete(user.data?.profile)) {
                setUserProfileComplete(false);
                setShowProfileDialog(true);
            }
        }
    }, [
        user.data?.profile,
        user.data?.credentials?.hasToChangePassword,
        setUserProfileComplete,
        setShowProfileDialog,
        setShowChangePasswordDialog,
    ]);

    useEffect(() => {
        setSidebarDocked(large && sidebarOpen);
    }, [large, sidebarOpen]);

    useEffect(() => {
        loadDefinedExercises();
        loadAttributes();
    }, [loadDefinedExercises, loadAttributes, credentials]);

    const onCancelEditPress = useCallback(() => {
        setShowProfileDialog(false);
    }, [setShowProfileDialog]);

    const onSaveEditPress = useCallback(
        (userProfile) => {
            setShowProfileDialog(false);
            saveUser({ ...user.data, profile: userProfile });
        },
        [setShowProfileDialog],
    );

    const onShowHideMenu = useCallback(() => {
        setSidebarOpen((isOpen) => !isOpen);
    }, [setSidebarOpen]);

    const onNavigate = useCallback(
        (item) => {
            if (!large) {
                setSidebarOpen(false);
            }
        },
        [setSidebarOpen, large],
    );

    const onApplyPasswordChangePress = useCallback(
        (passwords) => {
            changePassword(passwords);
        },
        [changePassword],
    );

    const profileDialog = () => {
        return (
            <ProfileDialog
                open={showProfileDialog}
                onSavePress={onSaveEditPress}
                onCancelPress={onCancelEditPress}
                showCancelButton={userProfileComplete}
            />
        );
    };

    const changePasswordDialog = () => {
        return <ChangePasswordDialog open={showChangePasswordDialog} onApplyPress={onApplyPasswordChangePress} />;
    };

    const onShowProfileClick = useCallback(() => {
        setShowProfileDialog(true);
    }, [setShowProfileDialog]);

    const initials = () => {
        const { profile } = user.data;
        if (profile?.firstName && profile?.lastName) {
            return `${profile?.firstName?.substring(0, 1).toUpperCase()}${profile?.lastName
                ?.substring(0, 1)
                .toUpperCase()}`;
        }
        return null;
    };

    const renderSideBar = useMemo(() => {
        return (
            <Sidebar
                sidebar={<SideMenu large={large} onNavigate={onNavigate} userRole={credentials.role} />}
                open={sidebarOpen}
                defaultSidebarWidth={100}
                docked={sidebarDocked}
                onSetOpen={onShowHideMenu}
                styles={{
                    content: { backgroundColor: '#eeeeee' },
                    sidebar: { zIndex: '4', width: '250px' },
                }}
            >
                <THGAppBar
                    onMenuClick={onShowHideMenu}
                    menuConfig={config.menu}
                    onAvatarClick={onShowProfileClick}
                    initials={initials()}
                />
                <div className={classes.contentContainer}>
                    <Route path="/thg/impressum" component={Impressum} />
                    <Route path="/thg/support" component={Support} />
                    <Route path="/thg/catalog" component={Exercises} />
                    <Route path="/thg/1-rm" component={OneRM} />
                    <Route
                        path="/thg/workouts"
                        component={
                            ['ADMIN', 'SUPERVISOR'].includes(user?.data?.credentials?.role)
                                ? WorkoutManagement
                                : Workouts
                        }
                    />
                    <Route path="/thg/education" component={Education} />
                    {(['ADMIN', 'SUPERVISOR'].includes(credentials.role) || userRights?.['manage-access']) && (
                        <Route path="/thg/user-management" component={AccessManagement} />
                    )}
                    {['ADMIN', 'SUPERVISOR'].includes(credentials.role) && (
                        <Route path="/thg/catalog-management" component={CatalogManagement} />
                    )}
                    {['ADMIN', 'SUPERVISOR'].includes(credentials.role) && (
                        <Route path="/thg/usage" component={UsageSummary} />
                    )}
                    <Route path="/" exact component={Dashboard} />
                    <ActionResponse />
                </div>
            </Sidebar>
        );
    }, [credentials, classes, onNavigate, onShowHideMenu, sidebarOpen, large, sidebarDocked]);

    return (
        <div className={classes.dashboardContainer}>
            {renderSideBar}
            {showProfileDialog && profileDialog()}
            {showChangePasswordDialog && changePasswordDialog()}
        </div>
    );
};

export default THG;
