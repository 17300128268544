import moment from 'moment';
import { handleActions } from 'redux-actions';
import UserActions from '../redux-actions/UserActions';

const initialUserState = {
    loginAttempts: 0,
    registerRunning: false,
    loading: null,
    error: null,
    data: null,
    ormLoading: false,

};

const user = handleActions(
    {
        [UserActions.logIn]: (state) => {
            return {
                ...state,
                loginAttempts: state.loginAttempts + 1,
                registerRunning: false,
                data: null,
                loading: true,
                error: false,
            };
        },
        [UserActions.getUser]: (state) => {
            return {
                ...state,
                loginAttempts: state.loginAttempts + 1,
                data: null,
                registerRunning: false,
                loading: true,
                error: false,
            };
        },
        [UserActions.logInSuccess]: (state, { payload: { user } }) => {
            return {
                ...state,
                loginAttempts: 0,
                data: user,
                loading: false,
                error: false,
            };
        },
        [UserActions.logInError]: (state, { payload: { message } }) => {
            return {
                ...state,
                loginAttempts: state.loginAttempts + 1,
                data: null,
                loading: false,
                error: message,
            };
        },
        [UserActions.updateUserSuccess]: (state, { payload: { userToBe } }) => {
            return {
                ...state,
                loginAttempts: 0,
                data: {
                    ...state.data,
                    credentials: userToBe.credentials,
                    toBeDeleted: userToBe.toBeDeleted,
                    deletionDate: moment(userToBe.deletionDate),
                    profile: userToBe.profile,
                    userORM: userToBe.userORM,
                },
                loading: false,
                ormLoading: false,

                error: false,
            };
        },
        [UserActions.updateUserORM]: (state, { payload: { orm } }) => {
            return {
                ...state,
                loginAttempts: 0,
                ormLoading: true,
                error: false,
            };
        },
        [UserActions.updateUserError]: (state, { payload: { message } }) => {
            return {
                ...state,
                loading: false,
                error: message,
            };
        },
        [UserActions.logout]: (state) => {
            return initialUserState;
        },
        [UserActions.resetPassword]: (state) => {
            return {
                ...state,
                loginAttempts: 0,
            };
        },
        [UserActions.register]: (state) => {
            return {
                ...state,
                registerRunning: true,
            };
        },
        [UserActions.registerError]: (state) => {
            return {
                ...state,
                registerRunning: false,
            };
        },
    },
    initialUserState,
);

export const getUser = (state) => state;
export const getUserProfile = (state) => state.data.profile;

export const getUserCredentials = (state) => state.data.credentials;
export const getLoginAttempts = (state) => state.loginAttempts;

export default user;
