import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { StateSelectors } from '../../reducers/StateSelectors';

const styles = createUseStyles({
    assignementChipSmall: {
        margin: 2,
        fontSize: '8px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    selectGroupLabel: {
        color: 'rgba(5, 116, 19)!important',
        fontWeight: 'bold!important',
        fontSize: '18px!important',
    },
});

interface ExercisesFiltersDialogProps {
    onSavePress: (filters: any) => void;
    open: boolean;
    onCancelPress: () => void;
    title: string;
    currentFilters: any;
}

const ExercisesFiltersDialog = (props: ExercisesFiltersDialogProps): JSX.Element => {
    const { onSavePress, onCancelPress, open, title, currentFilters } = props;
    const classes = styles();
    const attributes = useSelector(StateSelectors.getAttributes);

    const [userFilters, setUserFilters] = useState(currentFilters);

    const onCancelExercisePress = useCallback(() => {
        onCancelPress();
    }, [onCancelPress]);

    const onSaveApplyPress = useCallback(() => {
        onSavePress(userFilters);
    }, [userFilters]);

    const onUserFilterChange = useCallback(
        (value, filter) => {
            setUserFilters((previousFilters: any) => ({ ...previousFilters, [filter]: value }));
        },
        [setUserFilters],
    );

    const filters = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    margin: 5,
                    flexWrap: 'wrap',
                    width: 'calc(100% - 10px)',
                    justifyContent: 'flex-end',
                }}
            >
                <Autocomplete
                    id="muscles-detailed-select"
                    options={attributes.data?.muscleGroupsDetailed || []}
                    value={userFilters.muscleGroups || []}
                    onChange={(e, v) => onUserFilterChange(v, 'muscleGroups')}
                    classes={{ groupLabel: classes.selectGroupLabel }}
                    style={{ width: '100%', marginBottom: 20 }}
                    multiple
                    groupBy={(option: any) => option.group}
                    getOptionLabel={(option: any) => {
                        return option.title;
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                label={option.title}
                                {...getTagProps({ index })}
                                className={classes.assignementChipSmall}
                                color="primary"
                            />
                        ))
                    }
                    renderInput={(params) => {
                        return <TextField {...params} variant="standard" placeholder="Select Muscles..." />;
                    }}
                />
            </div>
        );
    };

    return (
        <Dialog fullWidth={true} open={open} onClose={() => {}}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <DialogContent>{filters()}</DialogContent>
            <DialogActions>
                <Button onClick={onCancelExercisePress} variant="outlined">
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={onSaveApplyPress}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExercisesFiltersDialog;

// rxD321l2svE
