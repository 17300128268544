const oneRmConfig = [
  {
    percent: 100,
    reps: 1,
  },
  {
    percent: 94,
    reps: 2,
  },

  {
    percent: 90,
    reps: 3,
  },
  {
    percent: 88,
    reps: 4,
  },
  {
    percent: 85,
    reps: 5,
  },
  {
    percent: 83,
    reps: 6,
  },
  {
    percent: 80,
    reps: 7,
  },
  {
    percent: 78,
    reps: 8,
  },
  {
    percent: 76,
    reps: 9,
  },
  {
    percent: 74,
    reps: 10,
  },
  {
    percent: 72,
    reps: 11,
  },
  {
    percent: 70,
    reps: 12,
  },
  {
    percent: 65,
    reps: 12,
  },
  {
    percent: 68,
    reps: 13,
  },
  {
    percent: 67,
    reps: 14,
  },
  {
    percent: 66,
    reps: 15,
  },
  {
    percent: 65,
    reps: 16,
  },
  {
    percent: 63,
    reps: 17,
  },
  {
    percent: 62,
    reps: 18,
  },
  {
    percent: 61,
    reps: 19,
  },
  {
    percent: 60,
    reps: 20,
  },
];

// eslint-disable-next-line import/prefer-default-export
export { oneRmConfig };
// POLIQUIN-Tabelle
