import { createAction } from 'redux-actions';

export default class UserActions {
    static logIn = createAction('LOG_IN_REQUESTED', (credentials) => ({
        credentials,
    }));
    static logInSuccess = createAction('LOG_IN_SUCCESS', (user) => ({
        user,
    }));
    static logInError = createAction('LOG_IN_ERROR', (message) => ({ message }));

    static register = createAction('REGISTER_REQUESTED', (credentials) => ({
        credentials,
    }));
    static registerError = createAction('REGISTER_ERROR', () => ({}));

    static getUser = createAction('USER_FETCH_REQUESTED', (userId) => ({
        userId,
    }));

    static getAllUser = createAction('ALL_USER_FETCH_REQUESTED', () => ({}));

    static getAllUserSuccess = createAction('ALL_USER_FETCH_SUCCESS', (users) => ({
        users,
    }));
    static getAllUserError = createAction('ALL_USER_FETCH_ERROR', (message) => ({
        message,
    }));

    static updateUser = createAction('USER_UPDATE_REQUEST', (userToBe) => ({
        userToBe,
    }));

    static updateUserSuccess = createAction('USER_UPDATE_SUCCESS', (userToBe) => ({
        userToBe,
    }));

    static updateUserError = createAction('USER_UPDATE_ERROR', (message) => ({
        message,
    }));

    static updateUserORM = createAction('USER_ORM_UPDATE_REQUEST', (orm) => ({
        orm,
    }));

    static updateUserORMSuccess = createAction('USER_UPDATE_SUCCESS', (userToBe) => ({
        userToBe,
    }));

    static updateUserORMError = createAction('USER_ORM_UPDATE_ERROR', (message) => ({
        message,
    }));

    static logout = createAction('USER_ORM_LOGOUT_REQUESTED', () => ({}));

    static deleteUserProfile = createAction('USER_PROFILE_DELETION_REQUESTED', () => ({}));

    static reactivateUserProfile = createAction('USER_PROFILE_REACTIVATION_REQUESTED', () => ({}));

    static resetPassword = createAction('PASSWORD_RESET_REQUESTED', (credentials) => ({
        credentials,
    }));

    static changePassword = createAction('PASSWORD_CHANGE_REQUESTED', (passwords) => ({
        passwords,
    }));
    static getPrincipal = createAction('USER_PRINCIPAL_REQUESTED', () => ({}));
}
