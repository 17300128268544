import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Exercise from './Exercise';

const styles = createUseStyles({
    contentContainer: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    accordionOuter: {
        width: '100%',
        backgroundColor: 'transparent!important',
        borderRadius: '0px !important',
        borderBottom: '1px solid rgba(5, 116, 19, .3)',
        boxShadow: '0px 0px 0px!important',
    },
    accordionHeader: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold!important',
        marginRight: '10px!important',
    },
    accordionDetails: {
        flexWrap: 'wrap',
        display: 'flex',
        padding: '0px 0px 20px !important',
    },
});

interface ExerciseGroupProps {
    data: any;
    title: string;
    itemsModifyable?: boolean;
}
const ExerciseGroup = (props: ExerciseGroupProps): JSX.Element => {
    const classes = styles();
    const { data, title, itemsModifyable = false } = props;

    const [filteredExercises, setFilteredExercises] = useState(data);

    useEffect(() => {
        setFilteredExercises(data);
    }, [setFilteredExercises, data]);

    return (
        <div className={classes.contentContainer}>
            <Accordion className={classes.accordionOuter} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="strength-content" id="strength-header">
                    <Typography className={classes.accordionHeader}>{`${title} (${data.length})`}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    {filteredExercises.map((item: any) => {
                        return <Exercise key={item.id} item={item} modifyable={itemsModifyable} />;
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default ExerciseGroup;
