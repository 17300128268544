import { handleActions } from 'redux-actions';
import ExercisesActions from '../redux-actions/ExercisesActions';

const initialDefinedExercisesState = {
    loading: null,
    error: null,
    data: [],
};

const exerciseDefinitions = handleActions(
    {
        [ExercisesActions.getDefinedExercises]: (state) => {
            return {
                ...state,
                loading: true,
                error: false,
            };
        },
        [ExercisesActions.getDefinedExercisesSuccess]: (state, { payload: { exercises } }) => {
            return {
                ...state,
                data: exercises,
                loading: false,
                error: false,
            };
        },
        [ExercisesActions.getDefinedExercisesError]: (state, { payload: { message } }) => {
            return {
                ...state,
                data: [],
                loading: false,
                error: message,
            };
        },
        [ExercisesActions.saveExerciseDefinitionSuccess]: (state, { payload: { exercise } }) => {
            return {
                ...state,
                data: [...state.data, exercise],
            };
        },
        [ExercisesActions.updateExerciseDefinitionSuccess]: (state, { payload: { exercise } }) => {
            return {
                ...state,
                data: state.data.map((item) => {
                    if (exercise.id === item.id) {
                        return exercise;
                    }
                    return item;
                }),
                loading: false,
                error: false,
            };
        },
        [ExercisesActions.deleteExerciseDefinitionSuccess]: (state, { payload: { id } }) => {
            return {
                ...state,
                data: state.data.filter((item) => item.id !== id),
            };
        },
        [ExercisesActions.deleteExerciseDefinitionError]: (state, { payload: { message } }) => {
            return {
                ...state,
                loading: false,
                error: message,
            };
        },
    },
    initialDefinedExercisesState,
);

export const getDefinedExercises = (state) => {
    return {
        ...state, data: state.data.sort((a, b) => a.description?.type < b.description?.type ? - 1 : Number(a.description?.type > b.description?.type))
    }
};
export default exerciseDefinitions;
