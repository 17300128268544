import { call, put, select, takeLatest } from 'redux-saga/effects';
import { StateSelectors } from '../reducers/StateSelectors';
import ActionResponseActions from '../redux-actions/ActionResponseActions';
import WorkoutActions from '../redux-actions/WorkoutActions';
import Endpoints from '../util/endpoints';
import { httpDelete, httpGet, httpPut } from '../util/Http';

export function* getAllWorkouts() {
    const userCredentials = yield select(StateSelectors.getUserCredentials);
    const endpoint = ['ADMIN', 'SUPERVISOR'].includes(userCredentials.role)
        ? `${Endpoints.getAllWorkouts}`
        : `${Endpoints.getWorkoutsOfUser}/${userCredentials.userId}`;
    try {
        const workouts = yield call(httpGet, endpoint);
        yield put(WorkoutActions.getAllWorkoutsSuccess(workouts.data));
    } catch (e) {
        yield put(WorkoutActions.getAllWorkoutsError(e));
    }
}

export function* updateWorkoutDay({ payload: { workoutId, workoutDay } }) {
    const workouts = yield select(StateSelectors.getWorkouts);
    let workoutToBeUpdated = workouts.data?.filter((workout) => workout.id === workoutId)[0];

    const newWorkoutToBeSaved = {
        ...workoutToBeUpdated,
        days: workoutToBeUpdated.days.map((day) => {
            if (day.id === workoutDay.id) {
                return workoutDay;
            }
            return day;
        }),
    };
    try {
        const workout = yield call(httpPut, `${Endpoints.updateWorkout}`, newWorkoutToBeSaved);
        yield put(WorkoutActions.updateWorkoutSuccess(workout.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Workout Item',
                severity: 'success',
                message: 'Workout Item updated',
            }),
        );
    } catch (e) {
        yield put(WorkoutActions.updateWorkoutError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Workout Item',
                severity: 'error',
                message: 'Could not updated Workout Item',
            }),
        );
    }
}
export function* updateWorkout({ payload: { workout } }) {
    try {
        const newWorkout = yield call(httpPut, `${Endpoints.updateWorkout}`, workout);
        yield put(WorkoutActions.updateWorkoutSuccess(newWorkout.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Workout',
                severity: 'success',
                message: 'Workout updated',
            }),
        );
    } catch (e) {
        yield put(WorkoutActions.updateWorkoutError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Update Workout ',
                severity: 'error',
                message: 'Could not updated Workout',
            }),
        );
    }
}

export function* createWorkout({ payload: { workout } }) {
    try {
        const newWorkout = yield call(httpPut, `${Endpoints.updateWorkout}`, workout);
        yield put(WorkoutActions.createWorkoutSuccess(newWorkout.data));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Create Workout',
                severity: 'success',
                message: 'Workout created',
            }),
        );
    } catch (e) {
        yield put(WorkoutActions.createWorkoutError(e));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Create Workout ',
                severity: 'error',
                message: 'Could not create Workout',
            }),
        );
    }
}

export function* deleteWorkout({ payload: { workoutId } }) {
    try {
        yield call(httpDelete, `${Endpoints.deleteWorkout}/${workoutId}`);
        yield put(WorkoutActions.deleteWorkoutSuccess(workoutId));
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Workout',
                severity: 'success',
                message: 'Workout deleted',
            }),
        );
    } catch (e) {
        yield put(
            ActionResponseActions.setActionResponse({
                title: 'Delete Workout ',
                severity: 'error',
                message: 'Could not delete Workout',
            }),
        );
    }
}

export default function* workoutsSagas() {
    yield takeLatest(WorkoutActions.getAllWorkouts.toString(), getAllWorkouts);
    yield takeLatest(WorkoutActions.updateWorkoutDay.toString(), updateWorkoutDay);
    yield takeLatest(WorkoutActions.updateWorkout.toString(), updateWorkout);
    yield takeLatest(WorkoutActions.createWorkout.toString(), createWorkout);
    yield takeLatest(WorkoutActions.deleteWorkout.toString(), deleteWorkout);
}
