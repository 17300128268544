export default class RegexValidator {
    static validatePassword = (password) => {
        return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*_?&\.\-\/])([a-zA-Z0-9@$!%*_?&\.\-\/]{8,})$/.test(password);
    };
    static validateEmail = (email) => {
        return email?.length > 0
    };

    static validateUserId = (userId) => {
        return /[^a-zA-Z0-9]/.test(userId);
    }
}
