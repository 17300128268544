import { handleActions } from 'redux-actions';
import WorkoutActions from '../redux-actions/WorkoutActions';

const initialWorkoutsState = {
    loading: null,
    error: null,
    data: [],
};

const workouts = handleActions(
    {
        [WorkoutActions.getAllWorkouts]: (state) => {
            return {
                ...state,
                loading: true,
                error: false,
            };
        },
        [WorkoutActions.getAllWorkoutsSuccess]: (state, { payload: { workouts } }) => {
            return {
                ...state,
                data: workouts,
                loading: false,
                error: false,
            };
        },
        [WorkoutActions.getAllWorkoutsError]: (state, { payload: { message } }) => {
            return {
                ...state,
                loading: false,
                error: message,
            };
        },
        [WorkoutActions.updateWorkoutSuccess]: (state, { payload: { workout } }) => {
            return {
                ...state,
                data: state.data.map((item) => {
                    if (item.id === workout.id) {
                        return workout;
                    }
                    return item;
                }),
                loading: false,
                error: false,
            };
        },
        [WorkoutActions.deleteWorkoutSuccess]: (state, { payload: { workoutId } }) => {
            return {
                ...state,
                data: state.data.filter((item) => {
                    return item.id !== workoutId;
                }),
                loading: false,
                error: false,
            };
        },
        [WorkoutActions.createWorkoutSuccess]: (state, { payload: { workout } }) => {
            return {
                ...state,
                data: [...state.data, workout],
            };
        },
    },
    initialWorkoutsState,
);

export const getWorkouts = (state) => state;

export default workouts;
