import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import hunterLogo from '../../assets/hunters-head.png';
import DownloadLink from '../../components/DownloadLink';
import { useActions } from '../../hooks/useActions';
import UserActions from '../../redux-actions/UserActions';
import { navigate } from '../../routing/Router';
import RegexValidator from '../../util/RegexValidator';
import { useMedia } from '../../util/useMedia';
import GetAppIcon from '@mui/icons-material/GetApp';
import user from '../../reducers/userReducer';
import { StateSelectors } from '../../reducers/StateSelectors';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../components/loading/LoadingIndicator';

const styles = createUseStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#eeeeee',
        padding: 5,
    },
    headerText: {
        color: 'rgba(5, 116, 19)',
        fontWeight: 'bold!important',
        textShadow: '2px 2px 3px black',
    },
    headerTextInput: {
        color: 'rgba(5, 116, 19)',
        fontWeight: 'bold!important',
    },
    infoText: {
        color: 'rgba(5, 116, 19)',
        fontSize: '10px!important',
        textAlign: 'center',
    },
    infoTextCredentials: {
        color: 'rgba(5, 116, 19)',
        fontSize: '10px!important',
        fontStyle: 'italic',
        textAlign: 'center',
    },
    formContainer: {
        display: 'flex',
        wodth: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
    input: {
        margin: 5,
        flexDirection: 'column',
        display: 'flex',
    },
    registerButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    checkBoxLabel: {
        fontSize: '12px!important',
    },
    linkLabel: {
        color: 'blue',
        fontSize: '10px!important',
    },
});

interface RegisterProps {}
const Register = (props: RegisterProps): JSX.Element => {
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const [register] = useActions([(credentials: any) => UserActions.register(credentials)]);

    const user = useSelector(StateSelectors.getUser);

    const [credentialsEntered, setCredentialsEntered] = useState({} as any);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [userIdInvalid, setUserIdInvalid] = useState(false);
    const [credentialsValid, setCredentialsValid] = useState(false);
    const [ageConfirmed, setAgeConfirmed] = useState(false);

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            let value = event.target?.value?.trim();

            setCredentialsEntered((priorCredentialsEntered) => ({ ...priorCredentialsEntered, [field]: value }));
        },
        [setCredentialsEntered],
    );

    const onAttemptRegister = useCallback(() => {
        if (credentialsValid) {
            register(credentialsEntered);
        }
    }, [register, credentialsEntered, credentialsValid]);

    useEffect(() => {
        //@ts-ignore
        const { password, passwordConfirm } = credentialsEntered;

        if (password) {
            const passwordMatchedCriteria = RegexValidator.validatePassword(password);
            setPasswordInvalid(password !== passwordConfirm || !passwordMatchedCriteria);
        }
    }, [credentialsEntered, setPasswordInvalid]);

    useEffect(() => {
        //@ts-ignore
        const { email } = credentialsEntered;

        if (email) {
            const passwordMatchedCriteria = RegexValidator.validateEmail(email);
            setEmailInvalid(!passwordMatchedCriteria);
        }
    }, [credentialsEntered, setEmailInvalid]);

    useEffect(() => {
        //@ts-ignore
        const { userId } = credentialsEntered;

        if (userId) {
            const passwordMatchedCriteria = RegexValidator.validateUserId(userId);
            setUserIdInvalid(passwordMatchedCriteria);
        }
    }, [credentialsEntered, setUserIdInvalid]);

    useEffect(() => {
        //@ts-ignore
        const { userId, invitationCode, dataPolicyAccepted } = credentialsEntered;
        if (
            !emailInvalid &&
            !userIdInvalid &&
            !passwordInvalid &&
            userId?.length > 0 &&
            invitationCode?.length > 0 &&
            dataPolicyAccepted &&
            ageConfirmed
        ) {
            setCredentialsValid(true);
        } else {
            setCredentialsValid(false);
        }
    }, [credentialsEntered, emailInvalid, passwordInvalid, setCredentialsValid, ageConfirmed, userIdInvalid]);

    const onDatapolicyCheckedStateChange = useCallback(
        (event) => {
            // event.persist();
            //@ts-ignore
            setCredentialsEntered((priorCredentialsEntered) => ({
                ...priorCredentialsEntered,
                dataPolicyAccepted: event.target?.checked,
                agbAccepted: event.target?.checked,
            }));
        },
        [setCredentialsEntered],
    );

    const onAgeConfirmStateChange = useCallback(
        (event) => {
            // event.persist();
            setAgeConfirmed(event.target?.checked);
        },
        [setAgeConfirmed],
    );

    return (
        <div className={classes.container}>
            <img src={hunterLogo} alt="Logo" style={{ width: '50%', maxWidth: '500px' }} />
            <Typography variant={large ? 'h1' : 'h3'} component="h2" gutterBottom className={classes.headerText}>
                The Hunt Game
            </Typography>

            <FormControl>
                <div className={classes.formContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div className={classes.input}>
                            <Typography gutterBottom className={classes.headerTextInput}>
                                UserCredentials
                            </Typography>
                            <TextField
                                id="outlined-helperText"
                                label="Email"
                                variant="outlined"
                                type="email"
                                required
                                onChange={(e) => onInputFieldChange('email', e)}
                                style={{ width: '300px', marginBottom: 5, marginTop: 5 }}
                                error={emailInvalid}
                            />
                            <TextField
                                id="outlined-helperText"
                                label="User-ID (no special characters!)"
                                variant="outlined"
                                required
                                onChange={(e) => onInputFieldChange('userId', e)}
                                style={{ width: '300px', marginTop: 5 }}
                                error={userIdInvalid}
                            />
                        </div>
                        <div className={classes.input}>
                            <Typography gutterBottom className={classes.headerTextInput}>
                                Password
                            </Typography>
                            <TextField
                                id="filled-password-input"
                                label="Password"
                                type="password"
                                variant="outlined"
                                required
                                style={{ width: '300px', marginBottom: 5, marginTop: 5 }}
                                onChange={(e) => onInputFieldChange('password', e)}
                                error={passwordInvalid}
                            />
                            <TextField
                                id="filled-password-input-confirm"
                                label="Confirm Password"
                                type="password"
                                variant="outlined"
                                required
                                style={{ width: '300px', marginTop: 5 }}
                                onChange={(e) => onInputFieldChange('passwordConfirm', e)}
                                error={passwordInvalid}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                        <div className={classes.input}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Typography gutterBottom className={classes.infoTextCredentials}>
                                    * Password must be 8-24 characters long and contain at least: capital letters (1),
                                    digits (1), special characters (1)
                                </Typography>
                                <Typography gutterBottom className={classes.infoTextCredentials}>
                                    * Sequences like : 1234 or abcd are also not valid and will be rejected
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div className={classes.input}>
                        <Typography gutterBottom className={classes.headerTextInput}>
                            Invitation
                        </Typography>
                        <TextField
                            id="filled-invitation"
                            label="Invitation-Code"
                            variant="outlined"
                            required
                            style={{ width: large ? '610px' : '300px' }}
                            onChange={(e) => onInputFieldChange('invitationCode', e)}
                        />
                    </div>
                </div>
                <div className={classes.input}>
                    <Typography gutterBottom className={classes.infoText}>
                        *After You successfully registered, the app can only be used after you have setup your profile
                        and maintained all required fields.
                    </Typography>
                </div>

                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    <FormControlLabel
                        classes={{ label: classes.checkBoxLabel }}
                        control={
                            <Checkbox
                                checked={credentialsEntered.dataPolicyAccepted}
                                onChange={onDatapolicyCheckedStateChange}
                                name="isDatapolicyAccepted"
                            />
                        }
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>I do accept the</Typography>
                                <Button
                                    onClick={() => {}}
                                    classes={{ text: classes.linkLabel }}
                                    endIcon={<GetAppIcon color="primary" fontSize="small" />}
                                >
                                    <DownloadLink to="/files/THG_Datenschutzerklärung.pdf" target="_blank">
                                        Data-Policy
                                    </DownloadLink>
                                </Button>
                            </div>
                        }
                    />
                    <div>
                        <Typography gutterBottom className={classes.infoText}>
                            *can be rejected again at anytime
                        </Typography>
                    </div>
                    <div>
                        <FormControlLabel
                            classes={{ label: classes.checkBoxLabel }}
                            control={
                                <Checkbox
                                    checked={ageConfirmed}
                                    onChange={onAgeConfirmStateChange}
                                    name="isDatapolicyAccepted"
                                />
                            }
                            label={
                                <Typography style={{ fontSize: 12, fontStyle: 'italic' }}>
                                    I confirm that I am at least 16 years of age.
                                </Typography>
                            }
                        />
                        <Typography gutterBottom className={classes.infoText}>
                            *If you are under 16 years of age, you must not use this app.
                        </Typography>
                    </div>
                </div>
                <div className={classes.registerButtonContainer}>
                    {!user.registerRunning && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '200px', height: '50px', marginTop: 20, marginBottom: 10 }}
                                onClick={onAttemptRegister}
                                disabled={!credentialsValid}
                            >
                                Register
                            </Button>
                            <Link component="button" variant="body2" onClick={() => navigate('/')}>
                                Login
                            </Link>
                        </>
                    )}
                    {user.registerRunning && <LoadingIndicator size={30} />}
                </div>
            </FormControl>
        </div>
    );
};

export default Register;
