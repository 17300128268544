import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Carousel from 'react-material-ui-carousel';
import { useSelector } from 'react-redux';
import THGCard from '../../components/THGCard';
import { StateSelectors } from '../../reducers/StateSelectors';
import WorkoutDay from './WorkoutDay';

const styles = createUseStyles({
    container: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    accordionOuter: {
        width: '100%',
        backgroundColor: 'transparent!important',
        borderRadius: '0px !important',
        borderBottom: '1px solid rgba(5, 116, 19, .3)',
        boxShadow: '0px 0px 0px!important',
    },
    accordionHeader: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold!important',
        marginRight: '10px!important',
    },
    accordionDetails: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: '0px 0px 20px !important',
    },
    iconButton: {
        width: '200px',
        height: '200px',
        padding: '0!important',
    },
    chip: {
        margin: 2,
        fontSize: '18px!important',
        height: '40px!important',
        backgroundColor: 'rgb(0,0,0)!important',
        color: 'white!important',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    assignementChip: {
        margin: 2,
        fontSize: '12px!important',
        textAlign: 'center!important',
        height: '25px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    assignmentChipTitle: {
        fontSize: '12px!important',
    },
    assignementChipSmall: {
        margin: 2,
        fontSize: '8px!important',
        textAlign: 'center!important',
        height: '18px!important',
        color: 'white!important',
        fontWeight: 'bold!important',
    },
    assignmentChipTitleSmall: {
        fontSize: '10px!important',
    },
    toggleGroup: {
        height: 30,
    },
    selected: {
        backgroundColor: '#fafafa!important',
        border: '.5px solid #D3D3D3!important',
    },
    actionButton: {
        marginTop: '5px!important',
    },
});
interface WorkoutProps {
    workout: any;
    onWorkoutDayOpen: (resource: any) => void;
}
const WorkoutMobile = (props: WorkoutProps): JSX.Element => {
    const { workout, onWorkoutDayOpen } = props;
    const classes = styles();
    const exercises = useSelector(StateSelectors.getDefinedExercises);

    const [workoutState] = useState(workout);
    const [workoutVisible, setWorkoutVisible] = useState(false);
    const [workoutToBeShown, setWorkoutToBeShown] = useState(null);

    useEffect(() => {
        if (workoutVisible && workoutToBeShown) {
            onWorkoutDayOpen(workoutToBeShown);
        }
    }, [workoutVisible, workoutToBeShown]);

    const exercisesMapped = useCallback(
        (exercisesOfDay) => {
            const exercisesMapped = {};
            exercisesOfDay.forEach((exercise: any) => {
                // @ts-ignore
                exercisesMapped[exercise.exerciseId] = exercises.data?.filter(
                    (ex: any) => ex.id === exercise.exerciseId,
                )[0];
            });
            return exercisesMapped;
        },
        [workout, exercises],
    );

    const onShowHideWorkoutPress = useCallback(
        (resource) => {
            setWorkoutVisible((visible) => !visible);
            setWorkoutToBeShown(resource);
        },
        [setWorkoutVisible, setWorkoutToBeShown],
    );

    const workoutExpandAction = (resource) => (
        <IconButton
            aria-label="settings"
            onClick={() => onShowHideWorkoutPress(resource)}
            classes={{ root: classes.actionButton }}
        >
            {!workoutVisible && <ChevronRightIcon color="inherit" style={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
            {workoutVisible && <ExpandMoreIcon color="inherit" style={{ color: '#fafafa' }} />}
        </IconButton>
    );

    const renderWorkout = () => {
        return (
            <>
                <Carousel animation="slide" autoPlay={false} navButtonsAlwaysInvisible timeout={300}>
                    {workoutState.days?.map((day: any) => {
                        return (
                            <WorkoutDay
                                workoutDay={day}
                                workoutId={workoutState.id}
                                exercisesMap={exercisesMapped(day.exercises)}
                                onDeleteWorkoutDay={() => {}}
                                isMobile
                            />
                        );
                    })}
                </Carousel>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Typography style={{ fontSize: '10px', fontStyle: 'italic' }}>
                        *swipe or press bubbles to see other days
                    </Typography>
                </div>
            </>
        );
    };

    console.log(workoutState);

    return (
        <div className={classes.container}>
            <THGCard
                content={workoutVisible ? renderWorkout() : () => null}
                loading={false}
                title={`${workout.name}`}
                subheader=""
                action={workoutExpandAction(workout.id)}
                headerTransparent={!workoutVisible}
            />
        </div>
    );
};

export default WorkoutMobile;
