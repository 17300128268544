import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Provider } from 'react-redux';
import store from './reducers/store';
import Router from './routing/Router';
import { theme } from './theme';

const App = (): JSX.Element => {
    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    );
};

const AppProvider = (): JSX.Element => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

export default AppProvider;
