import axios from 'axios';
import { CANCEL } from 'redux-saga';

export const httpGet = (url, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: localStorage.getItem('THG_AUTH_HEADER'),
    };

    const request = axios.get(url, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpPost = (url, payload, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: localStorage.getItem('THG_AUTH_HEADER'),
    };
    const request = axios.post(url, payload, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpPut = (url, payload, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: localStorage.getItem('THG_AUTH_HEADER'),
    };
    const request = axios.put(url, payload, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpDelete = (url, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: localStorage.getItem('THG_AUTH_HEADER'),
    };
    const request = axios.delete(url, options);

    request[CANCEL] = () => source.cancel();

    return request;
};
