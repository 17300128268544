import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import EditWorkoutDayDialog from '../../components/dialogs/EditWorkoutDayDialog';
import ExerciseDescriptionDialog from '../../components/dialogs/ExerciseDescriptionDialog';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import WorkoutActions from '../../redux-actions/WorkoutActions';
import { useMedia } from '../../util/useMedia';

const styles = createUseStyles({
    container: {
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
    },
    root: {
        maxWidth: 500,
        padding: 5,
        borderRadius: '10px!important',
    },
    cardHeader: {
        backgroundColor: 'rgb(41, 49, 51)',
        color: 'white',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: '5px!important',
        '& >div>span': {
            fontWeight: 'bold',
            fontSize: '18px',
        },
    },
    title: {
        fontSize: 14,
    },
    tableHead: {
        '& >th': {
            backgroundColor: '#8FBC8F',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '10px',
        },
    },
    dataRow: {
        borderBottom: '1px solid #D3D3D3',
        '& >td': {
            fontWeight: 'bold',
        },
    },
    exerciseButtonLabel: {
        fontSize: '10px',
        fontWeight: 'bold',
        width: '100%!important',
        overflow: 'hidden!important',
        textOverflow: 'ellipsis',
    },
    exerciseButtonRoot: {
        padding: '4px 4px!important',
        display: 'block!important',
    },
    iconButton: {
        width: '40px',
        height: '40px',
        padding: '0!important',
    },
    headerAvatar: {
        marginRight: '0px!important',
        cursor: 'pointer',
        width: '100%',
    },
    headerRoot: {
        color: 'white',
        fontSize: '18px!important',
        fontWeight: 'bold!important',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

interface WorkoutDayProps {
    workoutDay: any;
    workoutId: string;
    onDeleteWorkoutDay: (workoutDay) => void;
    exercisesMap: {};
    ref?: any;
    isMobile?: boolean;
}

const WorkoutDay = (props: WorkoutDayProps): JSX.Element => {
    const { workoutDay, workoutId, exercisesMap, onDeleteWorkoutDay, isMobile = false } = props;
    const classes = styles();
    const large = useMedia('(min-width: 900px)');

    const exercises = useSelector(StateSelectors.getDefinedExercises);
    const userCredentials = useSelector(StateSelectors.getUserCredentials);
    const big4 = useSelector(StateSelectors.big4Exercises);
    const userORM = useSelector(StateSelectors.userORM);

    const [showExerciseDescription, setShowExerciseDescription] = useState(false);
    const [exerciseToBeDescribed, setExerciseToBeDescribed] = useState();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isPercentage, setIsPercentage] = useState(large);

    const onToggleChange = useCallback(() => {
        setIsPercentage((isPercentageNow) => !isPercentageNow);
    }, [[setIsPercentage]]);

    const DraggableHandle = SortableHandle(() => <DragIndicatorIcon style={{ fontSize: '24px' }} />);

    const [updateWorkoutDay] = useActions([
        (workoutId, workoutDay) => WorkoutActions.updateWorkoutDay(workoutId, workoutDay),
    ]);

    const onCloseExerciseDescription = useCallback(() => {
        setShowExerciseDescription(false);
    }, [setShowExerciseDescription]);

    const onCloseWorkoutEdit = useCallback(() => {
        setEditDialogOpen(false);
    }, [setEditDialogOpen]);

    const onSaveWorkoutDay = useCallback(
        (workoutDay) => {
            updateWorkoutDay(workoutId, workoutDay);
            setEditDialogOpen(false);
        },
        [setEditDialogOpen],
    );

    const onShowExerciseDescription = useCallback(
        (exercise) => {
            setExerciseToBeDescribed(exercise);
            setShowExerciseDescription(true);
        },
        [setExerciseToBeDescribed, setShowExerciseDescription],
    );

    const onShowWorkoutDayEdit = useCallback(() => {
        setEditDialogOpen(true);
    }, [setExerciseToBeDescribed, setEditDialogOpen]);

    const onDeleteWorkoutDayClick = useCallback(() => {
        setDeleteDialogOpen(true);
    }, [setDeleteDialogOpen]);

    const onDeleteDialogCancelPress = useCallback(() => {
        setDeleteDialogOpen(false);
    }, [setDeleteDialogOpen]);

    const onDeleteWorkoutDayConfirm = useCallback(() => {
        onDeleteWorkoutDay(workoutDay);
        setDeleteDialogOpen(false);
    }, [setDeleteDialogOpen]);

    const exerciseDescriptionDialog = useMemo(() => {
        return (
            <ExerciseDescriptionDialog
                open={showExerciseDescription}
                exercise={exerciseToBeDescribed}
                onClosePress={onCloseExerciseDescription}
            />
        );
    }, [setShowExerciseDescription, exerciseToBeDescribed, showExerciseDescription]);

    const workoutDayEditDialog = useMemo(() => {
        return (
            <EditWorkoutDayDialog
                open={editDialogOpen}
                workoutDay={workoutDay}
                onClosePress={onCloseWorkoutEdit}
                onSavePress={onSaveWorkoutDay}
                definedExercises={exercises}
            />
        );
    }, [editDialogOpen, onCloseWorkoutEdit, onSaveWorkoutDay, exercises, workoutDay]);

    const deleteDialog = useMemo(() => {
        return (
            <DeleteDialog
                title="Delete Day of Workout"
                item={{ description: { name: workoutDay.day } }}
                open={deleteDialogOpen}
                onSavePress={onDeleteWorkoutDayConfirm}
                onCancelPress={onDeleteDialogCancelPress}
            />
        );
    }, [workoutDay, deleteDialogOpen, onDeleteWorkoutDayConfirm, onDeleteDialogCancelPress]);

    const intensityMapped = (intensity, exerciseORM) => {
        let mappedIntensity = intensity;
        const weightsInfo = intensity.split(' ').filter((i) => i.includes('%'));

        weightsInfo.forEach((info) => {
            mappedIntensity = mappedIntensity.replace(info, `${((exerciseORM / 100) * parseInt(info)).toFixed(1)}kg`);
        });
        return mappedIntensity;
    };

    return (
        <div className={classes.container}>
            <Card className={classes.root}>
                <CardHeader
                    className={classes.cardHeader}
                    classes={{ root: classes.headerRoot, avatar: classes.headerAvatar }}
                    title=""
                    subheader=""
                    avatar={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && !isMobile && (
                                    <DraggableHandle />
                                )}
                                <Typography classes={{ root: classes.headerRoot }}>{workoutDay.day}</Typography>
                            </div>

                            <ToggleButtonGroup
                                exclusive
                                onChange={onToggleChange}
                                sx={{ backgroundColor: 'rgba(0,86,44)', height: 30 }}
                            >
                                <ToggleButton
                                    value="weight"
                                    selected={!isPercentage}
                                    style={{
                                        width: 50,
                                        fontWeight: !isPercentage ? 'bold' : 'normal',
                                        fontSize: !isPercentage ? 16 : 12,
                                        color: !isPercentage ? 'white' : 'lightGrey',
                                    }}
                                >
                                    KG
                                </ToggleButton>
                                <ToggleButton
                                    value="percentage"
                                    selected={isPercentage}
                                    style={{
                                        width: 50,
                                        fontWeight: isPercentage ? 'bold' : 'normal',
                                        fontSize: isPercentage ? 16 : 12,
                                        color: isPercentage ? 'white' : 'lightGrey',
                                    }}
                                >
                                    %
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    }
                />
                <CardContent style={{ padding: 0, marginTop: '10px' }}>
                    <TableContainer style={{ marginBottom: 10 }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow className={classes.tableHead}>
                                    <TableCell key={`${workoutDay.day}_exercises`}>Exercise</TableCell>
                                    <TableCell
                                        key={`${workoutDay.day}_sets`}
                                        align="center"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Sets
                                    </TableCell>
                                    <TableCell
                                        key={`${workoutDay.day}_reps`}
                                        align="center"
                                        style={{ fontSize: '10px' }}
                                    >
                                        Amount
                                    </TableCell>
                                    <TableCell key={`${workoutDay.day}_intensity`} align="center">
                                        Intensity/Weight
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {workoutDay.exercises?.map((exercise: any, index: number) => {
                                    const ormMapped =
                                        userORM?.data?.filter((e) => e.exercise == exercise.exerciseId) ?? [];
                                    let exerciseORM = ormMapped.length > 0 ? ormMapped[0].orm : null;

                                    return (
                                        <TableRow
                                            key={`row__${index}__${workoutDay.id}`}
                                            tabIndex={-1}
                                            className={classes.dataRow}
                                        >
                                            <TableCell
                                                align="left"
                                                style={{
                                                    padding: 4,
                                                    maxWidth: '120px',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                }}
                                            >
                                                <Button
                                                    fullWidth
                                                    style={{
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        backgroundColor: 'rgba(0,86,44)',
                                                        color: '#fafafa',
                                                        borderRadius: 5,
                                                        fontSize: '8px',
                                                        fontWeight: 'bold',
                                                        padding: 4,
                                                    }}
                                                    variant="contained"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() =>
                                                        onShowExerciseDescription(exercisesMap[exercise?.exerciseId])
                                                    }
                                                >
                                                    {exercisesMap[exercise.exerciseId]?.description?.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center" style={{ padding: 2, fontSize: '12px' }}>
                                                {exercise.sets}
                                            </TableCell>
                                            <TableCell align="center" style={{ padding: 2, fontSize: '12px' }}>
                                                {`${exercise.repetitions}`} <br />
                                                <div style={{ fontSize: '10px' }}>
                                                    {exercise.unit?.map((u, index) => (
                                                        <span key={`span_${index}_units`}>{`${u}${
                                                            index < exercise.unit?.length - 1 ? '/' : ''
                                                        }`}</span>
                                                    )) ?? ''}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" style={{ padding: 2, fontSize: '10px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        {(isPercentage || !exerciseORM) && exercise.intensity}
                                                        {!isPercentage &&
                                                            exerciseORM &&
                                                            intensityMapped(exercise.intensity, exerciseORM)}
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {['ADMIN', 'SUPERVISOR'].includes(userCredentials.role) && large && (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={onShowWorkoutDayEdit}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="primary"
                                aria-label="edit"
                                className={classes.iconButton}
                                onClick={onDeleteWorkoutDayClick}
                            >
                                <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                        </div>
                    )}
                </CardContent>
            </Card>
            {showExerciseDescription && exerciseDescriptionDialog}
            {editDialogOpen && workoutDayEditDialog}
            {deleteDialogOpen && deleteDialog}
        </div>
    );
};

export default WorkoutDay;
