import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import { StateSelectors } from '../../reducers/StateSelectors';
import UserActions from '../../redux-actions/UserActions';
import { navigate } from '../../routing/Router';

const styles = createUseStyles({
    dialog: {
        minWidth: 350,
    },
    dialogContent: {
        padding: 5,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    profileItem: {
        marginTop: 10,
        marginBottom: 10,
    },
    logoutLabel: {
        color: 'blue',
    },
    deleteProfileLabel: {
        fontSize: '8px!important',
    },
});

interface ProfileDialogProps {
    open: boolean;
    onSavePress: (exercise: any) => void;
    onCancelPress: () => void;
    showCancelButton: boolean;
}

const ProfileDialog = (props: ProfileDialogProps): JSX.Element => {
    const { open, onCancelPress, onSavePress, showCancelButton } = props;
    const classes = styles();
    const userProfile = useSelector(StateSelectors.getUserProfile);
    const [itemToBeSaved, setItemToBeSaved] = useState(userProfile);
    const [profileShouldBeDeleted, setProfileShouldBeDeleted] = useState(false);

    const attributes = useSelector(StateSelectors.getAttributes);

    const [logout, deleteProfile] = useActions([() => UserActions.logout(), () => UserActions.deleteUserProfile()]);

    const onInputFieldChange = useCallback(
        (field, event) => {
            event.persist();
            setItemToBeSaved((priorItemToBeSaved) => ({ ...priorItemToBeSaved, [field]: event.target?.value }));
        },
        [setItemToBeSaved],
    );

    const onSelectFieldChange = useCallback(
        (field, event) => {
            setItemToBeSaved((priorItemToBeSaved) => ({ ...priorItemToBeSaved, [field]: event.target?.value }));
        },
        [setItemToBeSaved],
    );

    const onCancelExercisePress = useCallback(() => {
        onCancelPress();
    }, [onCancelPress]);

    const onSaveExercisePress = useCallback(() => {
        onSavePress(itemToBeSaved);
    }, [itemToBeSaved]);

    const onLogoutPress = useCallback(() => {
        localStorage.removeItem('THG_AUTH_HEADER');
        logout();
    }, [localStorage]);

    const onDeleteProfilePress = useCallback(() => {
        setProfileShouldBeDeleted(true);
    }, [setProfileShouldBeDeleted]);

    const onConfirmDeleteProfilePress = useCallback(() => {
        deleteProfile();
        setProfileShouldBeDeleted(false);
        navigate('/');
    }, [setProfileShouldBeDeleted, deleteProfile]);

    const profileSettings = () => {
        return (
            <>
                <div className={classes.profileItem}>
                    <TextField
                        id="outlined-firstName"
                        label="Firstname"
                        variant="outlined"
                        defaultValue={itemToBeSaved?.firstName}
                        required
                        onChange={(e) => onInputFieldChange('firstName', e)}
                        style={{
                            width: 'calc(100% - 10px)',
                        }}
                    />
                </div>
                <div className={classes.profileItem}>
                    <TextField
                        id="outlined-lastName"
                        label="Lastname"
                        variant="outlined"
                        required
                        defaultValue={itemToBeSaved?.lastName}
                        onChange={(e) => onInputFieldChange('lastName', e)}
                        style={{
                            width: 'calc(100% - 10px)',
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: 10,
                        flexWrap: 'wrap',
                    }}
                >
                    <div className={classes.profileItem}>
                        <FormControl required>
                            <InputLabel id="group-select-label">Group</InputLabel>
                            <Select
                                labelId="group-select-label"
                                id="group-select"
                                value={itemToBeSaved?.department}
                                required
                                label="Group"
                                onChange={(e) => onSelectFieldChange('department', e)}
                                style={{ width: '130px' }}
                            >
                                {attributes.data.departments
                                    ?.filter((p) => p !== 'All')
                                    .map((department: string) => {
                                        return <MenuItem value={department}>{department}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.profileItem}>
                        <FormControl>
                            <InputLabel id="side-select-label">Side</InputLabel>
                            <Select
                                labelId="side-select-label"
                                id="side-select"
                                value={itemToBeSaved?.side}
                                label="Side"
                                onChange={(e) => onSelectFieldChange('side', e)}
                                style={{ width: '130px' }}
                            >
                                {attributes.data.sides?.map((side: string) => {
                                    return <MenuItem value={side}>{side}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.profileItem}>
                        <FormControl required>
                            <InputLabel id="position-select-label">Position</InputLabel>
                            <Select
                                labelId="position-select-label"
                                id="position-select"
                                label="Position"
                                value={itemToBeSaved?.position}
                                onChange={(e) => onSelectFieldChange('position', e)}
                                required
                                style={{ width: '150px' }}
                            >
                                {attributes.data.positions
                                    ?.filter((p) => p !== 'All')
                                    .map((position: string) => {
                                        return <MenuItem value={position}>{position}</MenuItem>;
                                    })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className={classes.profileItem}>
                        <TextField
                            id="outlined-height"
                            label="Height(cm)"
                            variant="outlined"
                            defaultValue={itemToBeSaved?.height}
                            onChange={(e) => onInputFieldChange('height', e)}
                            style={{
                                width: 'calc(100% - 10px)',
                            }}
                        />
                    </div>
                    <div className={classes.profileItem}>
                        <TextField
                            id="outlined-weight"
                            label="Weight(kg)"
                            variant="outlined"
                            defaultValue={itemToBeSaved?.weight}
                            onChange={(e) => onInputFieldChange('weight', e)}
                            style={{
                                width: 'calc(100% - 10px)',
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', padding: 5 }}>
                    <Typography style={{ fontSize: 10, fontStyle: 'italic' }}>
                        *required Field have to be filled before you can use the app
                    </Typography>
                </div>
            </>
        );
    };

    const profileDeletionConfirmation = useMemo(() => {
        return (
            <div>
                <Typography style={{ fontWeight: 'bold' }}> Are You sure you want to delete your profile?</Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                    {' '}
                    The deletion will automatically be made within the next 7 days including all data stored for your
                    user.
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                    {' '}
                    You might want to come back and reactivate your profile within the next 7 days.
                </Typography>
                <div style={{ display: 'flex', marginTop: 20, width: '100%', justifyContent: 'space-between' }}>
                    <Button onClick={onConfirmDeleteProfilePress} variant="contained" color="primary">
                        Yes, I am sure!
                    </Button>
                    <Button
                        onClick={() => {
                            setProfileShouldBeDeleted(false);
                            onCancelPress();
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }, [setProfileShouldBeDeleted, onConfirmDeleteProfilePress]);
    return (
        <Dialog fullWidth={true} open={open} onClose={() => {}}>
            <DialogTitle id="simple-dialog-title">
                <div style={{ display: 'flex', width: '100%', padding: 10, justifyContent: 'space-between' }}>
                    <Typography variant="h4" color="primary" style={{ fontWeight: 'bold' }}>
                        {' '}
                        Profile
                    </Typography>
                    <Button onClick={onLogoutPress} classes={{ text: classes.logoutLabel }}>
                        Logout
                    </Button>
                </div>
            </DialogTitle>
            <DialogContent>
                {!profileShouldBeDeleted && profileSettings()}
                {profileShouldBeDeleted && profileDeletionConfirmation}
            </DialogContent>
            <DialogActions>
                {!profileShouldBeDeleted && (
                    <>
                        {showCancelButton && (
                            <Button onClick={onCancelExercisePress} variant="outlined">
                                Cancel
                            </Button>
                        )}
                        <Button color="primary" variant="contained" onClick={onSaveExercisePress}>
                            Save
                        </Button>
                    </>
                )}
            </DialogActions>
            {!profileShouldBeDeleted && (
                <div style={{ display: 'flex', padding: 2, width: '100%', justifyContent: 'center', height: 20 }}>
                    <Button
                        onClick={onDeleteProfilePress}
                        classes={{ text: classes.deleteProfileLabel }}
                        color="secondary"
                    >
                        Delete Profile
                    </Button>
                </div>
            )}
        </Dialog>
    );
};

export default ProfileDialog;

// rxD321l2svE
