import axios from 'axios';
import { CANCEL } from 'redux-saga';

export const httpGetSpring = (url, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: `Bearer ${localStorage.getItem('THG_JWT_TOKEN')}`,
    };

    const request = axios.get(url, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpPostSpring = (url, payload, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: `Bearer ${localStorage.getItem('THG_JWT_TOKEN')}`,
    };
    const request = axios.post(url, payload, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpPutSpring = (url, payload, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: `Bearer ${localStorage.getItem('THG_JWT_TOKEN')}`,
    };
    const request = axios.put(url, payload, options);

    request[CANCEL] = () => source.cancel();

    return request;
};

export const httpDeleteSpring = (url, options = {}) => {
    const source = axios.CancelToken.source();
    options.cancelToken = source.token;
    options.headers = {
        Authorization: `Bearer ${localStorage.getItem('THG_JWT_TOKEN')}`,
    };
    const request = axios.delete(url, options);

    request[CANCEL] = () => source.cancel();

    return request;
};
