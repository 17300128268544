import { createTheme } from '@mui/material/styles';
// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
    palette: {
        icon: {
            primary: 'rgba(0,86,44)',
            secondary: 'rgb(185, 25, 25)',
            disabled: 'rgba(0, 0, 0, 0.26)',
        },
        primary: {
            // main: 'rgba(5, 116, 19)',
            main: 'rgba(0,86,44)',
        },
        secondary: {
            // main: 'rgba(5, 116, 19)',
            main: 'rgba(185,25,25)',
        },
        action: {
            main: 'white',
        },
    },
});
