import React from 'react';

export const DownloadLink = ({ to, children, ...rest }) => {
    return (
        <a
            {...rest}
            style={{ borderBottom: 'none', color: 'rgba(0,86,44)', textDecoration: 'none' }}
            href={to}
            download
        >
            {children}
        </a>
    );
};

export default DownloadLink;
